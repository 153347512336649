import React, { useEffect, useState } from "react";
import {
  ArrowRightCircleIcon,
  CalendarIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/solid";

import { useConfig } from "../../context/ConfigContext";
import { usePrefences } from "../../context/PrefencesContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  checkRole,
  classNames,
  diagnosticResponse,
} from "../../helpers/Helpers";
import Requests from "../../helpers/Requests";
import Alert from "../../components/Alert";
import PaginationClassic from "../../components/PaginationClassic";
import NoData from "../../components/NoData";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import Confirm from "../../components/Confirm";
import { useToastr } from "../../context/ToastrContext";

import Dropdown from "../../components/Dropdown";
import SearchFilter from "../../components/SearchFilter";
import { EllipsisHorizontalCircleIcon } from "@heroicons/react/24/outline";

function MatchOdds() {
  const ConfigProvider = useConfig();
  const { eid } = useParams();
  const PrefencesProvider = usePrefences();

  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "SportsManageSportMarkets")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [formData, setFormData] = useState({
    eid: eid,
    page: 1,
  });

  const [info, setInfo] = useState(null);
  const [data, setData] = useState([]);
  const [messages, setMessages] = useState([]);

  const getItems = () => {
    if (!loading) {
      setLoading(true);
      setMessages([]);
      const postData = formData;
      postData.itemsPerPage = PrefencesProvider.prefences.offset;
      postData.search = search;
      Requests.postData("/sports/getEditMatchOdds", postData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              setMessages([
                {
                  type: "error",
                  text: res.message.length
                    ? res.message
                    : ConfigProvider.getTranslation("Forms.ErrorMessage"),
                },
              ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            if (formData.page === 1) {
              setTotalItems(res.c);
            }
            setOddValues(res.oddValues);

            setData(res.data.markets);
            setInfo(res.data);
          }
          setLoading(false);
        })
        .catch(() => {
          setMessages([
            {
              type: "error",
              text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
            },
          ]);
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, [formData.page, PrefencesProvider.prefences.offset]);
  const [oddValues, setOddValues] = useState(false);

  return (
    <>
      {loading && <div className="w-full h-full absolute z-50">&nbsp;</div>}
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (formData.page !== 1) {
            setFormData((prev) => {
              return { ...prev, page: 1 };
            });
          } else {
            getItems();
          }
        }}
        className=""
      >
        {messages.length > 0 && (
          <div className="mt-2 mb-4">
            <Alert messages={messages} setMessages={setMessages} />
          </div>
        )}
        {/* Page header */}
        <div className="sm:flex sm:justify-between sm:items-center mb-5">
          {/* Left: Title */}
          <div className="mb-4 sm:mb-0">
            <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
              {ConfigProvider.getTranslation("Markets")} ✨
            </h1>
          </div>
        </div>
        {/* More actions */}
        <div className="lg:flex lg:justify-between lg:items-center mb-5">
          {/* Left side */}
          <div className="mb-4 lg:mb-0"></div>
          {/* Right side */}
          <div className="flex flex-col lg:grid lg:grid-flow-col lg:auto-cols-max justify-end gap-2">
            {/* Search form */}
            <SearchFilter
              placeholder={ConfigProvider.getTranslation("Search")}
              search={search}
              setSearch={setSearch}
            />
            <button
              type="submit"
              className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
            >
              <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
              <span className="ml-2">
                {ConfigProvider.getTranslation("Button.Send")}
              </span>
            </button>
          </div>
        </div>
      </form>
      {/* Table */}
      <MatchOddsTable
        data={data}
        loading={loading}
        getItems={getItems}
        oddValues={oddValues}
        setData={setData}
        eid={eid}
        info={info}
      />
      {/* Pagination */}
      <div className="mt-8">
        <PaginationClassic
          page={formData.page}
          setPage={(val) => {
            setFormData((prev) => {
              return { ...prev, page: val };
            });
          }}
          totalItems={totalItems}
        />
      </div>
    </>
  );
}
function MatchOddsTable({
  data,
  loading,
  getItems,
  oddValues,
  setData,
  eid,
  info,
}) {
  const ConfigProvider = useConfig();
  const ToastrContext = useToastr();

  const [showConfirm, setShowConfirm] = useState(false);
  const [confirmItem, setConfirmItem] = useState(null);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const setIsShowMatchMarket = () => {
    if (!loading && !confirmLoading) {
      setConfirmLoading(true);

      Requests.postData("/sports/setIsShowMatchMarket", {
        eid: eid,
        sid: confirmItem.data.sid,
        market: confirmItem.data.market,
        selid: confirmItem.data.selid,
        secid: confirmItem.data.secid,
        isShow: confirmItem.type === "isShow" ? !confirmItem.data.isShow : null,
        odd: confirmItem.type === "odd" ? confirmItem.data.odd : null,
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              ToastrContext.showToast({
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            ToastrContext.showToast({
              type: "success",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.SuccessMessage"),
            });

            setShowConfirm(false);
            setConfirmItem(null);
            setTimeout(() => {
              getItems();
            }, 100);
          }
          setConfirmLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          setConfirmLoading(false);
          setShowConfirm(false);
          setConfirmItem(null);
        });
    }
  };
  const PrefencesProvider = usePrefences();
  return (
    <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
      <header className="px-5 py-4">
        {info && (
          <div className="font-medium text-slate-800 dark:text-slate-100 flex flex-col">
            <div className="flex items-center text-xs text-slate-700 dark:text-slate-500">
              <CalendarIcon className="w-4 h-4 mr-1" />
              {new Intl.DateTimeFormat("default", {
                day: "numeric",
                month: "numeric",
                year: "numeric",
                hour: "numeric",
                minute: "numeric",
                timeZone: PrefencesProvider.prefences.timezone,
              }).format(new Date(info.eventdate))}
            </div>
            <h2 className="font-semibold text-slate-800 dark:text-slate-100">
              {info.label}
            </h2>
            <div className="text-xs text-slate-700 dark:text-slate-500">
              {`${info.sport} / ${info.catName}`}
            </div>
          </div>
        )}
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
              <tr>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-full">
                  <div className="grow font-semibold text-left">
                    {ConfigProvider.getTranslation("Market")}
                  </div>
                </th>

                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("Status")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("Rate")}
                  </div>
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap max-w-[120px] min-w-[120px]">
                  &nbsp;
                </th>
                <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                  <div className="grow font-semibold text-center">
                    {ConfigProvider.getTranslation("Selections")}
                  </div>
                </th>
              </tr>
            </thead>

            {!loading && data && data.length === 0 && (
              <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
                <tr>
                  <td colSpan="5">
                    <NoData />
                  </td>
                </tr>
              </tbody>
            )}
            {loading && (
              <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
                <tr>
                  <td colSpan="5" className="text-center py-10">
                    <LoadingSpinner className="w-12 h-12" />
                  </td>
                </tr>
              </tbody>
            )}
            {!loading &&
              data.map((item) => (
                <MatchOddsTableItem
                  item={item}
                  key={item._id}
                  setShowConfirm={setShowConfirm}
                  setConfirmItem={setConfirmItem}
                  setData={setData}
                  loading={loading}
                  oddValues={oddValues}
                  sid={info.sid}
                />
              ))}
          </table>
        </div>
      </div>
      {showConfirm && confirmItem !== null && (
        <Confirm
          title={ConfigProvider.getTranslation("AreYouSure")}
          text={
            <>
              <p>{confirmItem.data.name}</p>
              {confirmItem.type === "isShow" && (
                <p>{`${ConfigProvider.getTranslation("Status")} -> ${
                  confirmItem.data.isShow
                    ? ConfigProvider.getTranslation("Passive")
                    : ConfigProvider.getTranslation("Active")
                }`}</p>
              )}
              {confirmItem.type === "odd" && (
                <p>{`${ConfigProvider.getTranslation("Rate")} -> ${
                  confirmItem.data.odd
                }`}</p>
              )}
            </>
          }
          confirm={() => {
            setIsShowMatchMarket();
          }}
          callback={() => {
            setShowConfirm(false);
            setConfirmItem(null);
          }}
        />
      )}
    </div>
  );
}
function MatchOddsTableItem({
  item,
  setShowConfirm,
  setConfirmItem,
  setData,
  loading,
  oddValues,
  sid,
}) {
  const ConfigProvider = useConfig();
  const ToastrContext = useToastr();
  const { eid } = useParams();
  const [editOdd, setEditOdd] = useState(false);

  const [descriptionOpen, setDescriptionOpen] = useState(false);
  const [formData, setFormData] = useState({
    sid: sid,
    market: item.market,
    selid: item.selid,
    secid: item.secid,
    preSingleLimit: 0,
    preMultibleLimit: 0,
    liveSingleLimit: 0,
    liveMultibleLimit: 0,
    val: eid,
  });
  const handleChange = (e) => {
    if (!loading) {
      const { name, value } = e.target;
      setFormData({ ...formData, [name]: value });
    }
  };
  const [dataLoading, setDataLoading] = useState(false);
  const getItems = () => {
    if (!dataLoading) {
      setDataLoading(true);

      Requests.postData("/sports/getLimitEventMarket", {
        val: formData.val,
        sid: formData.sid,
        market: item.market,
        selid: item.selid,
        secid: item.secid,
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
          } else {
            setFormData({
              ...formData,
              preSingleLimit: res.data.preSingleLimit,
              preMultibleLimit: res.data.preMultibleLimit,
              liveSingleLimit: res.data.liveSingleLimit,
              liveMultibleLimit: res.data.liveMultibleLimit,
            });
          }
          setDataLoading(false);
        })
        .catch(() => {
          document.getElementById("mainBody").scrollTo(0, 0);
          setDataLoading(false);
        });
    }
  };
  useEffect(() => {
    if (descriptionOpen) {
      getItems();
    }
    // eslint-disable-next-line
  }, [descriptionOpen]);

  const updateLimitEventMarket = (e) => {
    e.preventDefault();

    if (!dataLoading) {
      setDataLoading(true);

      Requests.postData("/sports/updateLimitEventMarket", formData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            ToastrContext.showToast({
              type: "error",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.ErrorMessage"),
            });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            ToastrContext.showToast({
              type: "success",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.SuccessMessage"),
            });
            setDescriptionOpen(false);
          }
          setDataLoading(false);
        })
        .catch(() => {
          setDataLoading(false);
        });
    }
  };
  const removeLimitEventMarket = () => {
    if (!dataLoading) {
      setDataLoading(true);
      Requests.postData("/sports/removeLimitEventMarket", {
        val: formData.val,
        sid: formData.sid,
        market: item.market,
        selid: item.selid,
        secid: item.secid,
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              ToastrContext.showToast({
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            ToastrContext.showToast({
              type: "success",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.SuccessMessage"),
            });
          }
          setDataLoading(false);
          setDescriptionOpen(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          setDataLoading(false);
        });
    }
  };

  const [descriptionOpen2, setDescriptionOpen2] = useState(false);
  const [selections, setSelections] = useState(item.odds);
  const getItems2 = () => {
    if (!dataLoading) {
      setDataLoading(true);

      Requests.postData("/sports/getEditMatchMarketSelections", {
        eid: eid,
        sid: formData.sid,
        market: item.market,
        selid: item.selid,
        secid: item.secid,
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
          } else {
            Object.keys(res.data).forEach((item) => {
              if (selections.hasOwnProperty(item)) {
                console.log(selections);
                setSelections((prev) => {
                  return {
                    ...prev,
                    [item]: {
                      ...prev[item],
                      odd: res.data[item].odd,
                      isShow: res.data[item].isShow,
                    },
                  };
                });
              }
            });
          }
          setDataLoading(false);
        })
        .catch(() => {
          document.getElementById("mainBody").scrollTo(0, 0);
          setDataLoading(false);
        });
    }
  };
  useEffect(() => {
    if (descriptionOpen2) {
      getItems2();
    }
    // eslint-disable-next-line
  }, [descriptionOpen2]);

  return (
    <tbody className="text-sm">
      <tr>
        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          {checkRole(
            ConfigProvider.config.user,
            "ReportMatchReportsManageLimitClass"
          ) ? (
            <Link
              onClick={() => setDescriptionOpen(!descriptionOpen)}
              className="font-semibold text-sky-500 dark:text-sky-400"
            >
              {item.name}
            </Link>
          ) : (
            <div className="text-left text-slate-900 dark:text-slate-100">
              {item.name}
            </div>
          )}
        </td>

        <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
          <Link
            className={classNames(
              "inline-flex font-medium rounded-full text-center px-2.5 py-0.5",
              item.isShow
                ? "bg-emerald-100 dark:bg-emerald-400/30 text-emerald-600 dark:text-emerald-400"
                : "bg-slate-100 dark:bg-slate-700 text-slate-500 dark:text-slate-400"
            )}
            onClick={() => {
              setConfirmItem({ type: "isShow", data: item });
              setShowConfirm(true);
            }}
          >
            {item.isShow
              ? ConfigProvider.getTranslation("Active")
              : ConfigProvider.getTranslation("Passive")}
          </Link>
        </td>
        <td
          colSpan={2}
          className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"
        >
          {editOdd ? (
            <form
              className="flex space-x-2"
              onSubmit={(e) => {
                e.preventDefault();
                setConfirmItem({ type: "odd", data: item });
                setShowConfirm(true);
              }}
            >
              <Dropdown
                name="rate"
                items={oddValues}
                selected={item.odd}
                setSelected={(val) =>
                  setData((prev) => {
                    const updatedItems = prev.map((subItem) => {
                      if (subItem._id === item._id) {
                        return {
                          ...subItem,
                          odd: val,
                        };
                      }
                      return subItem;
                    });
                    return updatedItems;
                  })
                }
                disabled={loading}
              />
              <button
                type="submit"
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
              >
                <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
                <span className="ml-2">
                  {ConfigProvider.getTranslation("Button.Save")}
                </span>
              </button>
            </form>
          ) : (
            <div className="flex items-center space-x-2">
              <span className="font-semibold mx-2 grow">{item.odd}</span>
              <button
                type="button"
                className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                onClick={() => setEditOdd(true)}
              >
                <PencilSquareIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
              </button>
            </div>
          )}
        </td>
        <td>
          {checkRole(
            ConfigProvider.config.user,
            "SportsManageSportMarkets"
          ) && (
            <Link
              onClick={() => setDescriptionOpen2(!descriptionOpen2)}
              className="btn bg-amber-100 dark:bg-amber-400/30 text-amber-600 dark:text-amber-400"
            >
              {ConfigProvider.getTranslation("Selections")}
            </Link>
          )}
        </td>
      </tr>
      {descriptionOpen && (
        <tr
          id={`description-${item._id}`}
          role="region"
          className={`${!descriptionOpen && "hidden"}`}
        >
          <td
            colSpan="5"
            className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3"
          >
            <div className="w-full bg-slate-50 dark:bg-slate-900/30 dark:text-slate-400 p-3 -mt-3">
              <form
                onSubmit={(e) => {
                  updateLimitEventMarket(e);
                }}
                className="p-6 space-y-6"
              >
                <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
                  {ConfigProvider.getTranslation("PreGame")}
                </h2>

                <section>
                  <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
                    <div className="relative">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="preSingleLimit"
                      >
                        {ConfigProvider.getTranslation("SingleBetLimit")}
                      </label>
                      <input
                        id="preSingleLimit"
                        name="preSingleLimit"
                        type="number"
                        value={formData.preSingleLimit}
                        className="w-full form-input"
                        placeholder={ConfigProvider.getTranslation(
                          "SingleBetLimit"
                        )}
                        required
                        disabled={loading}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="relative">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="preMultibleLimit"
                      >
                        {ConfigProvider.getTranslation("MultibleBetLimit")}
                      </label>
                      <input
                        id="preMultibleLimit"
                        name="preMultibleLimit"
                        type="number"
                        value={formData.preMultibleLimit}
                        className="w-full form-input"
                        placeholder={ConfigProvider.getTranslation(
                          "MultibleBetLimit"
                        )}
                        required
                        disabled={loading}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </section>
                <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
                  {ConfigProvider.getTranslation("Live")}
                </h2>
                <section>
                  <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
                    <div className="relative">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="liveSingleLimit"
                      >
                        {ConfigProvider.getTranslation("SingleBetLimit")}
                      </label>
                      <input
                        id="liveSingleLimit"
                        name="liveSingleLimit"
                        type="number"
                        value={formData.liveSingleLimit}
                        className="w-full form-input"
                        placeholder={ConfigProvider.getTranslation(
                          "SingleBetLimit"
                        )}
                        required
                        disabled={loading}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="relative">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="liveMultibleLimit"
                      >
                        {ConfigProvider.getTranslation("MultibleBetLimit")}
                      </label>
                      <input
                        id="liveMultibleLimit"
                        name="liveMultibleLimit"
                        type="number"
                        value={formData.liveMultibleLimit}
                        className="w-full form-input"
                        placeholder={ConfigProvider.getTranslation(
                          "MultibleBetLimit"
                        )}
                        required
                        disabled={loading}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </section>
                <footer>
                  <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
                    <div className="flex justify-between">
                      <button
                        type="button"
                        disabled={dataLoading}
                        className="btn bg-slate-500 hover:bg-slate-600 text-white ml-3 flex items-center"
                        onClick={() => {
                          removeLimitEventMarket();
                        }}
                      >
                        {dataLoading ? (
                          <span>
                            <LoadingSpinner className="w-4 h-4 mb-1" />
                          </span>
                        ) : (
                          <span>
                            <EllipsisHorizontalCircleIcon className="w-4 h-4" />
                          </span>
                        )}
                        <span className="ml-2 uppercase">
                          {ConfigProvider.getTranslation("Reset")}
                        </span>
                      </button>
                      <button
                        type="submit"
                        disabled={dataLoading}
                        className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
                      >
                        <span className="mr-2 uppercase">
                          {ConfigProvider.getTranslation("Button.Save")}
                        </span>
                        {dataLoading ? (
                          <span>
                            <LoadingSpinner className="w-4 h-4 mb-1" />
                          </span>
                        ) : (
                          <span>
                            <ArrowRightCircleIcon className="w-4 h-4" />
                          </span>
                        )}
                      </button>
                    </div>
                  </div>
                </footer>
              </form>
            </div>
          </td>
        </tr>
      )}
      {descriptionOpen2 && (
        <tr role="region" className={`${!descriptionOpen2 && "hidden"}`}>
          <td
            colSpan="5"
            className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3"
          >
            <div className="flex flex-col items-center bg-slate-50 dark:bg-slate-900/30 dark:text-slate-400 p-3 -mt-3">
              <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
                <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
                  <tr>
                    <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-full">
                      <div className="grow font-semibold text-left">
                        {ConfigProvider.getTranslation("Selection")}
                      </div>
                    </th>

                    <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                      <div className="grow font-semibold text-center">
                        {ConfigProvider.getTranslation("Status")}
                      </div>
                    </th>
                    <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                      <div className="grow font-semibold text-center">
                        {ConfigProvider.getTranslation("Rate")}
                      </div>
                    </th>
                    <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap max-w-[120px] min-w-[120px]">
                      &nbsp;
                    </th>
                  </tr>
                </thead>
                {!dataLoading &&
                  selections &&
                  Object.keys(selections).length === 0 && (
                    <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
                      <tr>
                        <td colSpan="5">
                          <NoData />
                        </td>
                      </tr>
                    </tbody>
                  )}
                {dataLoading && (
                  <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
                    <tr>
                      <td colSpan="5" className="text-center py-10">
                        <LoadingSpinner className="w-12 h-12" />
                      </td>
                    </tr>
                  </tbody>
                )}
                {!dataLoading && (
                  <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
                    {Object.keys(selections).map((key, index) => (
                      <MatchOddsTableSelectionItem
                        key={index}
                        eid={eid}
                        sid={sid}
                        market={item.market}
                        selid={item.selid}
                        secid={item.secid}
                        item={selections[key]}
                        oddValues={oddValues}
                        selection={key}
                        setSelections={setSelections}
                        loading={loading}
                      />
                    ))}
                  </tbody>
                )}
              </table>
            </div>
          </td>
        </tr>
      )}
    </tbody>
  );
}
function MatchOddsTableSelectionItem({
  eid,
  sid,
  market,
  selid,
  secid,
  item,
  oddValues,
  selection,
  setSelections,
  loading,
}) {
  const ConfigProvider = useConfig();
  const ToastrContext = useToastr();

  const [editOdd, setEditOdd] = useState(false);
  const [odd, setOdd] = useState(item.odd);

  const [confirmLoading, setConfirmLoading] = useState(false);

  const setIsShowMatchMarketSelection = (confirmItem) => {
    if (!loading && !confirmLoading) {
      setConfirmLoading(true);

      Requests.postData("/sports/setIsShowMatchMarketSelection", {
        eid: eid,
        sid: sid,
        market: market,
        selid: selid,
        secid: secid,
        selection: selection,
        isShow: confirmItem.type === "isShow" ? !confirmItem.data.isShow : null,
        odd: confirmItem.type === "odd" ? confirmItem.data.odd : null,
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res) &&
              ToastrContext.showToast({
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            ToastrContext.showToast({
              type: "success",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.SuccessMessage"),
            });

            setSelections((prev) => {
              return {
                ...prev,
                [selection]: {
                  ...prev[selection],
                  isShow:
                    confirmItem.type === "isShow"
                      ? !confirmItem.data.isShow
                      : prev[selection].isShow,
                  odd:
                    confirmItem.type === "odd"
                      ? confirmItem.data.odd
                      : prev[selection].odd,
                },
              };
            });
          }
          setConfirmLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          setConfirmLoading(false);
        });
    }
  };

  return (
    <tr>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left text-slate-900 dark:text-slate-100">
          {item.name}
        </div>
      </td>
      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <Link
          className={classNames(
            "inline-flex font-medium rounded-full text-center px-2.5 py-0.5",
            item.isShow
              ? "bg-emerald-100 dark:bg-emerald-400/30 text-emerald-600 dark:text-emerald-400"
              : "bg-slate-100 dark:bg-slate-700 text-slate-500 dark:text-slate-400"
          )}
          onClick={() => {
            setIsShowMatchMarketSelection({ type: "isShow", data: item });
          }}
        >
          {item.isShow
            ? ConfigProvider.getTranslation("Active")
            : ConfigProvider.getTranslation("Passive")}
        </Link>
      </td>
      <td
        colSpan={2}
        className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"
      >
        {editOdd ? (
          <form
            className="flex space-x-2"
            onSubmit={(e) => {
              e.preventDefault();
              setIsShowMatchMarketSelection({
                type: "odd",
                data: { ...item, odd: odd },
              });
            }}
          >
            <Dropdown
              name="rate"
              items={oddValues}
              selected={odd}
              setSelected={(val) => {
                setOdd(val);
              }}
              disabled={loading}
            />
            <button
              type="submit"
              className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
            >
              <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
              <span className="ml-2">
                {ConfigProvider.getTranslation("Button.Save")}
              </span>
            </button>
          </form>
        ) : (
          <div className="flex items-center space-x-2">
            <span className="font-semibold mx-2 grow">{item.odd}</span>
            <button
              type="button"
              className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
              onClick={() => setEditOdd(true)}
            >
              <PencilSquareIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
            </button>
          </div>
        )}
      </td>
    </tr>
  );
}
export default MatchOdds;
