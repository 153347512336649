import React, { useEffect, useState } from "react";
import { useConfig } from "../../context/ConfigContext";
import { useNavigate } from "react-router-dom";
import { checkRole, diagnosticResponse } from "../../helpers/Helpers";
import Requests from "../../helpers/Requests";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import Alert from "../../components/Alert";
import TreeMain from "../../components/Tree/TreeMain";
import Confirm from "../../components/Confirm";

function BulkMessage() {
  const ConfigProvider = useConfig();

  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "CardsBulkMessage")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [formData, setFormData] = useState({
    message: "",
    title: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const submitForm = () => {
    let requiredFieldErros = [];

    if (requiredFieldErros.length > 0) {
      setMessages([
        {
          type: "error",
          text:
            "[" +
            requiredFieldErros.join(",") +
            "] " +
            ConfigProvider.getTranslation("Forms.EmptyValueError"),
        },
      ]);
      document.getElementById("mainBody").scrollTo(0, 0);
    } else if (!loading) {
      setLoading(true);
      setMessages([]);
      Requests.postData("/cards/bulkMessage", formData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            setMessages([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setMessages([
              {
                type: "success",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.SuccessMessage"),
              },
            ]);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  const [showConfirm, setShowConfirm] = useState(false);
  return (
    <div className="flex space-x-4">
      {ConfigProvider.config.isShopSystem &&
      (ConfigProvider.config.user.auth === "0") |
        (ConfigProvider.config.user.auth === "1") ? (
        <TreeMain />
      ) : null}
      <div className="relative grow min-w-0">
        <WelcomeBanner />
        <form
          className="w-full border-b border-slate-200 dark:border-slate-700"
          onSubmit={(e) => {
            e.preventDefault();
            setShowConfirm(true);
          }}
          autoComplete="off"
        >
          {/* Panel body */}
          <div className="p-6 space-y-6">
            <div className="w-full mt-2 mb-4">
              <Alert messages={messages} setMessages={setMessages} />
            </div>
            <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
              {ConfigProvider.getTranslation("AddMessage")}
            </h2>
            <section>
              <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
                <div className="relative col-span-2">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="title"
                  >
                    {ConfigProvider.getTranslation("Title")}
                  </label>
                  <input
                    id="title"
                    name="title"
                    type="text"
                    value={formData.title}
                    onChange={handleChange}
                    className="w-full form-input"
                    placeholder={ConfigProvider.getTranslation("Title")}
                    required
                    maxLength={255}
                    disabled={loading}
                  />
                </div>
                <div className="relative col-span-2">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="message"
                  >
                    {ConfigProvider.getTranslation("Message")}
                  </label>

                  <textarea
                    id="message"
                    name="message"
                    type="text"
                    value={formData.message}
                    onChange={handleChange}
                    className="w-full form-input min-h-[200px]"
                    placeholder={ConfigProvider.getTranslation("Message")}
                    maxLength={2000}
                    disabled={loading}
                  />
                </div>
              </div>
            </section>
          </div>
          {/* Panel footer */}
          <footer>
            <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
              <div className="flex justify-between">
                <button
                  type="submit"
                  disabled={loading}
                  className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
                >
                  <span className="mr-2 uppercase">
                    {ConfigProvider.getTranslation("Button.Send")}
                  </span>
                  {loading ? (
                    <span>
                      <LoadingSpinner className="w-4 h-4 mb-1" />
                    </span>
                  ) : (
                    <span>
                      <ArrowRightCircleIcon className="w-4 h-4" />
                    </span>
                  )}
                </button>
              </div>
            </div>
          </footer>
        </form>
      </div>
      {showConfirm && (
        <Confirm
          title={ConfigProvider.getTranslation("AreYouSure")}
          text={
            <>
              <p>{`${ConfigProvider.getTranslation("Title")}: ${
                formData.title
              }`}</p>
              <p>{`${ConfigProvider.getTranslation("Message")}: ${
                formData.message
              }`}</p>
            </>
          }
          confirm={() => {
            submitForm();
          }}
          callback={() => {
            setShowConfirm(false);
          }}
        />
      )}
    </div>
  );
}
function WelcomeBanner() {
  const ConfigProvider = useConfig();
  return (
    <div className="relative bg-indigo-200 dark:bg-indigo-500 p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
      {/* Background illustration */}
      <div
        className="absolute right-0 top-0 -mt-4 mr-16 pointer-events-none hidden xl:block"
        aria-hidden="true"
      >
        <svg width="319" height="198" xmlnsXlink="http://www.w3.org/1999/xlink">
          <defs>
            <path id="welcome-a" d="M64 0l64 128-64-20-64 20z" />
            <path id="welcome-e" d="M40 0l40 80-40-12.5L0 80z" />
            <path id="welcome-g" d="M40 0l40 80-40-12.5L0 80z" />
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="welcome-b">
              <stop stopColor="#A5B4FC" offset="0%" />
              <stop stopColor="#818CF8" offset="100%" />
            </linearGradient>
            <linearGradient
              x1="50%"
              y1="24.537%"
              x2="50%"
              y2="100%"
              id="welcome-c"
            >
              <stop stopColor="#4338CA" offset="0%" />
              <stop stopColor="#6366F1" stopOpacity="0" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="none" fillRule="evenodd">
            <g transform="rotate(64 36.592 105.604)">
              <mask id="welcome-d" fill="#fff">
                <use xlinkHref="#welcome-a" />
              </mask>
              <use fill="url(#welcome-b)" xlinkHref="#welcome-a" />
              <path
                fill="url(#welcome-c)"
                mask="url(#welcome-d)"
                d="M64-24h80v152H64z"
              />
            </g>
            <g transform="rotate(-51 91.324 -105.372)">
              <mask id="welcome-f" fill="#fff">
                <use xlinkHref="#welcome-e" />
              </mask>
              <use fill="url(#welcome-b)" xlinkHref="#welcome-e" />
              <path
                fill="url(#welcome-c)"
                mask="url(#welcome-f)"
                d="M40.333-15.147h50v95h-50z"
              />
            </g>
            <g transform="rotate(44 61.546 392.623)">
              <mask id="welcome-h" fill="#fff">
                <use xlinkHref="#welcome-g" />
              </mask>
              <use fill="url(#welcome-b)" xlinkHref="#welcome-g" />
              <path
                fill="url(#welcome-c)"
                mask="url(#welcome-h)"
                d="M40.333-15.147h50v95h-50z"
              />
            </g>
          </g>
        </svg>
      </div>

      {/* Content */}
      <div className="relative">
        <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold mb-1">
          {ConfigProvider.getTranslation("BulkMessage")}💫
        </h1>
      </div>
    </div>
  );
}
export default BulkMessage;
