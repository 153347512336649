import React, { useEffect, useState } from "react";
import { useConfig } from "../context/ConfigContext";
import {
  ArrowDownLeftIcon,
  ArrowLeftEndOnRectangleIcon,
  ArrowRightEndOnRectangleIcon,
  ArrowRightStartOnRectangleIcon,
  ArrowUpRightIcon,
  ArrowUturnLeftIcon,
  CalendarIcon,
} from "@heroicons/react/24/outline";
import Requests from "../helpers/Requests";
import { useToastr } from "../context/ToastrContext";
import { usePrefences } from "../context/PrefencesContext";
import ShowTree from "../components/ShowTree";
import RecentTransactions from "../components/Finance/RecentTransactions";
import NoData from "../components/NoData";
import {
  addDays,
  checkRole,
  diagnosticResponse,
  formatDate,
  getDate,
} from "../helpers/Helpers";
import CardMaker from "../components/CardMaker";

import { ReactComponent as RouletteSVG } from "../assets/img/roulette.svg";
import { ArrowUturnRightIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import { ReactComponent as DiamondSVG } from "../assets/img/diamond.svg";
import { ArrowRightCircleIcon } from "@heroicons/react/24/solid";

function DashboardComponent({ id = undefined }) {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();
  const ToastrContext = useToastr();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [formData, setFormData] = useState({
    _id: id,
    date1: formatDate(getDate()),
    date2: formatDate(addDays(getDate(), 1)),
  });
  const [tempData, setTempData] = useState({
    date1: formatDate(getDate()),
    date2: formatDate(addDays(getDate(), 1)),
  });
  const getItems = () => {
    if (!loading) {
      setLoading(true);
      Requests.postData("/accounts/getAccountDashboard", formData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            ToastrContext.showToast({
              type: "error",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.ErrorMessage"),
            });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setData(res.data);
            setTempData({
              ...tempData,
              date1: formData.date1,
              date2: formData.date2,
            });
          }
          setLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          getItems();
        }}
        className="w-full flex mb-2"
      >
        <div className="hidden grow md:inline-block">&nbsp;</div>
        <div className="flex flex-col gap-2 w-full md:max-w-md md:flex-row">
          <input
            id="date1"
            name="date1"
            type="date"
            value={formData.date1}
            onChange={(e) => {
              return setFormData({ ...formData, date1: e.target.value });
            }}
            className="w-full form-input"
            placeholder={ConfigProvider.getTranslation("StartDate")}
            disabled={loading}
            required
          />
          <input
            id="date2"
            name="date2"
            type="date"
            value={formData.date2}
            onChange={(e) => {
              return setFormData({ ...formData, date2: e.target.value });
            }}
            className="w-full form-input"
            placeholder={ConfigProvider.getTranslation("EndDate")}
            disabled={loading}
            required
          />
          <button
            type="submit"
            className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
            disabled={loading}
          >
            <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
            <span className="ml-2">
              {ConfigProvider.getTranslation("Button.Send")}
            </span>
          </button>
        </div>
      </form>
      <div className="grid grid-cols-12 gap-6 p-2">
        {id && (
          <CardMaker
            icon="money"
            text={ConfigProvider.getTranslation("Balance")}
            val={
              ConfigProvider.customData
                ? `${ConfigProvider.customData.convertedBalance} ${ConfigProvider.customData.curSession}`
                : ""
            }
            desc={
              ConfigProvider.customData &&
              ConfigProvider.customData.currency !==
                ConfigProvider.customData.curSession
                ? ConfigProvider.getTranslation("Amount")
                : ""
            }
            desc2={
              ConfigProvider.customData &&
              ConfigProvider.customData.currency !==
                ConfigProvider.customData.curSession
                ? `${ConfigProvider.customData.strBalance} ${ConfigProvider.customData.currency}`
                : ""
            }
            compare={
              ConfigProvider.customData
                ? ConfigProvider.customData.balance
                : undefined
            }
            loading={ConfigProvider.customData === null}
          />
        )}
        {id === undefined &&
          (ConfigProvider.config.user.auth === "1" ||
            ConfigProvider.config.user.auth === "2") && (
            <CardMaker
              icon="money"
              text={ConfigProvider.getTranslation("Balance")}
              val={data ? `${data.convertedBalance} ${data.curSession}` : ""}
              desc={ConfigProvider.getTranslation("Amount")}
              desc2={
                data && data.currency !== data.curSession
                  ? `${data.strBalance} ${data.currency}`
                  : ""
              }
              compare={data && data.balance > 0 ? 1 : undefined}
              loading={loading}
            />
          )}

        <CardMaker
          icon="money"
          text={ConfigProvider.getTranslation("TotalBalanceOfPlayers")}
          val={data ? `${data.strTotalBalance} ${data.curSession}` : ""}
          desc={ConfigProvider.getTranslation("Amount")}
          compare={data && data.totalBalance > 0 ? 1 : undefined}
          loading={loading}
        />
        {checkRole(
          ConfigProvider.config.user,
          "AccountsDashboardViewDepositsAndWithdrawals"
        ) && (
          <CardMaker
            icon={<ArrowUpRightIcon className="w-8 h-8 mr-2" />}
            text={ConfigProvider.getTranslation("CreditAddition")}
            val={
              data ? `${data.strTotalCreditAddition} ${data.curSession}` : ""
            }
            desc={ConfigProvider.getTranslation("Amount")}
            compare={data && data.totalCreditAddition > 0 ? 1 : undefined}
            loading={loading}
          />
        )}
        {checkRole(
          ConfigProvider.config.user,
          "AccountsDashboardViewDepositsAndWithdrawals"
        ) && (
          <CardMaker
            icon={<ArrowDownLeftIcon className="w-8 h-8 mr-2" />}
            text={ConfigProvider.getTranslation("CreditDeduction")}
            val={
              data ? `${data.strTotalCreditDeduction} ${data.curSession}` : ""
            }
            desc={ConfigProvider.getTranslation("Amount")}
            compare={data && data.totalCreditDeduction > 0 ? -1 : undefined}
            loading={loading}
          />
        )}
        {checkRole(
          ConfigProvider.config.user,
          "AccountsDashboardViewDepositsAndWithdrawals"
        ) && (
          <CardMaker
            icon={<ArrowRightEndOnRectangleIcon className="w-8 h-8 mr-2" />}
            text={ConfigProvider.getTranslation("Deposit")}
            val={data ? `${data.strTotalDeposit} ${data.curSession}` : ""}
            desc={ConfigProvider.getTranslation("Amount")}
            compare={data && data.totalDeposit > 0 ? 1 : undefined}
            loading={loading}
          />
        )}
        {checkRole(
          ConfigProvider.config.user,
          "AccountsDashboardViewDepositsAndWithdrawals"
        ) && (
          <CardMaker
            icon={<ArrowLeftEndOnRectangleIcon className="w-8 h-8 mr-2" />}
            text={ConfigProvider.getTranslation("Withdrawal")}
            val={data ? `${data.strTotalWithdrawal} ${data.curSession}` : ""}
            desc={ConfigProvider.getTranslation("Amount")}
            compare={data && data.totalWithdrawal > 0 ? -1 : undefined}
            loading={loading}
          />
        )}

        {checkRole(
          ConfigProvider.config.user,
          "AccountsDashboardViewDepositsAndWithdrawals"
        ) && (
          <CardMaker
            icon={<ArrowUturnLeftIcon className="w-8 h-8 mr-2" />}
            text={`${ConfigProvider.getTranslation(
              "Discount"
            )} / ${ConfigProvider.getTranslation("NoWageringCredit")}`}
            val={data ? `${data.strTotalDiscount} ${data.curSession}` : ""}
            desc={ConfigProvider.getTranslation("Amount")}
            compare={data && data.totalDiscount > 0 ? 1 : undefined}
            loading={loading}
          />
        )}
        {checkRole(
          ConfigProvider.config.user,
          "AccountsDashboardViewSportGGR"
        ) && (
          <CardMaker
            icon="sport"
            text={ConfigProvider.getTranslation("Sports")}
            val={data ? `${data.strNetSport} ${data.curSession}` : ""}
            compare={data ? data.netSport : undefined}
            desc={
              <span
                className={`${
                  data
                    ? data.ggrSport > 0
                      ? "text-emerald-500"
                      : data.ggrSport < 0
                      ? "text-rose-500"
                      : ""
                    : ""
                }`}
              >{`GGR ${
                data ? `${data.ggrSport > 0 ? "+" : ""}${data.ggrSport}%` : ""
              }`}</span>
            }
            desc2={`${new Intl.DateTimeFormat("default", {
              day: "numeric",
              month: "numeric",
              year: "numeric",
              timeZone: PrefencesProvider.prefences.timezone,
            }).format(new Date(tempData.date1))} - ${new Intl.DateTimeFormat(
              "default",
              {
                day: "numeric",
                month: "numeric",
                year: "numeric",
                timeZone: PrefencesProvider.prefences.timezone,
              }
            ).format(new Date(tempData.date2))}`}
            loading={loading}
          />
        )}
        {checkRole(
          ConfigProvider.config.user,
          "AccountsDashboardViewCasinoGGR"
        ) && (
          <CardMaker
            icon="casino"
            text={ConfigProvider.getTranslation("Slot")}
            val={data ? `${data.strNetSlot} ${data.curSession}` : ""}
            compare={data ? data.netSlot : undefined}
            desc={
              <span
                className={`${
                  data
                    ? data.ggrSlot > 0
                      ? "text-emerald-500"
                      : data.ggrSlot < 0
                      ? "text-rose-500"
                      : ""
                    : ""
                }`}
              >{`GGR ${
                data ? `${data.ggrSlot > 0 ? "+" : ""}${data.ggrSlot}%` : ""
              }`}</span>
            }
            desc2={`${new Intl.DateTimeFormat("default", {
              day: "numeric",
              month: "numeric",
              year: "numeric",
              timeZone: PrefencesProvider.prefences.timezone,
            }).format(new Date(tempData.date1))} - ${new Intl.DateTimeFormat(
              "default",
              {
                day: "numeric",
                month: "numeric",
                year: "numeric",
                timeZone: PrefencesProvider.prefences.timezone,
              }
            ).format(new Date(tempData.date2))}`}
            loading={loading}
          />
        )}
        {checkRole(
          ConfigProvider.config.user,
          "AccountsDashboardViewCasinoGGR"
        ) && (
          <CardMaker
            icon={<RouletteSVG className="w-8 h-8 mr-2 fill-indigo-300" />}
            text={ConfigProvider.getTranslation("LiveCasino")}
            val={data ? `${data.strNetLiveCasino} ${data.curSession}` : ""}
            compare={data ? data.netLiveCasino : undefined}
            desc={
              <span
                className={`${
                  data
                    ? data.ggrLiveCasino > 0
                      ? "text-emerald-500"
                      : data.ggrLiveCasino < 0
                      ? "text-rose-500"
                      : ""
                    : ""
                }`}
              >{`GGR ${
                data
                  ? `${data.ggrLiveCasino > 0 ? "+" : ""}${data.ggrLiveCasino}%`
                  : ""
              }`}</span>
            }
            desc2={`${new Intl.DateTimeFormat("default", {
              day: "numeric",
              month: "numeric",
              year: "numeric",
              timeZone: PrefencesProvider.prefences.timezone,
            }).format(new Date(tempData.date1))} - ${new Intl.DateTimeFormat(
              "default",
              {
                day: "numeric",
                month: "numeric",
                year: "numeric",
                timeZone: PrefencesProvider.prefences.timezone,
              }
            ).format(new Date(tempData.date2))}`}
            loading={loading}
          />
        )}
        {checkRole(
          ConfigProvider.config.user,
          "AccountsDashboardViewCasinoGGR"
        ) && (
          <CardMaker
            icon="wagering"
            text={ConfigProvider.getTranslation("BonusWagering")}
            val={data ? `${data.strNetBonusWagering} ${data.curSession}` : ""}
            compare={data ? data.netBonusWagering : undefined}
            desc={
              <span
                className={`${
                  data
                    ? data.ggrBonusWagering > 0
                      ? "text-emerald-500"
                      : data.ggrBonusWagering < 0
                      ? "text-rose-500"
                      : ""
                    : ""
                }`}
              >{`GGR ${
                data
                  ? `${data.ggrBonusWagering > 0 ? "+" : ""}${
                      data.ggrBonusWagering
                    }%`
                  : ""
              }`}</span>
            }
            desc2={`${new Intl.DateTimeFormat("default", {
              day: "numeric",
              month: "numeric",
              year: "numeric",
              timeZone: PrefencesProvider.prefences.timezone,
            }).format(new Date(tempData.date1))} - ${new Intl.DateTimeFormat(
              "default",
              {
                day: "numeric",
                month: "numeric",
                year: "numeric",
                timeZone: PrefencesProvider.prefences.timezone,
              }
            ).format(new Date(tempData.date2))}`}
            loading={loading}
          />
        )}
        {checkRole(
          ConfigProvider.config.user,
          "AccountsDashboardViewCasinoGGR"
        ) && (
          <CardMaker
            icon="bonus"
            text={ConfigProvider.getTranslation("BonusAward")}
            val={data ? `${data.strNetBonusAward} ${data.curSession}` : ""}
            compare={data ? data.netBonusAward : undefined}
            desc={
              <span
                className={`${
                  data
                    ? data.ggrBonusAward > 0
                      ? "text-emerald-500"
                      : data.ggrBonusAward < 0
                      ? "text-rose-500"
                      : ""
                    : ""
                }`}
              >{`GGR ${
                data
                  ? `${data.ggrBonusAward > 0 ? "+" : ""}${data.ggrBonusAward}%`
                  : ""
              }`}</span>
            }
            desc2={`${new Intl.DateTimeFormat("default", {
              day: "numeric",
              month: "numeric",
              year: "numeric",
              timeZone: PrefencesProvider.prefences.timezone,
            }).format(new Date(tempData.date1))} - ${new Intl.DateTimeFormat(
              "default",
              {
                day: "numeric",
                month: "numeric",
                year: "numeric",
                timeZone: PrefencesProvider.prefences.timezone,
              }
            ).format(new Date(tempData.date2))}`}
            loading={loading}
          />
        )}
        {checkRole(
          ConfigProvider.config.user,
          "AccountsDashboardViewCasinoGGR"
        ) && (
          <CardMaker
            icon="freespin"
            text={ConfigProvider.getTranslation("Freespin")}
            val={data ? `${data.strNetFreespin} ${data.currency}` : ""}
            compare={data ? data.netFreespin : undefined}
            desc={
              <span
                className={`${
                  data
                    ? data.ggrFreespin > 0
                      ? "text-emerald-500"
                      : data.ggrFreespin < 0
                      ? "text-rose-500"
                      : ""
                    : ""
                }`}
              >{`GGR ${
                data
                  ? `${data.ggrFreespin > 0 ? "+" : ""}${data.ggrFreespin}%`
                  : ""
              }`}</span>
            }
            desc2={`${new Intl.DateTimeFormat("default", {
              day: "numeric",
              month: "numeric",
              year: "numeric",
              timeZone: PrefencesProvider.prefences.timezone,
            }).format(new Date(tempData.date1))} - ${new Intl.DateTimeFormat(
              "default",
              {
                day: "numeric",
                month: "numeric",
                year: "numeric",
                timeZone: PrefencesProvider.prefences.timezone,
              }
            ).format(new Date(tempData.date2))}`}
            loading={loading}
          />
        )}
        {checkRole(
          ConfigProvider.config.user,
          "AccountsDashboardViewCasinoGGR"
        ) && (
          <CardMaker
            icon={<DiamondSVG className="w-8 h-8 mr-2 text-indigo-300" />}
            text={ConfigProvider.getTranslation("Jackpot")}
            val={data ? `${data.strNetJackpot} ${data.currency}` : ""}
            compare={data ? data.netJackpot : undefined}
            desc={
              <span
                className={`${
                  data
                    ? data.ggrJackpot > 0
                      ? "text-emerald-500"
                      : data.ggrJackpot < 0
                      ? "text-rose-500"
                      : ""
                    : ""
                }`}
              >{`GGR ${
                data
                  ? `${data.ggrJackpot > 0 ? "+" : ""}${data.ggrJackpot}%`
                  : ""
              }`}</span>
            }
            desc2={`${new Intl.DateTimeFormat("default", {
              day: "numeric",
              month: "numeric",
              year: "numeric",
              timeZone: PrefencesProvider.prefences.timezone,
            }).format(new Date(tempData.date1))} - ${new Intl.DateTimeFormat(
              "default",
              {
                day: "numeric",
                month: "numeric",
                year: "numeric",
                timeZone: PrefencesProvider.prefences.timezone,
              }
            ).format(new Date(tempData.date2))}`}
            loading={loading}
          />
        )}
        <CardMaker
          icon={<UserPlusIcon className="w-8 h-8 mr-2" />}
          text={ConfigProvider.getTranslation("RegisteredUserCount")}
          val={data ? data.registeredUserCount : ""}
          compare={0}
          desc={<></>}
          desc2={`${new Intl.DateTimeFormat("default", {
            day: "numeric",
            month: "numeric",
            year: "numeric",
            timeZone: PrefencesProvider.prefences.timezone,
          }).format(new Date(tempData.date1))} - ${new Intl.DateTimeFormat(
            "default",
            {
              day: "numeric",
              month: "numeric",
              year: "numeric",
              timeZone: PrefencesProvider.prefences.timezone,
            }
          ).format(new Date(tempData.date2))}`}
          loading={false}
        />
        <CardMaker
          icon={<ArrowUturnRightIcon className="w-8 h-8 mr-2" />}
          text={ConfigProvider.getTranslation("LoggedInUser")}
          val={data ? data.loggedInUser : ""}
          compare={0}
          desc={<></>}
          desc2={`${new Intl.DateTimeFormat("default", {
            day: "numeric",
            month: "numeric",
            year: "numeric",
            timeZone: PrefencesProvider.prefences.timezone,
          }).format(new Date(tempData.date1))} - ${new Intl.DateTimeFormat(
            "default",
            {
              day: "numeric",
              month: "numeric",
              year: "numeric",
              timeZone: PrefencesProvider.prefences.timezone,
            }
          ).format(new Date(tempData.date2))}`}
          loading={false}
        />
        {id &&
          checkRole(
            ConfigProvider.config.user,
            "AccountsDashboardViewTree"
          ) && <TreeCard data={ConfigProvider.customData} />}
        {id &&
          checkRole(
            ConfigProvider.config.user,
            "AccountsDashboardViewLastTransactions"
          ) && (
            <RecentTransactions
              data={data ? data.balanceTransactions : undefined}
              creditDetail={true}
            />
          )}
        {id &&
          checkRole(
            ConfigProvider.config.user,
            "AccountsDashboardViewLastLogins"
          ) && <LoginCard data={data ? data.logins : undefined} />}
      </div>
    </>
  );
}
function TreeCard({ data }) {
  const ConfigProvider = useConfig();

  return (
    <div className="col-span-full bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
      <header className="px-5 py-4 border-b bg-slate-100 dark:bg-slate-700 border-slate-200 dark:border-slate-700">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          {ConfigProvider.getTranslation("Role.Shop")}
        </h2>
      </header>
      <div className="p-3">
        <div>{data && <ShowTree shop={data._id} />}</div>
      </div>
    </div>
  );
}
function LoginCard({ data }) {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();
  return (
    <div className="col-span-full xl:col-span-6 bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700">
      <header className="px-5 py-4 border-b bg-slate-100 dark:bg-slate-700 border-slate-200 dark:border-slate-700">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          {ConfigProvider.getTranslation("RecentLogins")}
          {data && data.length > 0 && (
            <div className="ml-1 inline-block text-sm font-semibold text-white px-1.5 bg-amber-500 rounded-full">
              {data.length}
            </div>
          )}
        </h2>
      </header>
      <div className="p-3">
        <div>
          <ul className="my-1">
            {data && data.length === 0 && <NoData />}
            {data
              ? data.map((item) => (
                  <li key={item._id} className="flex items-center px-2">
                    <div
                      className={`${
                        item.type === "Logs.Login"
                          ? "bg-emerald-500"
                          : "bg-rose-500"
                      } w-9 h-9 rounded-full shrink-0 my-2 mr-3`}
                    >
                      {item.type === "Logs.Login" ? (
                        <ArrowRightEndOnRectangleIcon className="w-9 h-9 text-indigo-50 p-2" />
                      ) : (
                        <ArrowRightStartOnRectangleIcon className="w-9 h-9 text-indigo-50 p-2" />
                      )}
                    </div>
                    <div className="grow flex min-w-0 items-center border-b gap-2 border-slate-100 dark:border-slate-700 text-sm py-2">
                      <div className="text-left grow min-w-0">
                        <div className="flex items-center text-xs w-full mb-0.5 text-slate-500">
                          <CalendarIcon className="w-4 h-4 mr-1" />
                          {new Intl.DateTimeFormat("default", {
                            day: "numeric",
                            month: "numeric",
                            hour: "numeric",
                            year: "numeric",
                            minute: "numeric",
                            second: "numeric",
                            timeZone: PrefencesProvider.prefences.timezone,
                          }).format(new Date(item.date))}
                        </div>
                        {item.desc}
                        <div className="text-slate-500 truncate">
                          {item.useragent}
                        </div>
                      </div>
                      <div className="shrink-0 text-right">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`https://ipinfo.io/${item.ip}`}
                          className="font-medium text-indigo-500"
                        >
                          {item.ip}
                        </a>
                      </div>
                    </div>
                  </li>
                ))
              : [1, 2, 3, 4, 5].map((item) => (
                  <li
                    key={item}
                    className="flex w-full mb-2 h-10 animate-pulse bg-slate-300 dark:bg-slate-500 rounded"
                  >
                    &nbsp;
                  </li>
                ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
export default DashboardComponent;
