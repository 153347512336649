import React from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";
import {
  BanknotesIcon,
  UserIcon,
  ArchiveBoxIcon,
  DocumentTextIcon,
  RectangleStackIcon,
  ComputerDesktopIcon,
  UserGroupIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/solid";
import { useConfig } from "../../../context/ConfigContext";
import { checkRole } from "../../../helpers/Helpers";

function AccountSidebar() {
  const ConfigProvider = useConfig();
  const location = useLocation();
  const { pathname } = location;
  const { _id } = useParams();
  return (
    <div className="flex flex-nowrap overflow-x-scroll no-scrollbar md:block md:overflow-auto px-3 py-6 border-b md:border-b-0 md:border-r border-slate-200 dark:border-slate-700 min-w-[15rem] md:space-y-3">
      {/* Group 1 */}
      <div>
        <div className="text-xs font-semibold text-slate-400 dark:text-slate-500 uppercase mb-3">
          {ConfigProvider.getTranslation("Account")}
        </div>
        <ul className="flex flex-nowrap md:block mr-3 md:mr-0">
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink
              end
              to={`/accounts/account/view/${_id}`}
              className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${
                pathname.startsWith("/accounts/account/view/") &&
                "bg-indigo-50 dark:bg-indigo-500/30"
              }`}
            >
              <ComputerDesktopIcon
                className={`w-4 h-4 shrink-0 mr-2 ${
                  pathname.startsWith("/accounts/account/view/")
                    ? "text-indigo-500 dark:text-indigo-400"
                    : "text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200"
                }`}
              />

              <span
                className={`text-sm font-medium ${
                  pathname.startsWith("/accounts/account/view/")
                    ? "text-indigo-500 dark:text-indigo-400"
                    : "text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200"
                }`}
              >
                {ConfigProvider.getTranslation("Dashboard")}
              </span>
            </NavLink>
          </li>
          <li className="mr-0.5 md:mr-0 md:mb-0.5">
            <NavLink
              end
              to={`/accounts/account/edit/${_id}`}
              className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${
                pathname.startsWith("/accounts/account/edit") &&
                "bg-indigo-50 dark:bg-indigo-500/30"
              }`}
            >
              <UserIcon
                className={`w-4 h-4 shrink-0 mr-2 ${
                  pathname.startsWith("/accounts/account/edit")
                    ? "text-indigo-500 dark:text-indigo-400"
                    : "text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200"
                }`}
              />
              <span
                className={`text-sm font-medium ${
                  pathname.startsWith("/accounts/account/edit")
                    ? "text-indigo-500 dark:text-indigo-400"
                    : "text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200"
                }`}
              >
                {ConfigProvider.getTranslation("PersonalInformation")}
              </span>
            </NavLink>
          </li>
          {ConfigProvider.config.isShopSystem &&
            checkRole(ConfigProvider.config.user, "AccountsListUsers") && (
              <li className="mr-0.5 md:mr-0 md:mb-0.5">
                <NavLink
                  end
                  to={`/accounts/account/users/${_id}`}
                  className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${
                    pathname.startsWith("/accounts/account/users") &&
                    "bg-indigo-50 dark:bg-indigo-500/30"
                  }`}
                >
                  <UserGroupIcon
                    className={`w-4 h-4 shrink-0 mr-2 ${
                      pathname.startsWith("/accounts/account/users")
                        ? "text-indigo-500 dark:text-indigo-400"
                        : "text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200"
                    }`}
                  />
                  <span
                    className={`text-sm font-medium ${
                      pathname.startsWith("/accounts/account/users")
                        ? "text-indigo-500 dark:text-indigo-400"
                        : "text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200"
                    }`}
                  >
                    {ConfigProvider.getTranslation("Users")}
                  </span>
                </NavLink>
              </li>
            )}
          {checkRole(ConfigProvider.config.user, "AccountsBalance") && (
            <li className="mr-0.5 md:mr-0 md:mb-0.5">
              <NavLink
                end
                to={`/accounts/account/finance/${_id}`}
                className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${
                  pathname.startsWith("/accounts/account/finance") &&
                  "bg-indigo-50 dark:bg-indigo-500/30"
                }`}
              >
                <BanknotesIcon
                  className={`w-4 h-4 shrink-0 mr-2 ${
                    pathname.startsWith("/accounts/account/finance")
                      ? "text-indigo-500 dark:text-indigo-400"
                      : "text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200"
                  }`}
                />
                <span
                  className={`text-sm font-medium ${
                    pathname.startsWith("/accounts/account/finance")
                      ? "text-indigo-500 dark:text-indigo-400"
                      : "text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200"
                  }`}
                >
                  {ConfigProvider.getTranslation("Finance")}
                </span>
              </NavLink>
            </li>
          )}
          {checkRole(ConfigProvider.config.user, "SportBetList") && (
            <li className="mr-0.5 md:mr-0 md:mb-0.5">
              <NavLink
                end
                to={`/accounts/account/tickets/${_id}`}
                className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${
                  pathname.startsWith("/accounts/account/tickets") &&
                  "bg-indigo-50 dark:bg-indigo-500/30"
                }`}
              >
                <RectangleStackIcon
                  className={`w-4 h-4 shrink-0 mr-2 ${
                    pathname.startsWith("/accounts/account/tickets")
                      ? "text-indigo-500 dark:text-indigo-400"
                      : "text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200"
                  }`}
                />
                <span
                  className={`text-sm font-medium ${
                    pathname.startsWith("/accounts/account/tickets")
                      ? "text-indigo-500 dark:text-indigo-400"
                      : "text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200"
                  }`}
                >
                  {ConfigProvider.getTranslation("Tickets")}
                </span>
              </NavLink>
            </li>
          )}
          {checkRole(
            ConfigProvider.config.user,
            "FinanceCreditTransactionsList"
          ) && (
            <li className="mr-0.5 md:mr-0 md:mb-0.5">
              <NavLink
                end
                to={`/accounts/account/creditTransactions/${_id}`}
                className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${
                  pathname.startsWith("/accounts/account/creditTransactions") &&
                  "bg-indigo-50 dark:bg-indigo-500/30"
                }`}
              >
                <DocumentTextIcon
                  className={`w-4 h-4 shrink-0 mr-2 ${
                    pathname.startsWith("/accounts/account/creditTransactions")
                      ? "text-indigo-500 dark:text-indigo-400"
                      : "text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200"
                  }`}
                />
                <span
                  className={`text-sm font-medium ${
                    pathname.startsWith("/accounts/account/creditTransactions")
                      ? "text-indigo-500 dark:text-indigo-400"
                      : "text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200"
                  }`}
                >
                  {ConfigProvider.getTranslation("CreditTransactions")}
                </span>
              </NavLink>
            </li>
          )}
          {checkRole(ConfigProvider.config.user, "FinanceLogsList") && (
            <li className="mr-0.5 md:mr-0 md:mb-0.5">
              <NavLink
                end
                to={`/accounts/account/logs/${_id}`}
                className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${
                  pathname.startsWith("/accounts/account/logs") &&
                  "bg-indigo-50 dark:bg-indigo-500/30"
                }`}
              >
                <ArchiveBoxIcon
                  className={`w-4 h-4 shrink-0 mr-2 ${
                    pathname.startsWith("/accounts/account/logs")
                      ? "text-indigo-500 dark:text-indigo-400"
                      : "text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200"
                  }`}
                />
                <span
                  className={`text-sm font-medium ${
                    pathname.startsWith("/accounts/account/logs")
                      ? "text-indigo-500 dark:text-indigo-400"
                      : "text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200"
                  }`}
                >
                  {ConfigProvider.getTranslation("Logs")}
                </span>
              </NavLink>
            </li>
          )}
          {checkRole(ConfigProvider.config.user, "AccountsListNotes") && (
            <li className="mr-0.5 md:mr-0 md:mb-0.5">
              <NavLink
                end
                to={`/accounts/account/note/list/${_id}`}
                className={`flex items-center px-2.5 py-2 rounded whitespace-nowrap ${
                  pathname.startsWith("/accounts/account/note") &&
                  "bg-indigo-50 dark:bg-indigo-500/30"
                }`}
              >
                <PencilSquareIcon
                  className={`w-4 h-4 shrink-0 mr-2 ${
                    pathname.startsWith("/accounts/account/note")
                      ? "text-indigo-500 dark:text-indigo-400"
                      : "text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200"
                  }`}
                />
                <span
                  className={`text-sm font-medium ${
                    pathname.startsWith("/accounts/account/note")
                      ? "text-indigo-500 dark:text-indigo-400"
                      : "text-slate-600 dark:text-slate-300 hover:text-slate-700 dark:hover:text-slate-200"
                  }`}
                >
                  {ConfigProvider.getTranslation("Notes")}
                </span>
              </NavLink>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
}

export default AccountSidebar;
