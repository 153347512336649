import React, { useEffect, useState } from "react";
import { useConfig } from "../../context/ConfigContext";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import {
  ArrowRightCircleIcon,
  PencilSquareIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import Alert from "../../components/Alert";
import Requests from "../../helpers/Requests";
import { useParams } from "react-router-dom";
import { useToastr } from "../../context/ToastrContext";
import { TypeIcon } from "../../components/Tree/TypeIcon";
import DomainDropdown from "../../components/DomainDropdown";
import { checkRole, diagnosticResponse } from "../../helpers/Helpers";
import AccountLayout from "./helpers/AccountLayout";
import { useTreeData } from "../../context/TreeDataContext";

function EditAccount() {
  return (
    <AccountLayout>
      <EditAccountPage />
    </AccountLayout>
  );
}
function EditAccountPage() {
  const ConfigProvider = useConfig();
  const TreeDataProvider = useTreeData();
  const ToastrContext = useToastr();
  const [loading, setLoading] = useState(false);
  const [messages1, setMessages1] = useState([]);
  const [messages3, setMessages3] = useState([]);
  const [accountInfo, setAccountInfo] = useState(ConfigProvider.customData);
  const [oldAccountInfo, setOldAccountInfo] = useState(null);
  const [editInfo, setEditInfo] = useState(false);
  const [accountSetting, setAccountSetting] = useState(null);
  const [oldAccountSetting, setOldAccountSetting] = useState(null);
  const [editAccountSetting, setEditAccountSetting] = useState(false);
  const { _id } = useParams();

  const getItems = () => {
    if (
      !loading &&
      checkRole(ConfigProvider.config.user, "AccountsViewSettings")
    ) {
      setLoading(true);
      Requests.postData("/accounts/getShopSetting", { _id: _id })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            ToastrContext.showToast({
              type: "error",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.ErrorMessage"),
            });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setAccountSetting(res.data);
          }
          setLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    setAccountInfo(ConfigProvider.customData);
    // eslint-disable-next-line
  }, [ConfigProvider.customData]);
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, []);
  const updateAccount = (e) => {
    e.preventDefault();
    if (!loading) {
      setLoading(true);
      setMessages1([]);
      Requests.postData("/accounts/updateAccount", accountInfo)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            setMessages1([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setMessages1([
              {
                type: "success",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.SuccessMessage"),
              },
            ]);
            ConfigProvider.setRefreshCustomData(true);
            TreeDataProvider.getItems();
            setEditInfo(false);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const updateShopSetting = (e) => {
    e.preventDefault();

    if (!loading) {
      setLoading(true);
      setMessages3([]);

      accountSetting.maxOpenBetLimitSport =
        Number(accountSetting.maxOpenBetLimitSport) || null;

      Requests.postData("/accounts/updateShopSetting", accountSetting)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            setMessages3([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setMessages3([
              {
                type: "success",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.SuccessMessage"),
              },
            ]);
            setEditAccountSetting(false);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  const handleChangeInfo = (e) => {
    if (accountInfo && !loading) {
      const { name, value } = e.target;
      setAccountInfo({ ...accountInfo, [name]: value });
    }
  };
  const handleChangeSetting = (e) => {
    if (accountSetting && !loading) {
      const { name, value } = e.target;
      setAccountSetting({ ...accountSetting, [name]: value });
    }
  };

  return (
    <div className="grow min-w-0 p-2">
      <form
        className="w-full border-b border-slate-200 dark:border-slate-700"
        onSubmit={updateAccount}
        autoComplete="off"
      >
        {/* Panel body */}
        <div className="p-6 space-y-6">
          <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
            {ConfigProvider.getTranslation("Register.AccountInformation")}
            {!editInfo &&
              checkRole(ConfigProvider.config.user, "AccountsEdit") && (
                <button
                  type="button"
                  onClick={() => {
                    if (!editInfo) {
                      setEditInfo(true);
                      setOldAccountInfo(accountInfo);
                    }
                  }}
                  className="ml-2 bg-indigo-500 rounded-lg px-2 py-1 text-sm flex"
                >
                  <PencilSquareIcon className="w-5 h-5 md:mr-2" />
                  <span className="hidden md:inline-block">
                    {ConfigProvider.getTranslation("Edit")}
                  </span>
                </button>
              )}
          </h2>
          <div className="w-full mt-2 mb-4">
            <Alert messages={messages1} setMessages={setMessages1} />
          </div>

          <section>
            <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="uname"
                >
                  {ConfigProvider.getTranslation("Username")}
                </label>
                {editInfo ? (
                  <input
                    id="uname"
                    name="uname"
                    type="text"
                    value={accountInfo && accountInfo.uname}
                    onChange={handleChangeInfo}
                    className="w-full form-input"
                    placeholder={ConfigProvider.getTranslation("Username")}
                    required
                    maxLength={15}
                    disabled={!accountInfo | loading}
                  />
                ) : (
                  <label className="block text-sm w-full">
                    {accountInfo && accountInfo.uname}
                  </label>
                )}
              </div>
              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="name"
                >
                  {ConfigProvider.getTranslation("Name")}
                </label>
                {editInfo ? (
                  <input
                    id="name"
                    name="name"
                    type="text"
                    value={accountInfo && accountInfo.name}
                    onChange={handleChangeInfo}
                    className="w-full form-input"
                    placeholder={ConfigProvider.getTranslation("Name")}
                    maxLength={50}
                    disabled={!accountInfo | loading}
                  />
                ) : (
                  <label className="block text-sm w-full">
                    {accountInfo && accountInfo.name}
                  </label>
                )}
              </div>
              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="currency"
                >
                  {ConfigProvider.getTranslation("Currency")}
                </label>
                {editInfo ? (
                  <input
                    id="currency"
                    name="currency"
                    type="text"
                    value={accountInfo && accountInfo.currency}
                    className="w-full form-input"
                    placeholder={ConfigProvider.getTranslation("Currency")}
                    required
                    maxLength={15}
                    disabled={true}
                  />
                ) : (
                  <label className="block text-sm w-full">
                    {accountInfo && accountInfo.currency}
                  </label>
                )}
              </div>
              {ConfigProvider.config.isShopSystem && (
                <div className="relative">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="uname"
                  >
                    {ConfigProvider.getTranslation("Role")}
                  </label>

                  {accountInfo && (
                    <div className="flex w-full flex-wrap -space-x-px">
                      <button
                        type="button"
                        className={`inline-flex w-1/2 justify-center items-center border text-sm font-semibold p-2  border-slate-200 dark:border-slate-600 rounded-none first:rounded-l last:rounded-r ${
                          accountInfo.auth === "1"
                            ? "text-white dark:text-slate:200 bg-indigo-500"
                            : "bg-slate-50 dark:bg-slate-800 hover:bg-slate-50  dark:hover:bg-slate-900 text-slate-600 dark:text-slate-300"
                        }`}
                        disabled={true}
                      >
                        <span className="mr-2">
                          <TypeIcon auth="1" stat={1} />
                        </span>
                        {ConfigProvider.getTranslation("Role.Reseller")}
                      </button>
                      <button
                        type="button"
                        className={`inline-flex w-1/2 justify-center items-center border text-sm font-semibold p-2  border-slate-200 dark:border-slate-600 rounded-none first:rounded-l last:rounded-r ${
                          accountInfo.auth === "2"
                            ? "text-white dark:text-slate:200 bg-indigo-500"
                            : "bg-slate-50 dark:bg-slate-800 hover:bg-slate-50  dark:hover:bg-slate-900 text-slate-600 dark:text-slate-300"
                        }`}
                        disabled={true}
                      >
                        <span className="mr-2">
                          <TypeIcon auth="2" stat={1} />
                        </span>
                        {ConfigProvider.getTranslation("Role.Shop")}
                      </button>
                    </div>
                  )}
                </div>
              )}

              {ConfigProvider.config.isShopSystem &&
                ConfigProvider.config.user.auth === "0" &&
                checkRole(
                  ConfigProvider.config.user,
                  "AccountsViewSettings"
                ) && (
                  <div className="relative">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="domain"
                    >
                      {ConfigProvider.getTranslation("DomainSettings")}
                    </label>
                    <DomainDropdown
                      name="domain"
                      selected={accountInfo && accountInfo.domain}
                      setSelected={(val) => {
                        editInfo &&
                          accountInfo &&
                          setAccountInfo((prev) => {
                            if (!loading) {
                              if (prev.domain.includes(val)) {
                                return {
                                  ...prev,
                                  domain: prev.domain.filter(
                                    (item) => item !== val
                                  ),
                                };
                              } else {
                                return {
                                  ...prev,
                                  domain: [...prev.domain, val],
                                };
                              }
                            } else {
                              return prev;
                            }
                          });
                      }}
                    />
                  </div>
                )}
              <div className="relative">
                <label
                  className="block text-sm font-medium mb-1"
                  htmlFor="stat"
                >
                  {ConfigProvider.getTranslation("Status")}
                </label>
                <div className="m-3 w-24">
                  {accountInfo && (
                    <div className="flex items-center">
                      <div className="form-switch">
                        <input
                          id="stat"
                          name="stat"
                          type="checkbox"
                          className="sr-only"
                          checked={accountInfo && accountInfo.stat === 1}
                          onChange={() =>
                            editInfo &&
                            setAccountInfo({
                              ...accountInfo,
                              stat: accountInfo.stat === 1 ? 0 : 1,
                            })
                          }
                          disabled={!accountInfo | loading}
                        />
                        <label
                          className="bg-slate-400 dark:bg-slate-700"
                          htmlFor="stat"
                        >
                          <span
                            className="bg-white shadow-sm"
                            aria-hidden="true"
                          ></span>
                        </label>
                      </div>
                      <div className="text-sm text-slate-700 dark:text-slate-400  ml-2">
                        {accountInfo && accountInfo.stat === 1
                          ? ConfigProvider.getTranslation("Active")
                          : ConfigProvider.getTranslation("Passive")}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {ConfigProvider.config.isShopSystem &&
                ConfigProvider.config.user.auth === "0" &&
                checkRole(
                  ConfigProvider.config.user,
                  "AccountsViewSettings"
                ) && (
                  <div className="relative">
                    <label
                      className="block text-sm font-medium mb-1"
                      htmlFor="allowTransferMoney"
                    >
                      &nbsp;
                    </label>
                    <div className="m-3 w-full">
                      {accountInfo && (
                        <div className="flex items-center gap-3">
                          <div className="form-switch">
                            <input
                              id="allowTransferMoney"
                              name="allowTransferMoney"
                              type="checkbox"
                              className="sr-only"
                              checked={
                                accountInfo && accountInfo.allowTransferMoney
                              }
                              onChange={() =>
                                editInfo &&
                                setAccountInfo({
                                  ...accountInfo,
                                  allowTransferMoney:
                                    accountInfo.allowTransferMoney
                                      ? false
                                      : true,
                                })
                              }
                              disabled={!accountInfo | loading}
                            />
                            <label
                              className="bg-slate-400 dark:bg-slate-700"
                              htmlFor="allowTransferMoney"
                            >
                              <span
                                className="bg-white shadow-sm"
                                aria-hidden="true"
                              ></span>
                            </label>
                          </div>
                          <label
                            className="block text-sm font-medium"
                            htmlFor="allowTransferMoney"
                          >
                            {ConfigProvider.getTranslation(
                              "AllowsPlayerstoTransferMoneyBetweenShops"
                            )}
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                )}
            </div>
          </section>
        </div>
        {/* Panel footer */}
        {editInfo && (
          <footer>
            <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
              <div className="flex self-end">
                <button
                  type="button"
                  disabled={!accountInfo | loading}
                  className="btn bg-rose-500 hover:bg-rose-600 text-white ml-3 flex items-center"
                  onClick={() => {
                    setEditInfo(false);
                    setAccountInfo(oldAccountInfo);
                  }}
                >
                  <span>
                    <XMarkIcon className="w-4 h-4 mr-2" />
                  </span>
                  <span className="mr-2 uppercase">
                    {ConfigProvider.getTranslation("Cancel")}
                  </span>
                </button>
                <button
                  type="submit"
                  disabled={!accountInfo | loading}
                  className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
                >
                  <span className="mr-2 uppercase">
                    {ConfigProvider.getTranslation("Button.Send")}
                  </span>
                  {loading ? (
                    <span>
                      <LoadingSpinner className="w-4 h-4 mb-1" />
                    </span>
                  ) : (
                    <span>
                      <ArrowRightCircleIcon className="w-4 h-4" />
                    </span>
                  )}
                </button>
              </div>
            </div>
          </footer>
        )}
      </form>

      {checkRole(ConfigProvider.config.user, "AccountsViewSettings") && (
        <form className="w-full" onSubmit={updateShopSetting}>
          {/* Panel body */}
          <div className="p-6 space-y-6">
            <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
              {ConfigProvider.getTranslation("Settings")}
              {!editAccountSetting &&
                checkRole(
                  ConfigProvider.config.user,
                  "AccountsEditSettings"
                ) && (
                  <button
                    type="button"
                    onClick={() => {
                      if (!editAccountSetting) {
                        setEditAccountSetting(true);
                        setOldAccountSetting(accountSetting);
                      }
                    }}
                    className="ml-2 bg-indigo-500 rounded-lg px-2 py-1 text-sm flex"
                  >
                    <PencilSquareIcon className="w-5 h-5 md:mr-2" />
                    <span className="hidden md:inline-block">
                      {ConfigProvider.getTranslation("Edit")}
                    </span>
                  </button>
                )}
            </h2>
            <div className="w-full mt-2 mb-4">
              <Alert messages={messages3} setMessages={setMessages3} />
            </div>

            <section>
              <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
                <div className="relative">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="limMaxAmount"
                  >
                    {ConfigProvider.getTranslation("MaxAmount")}
                  </label>
                  {editAccountSetting ? (
                    <input
                      id="limMaxAmount"
                      name="limMaxAmount"
                      type="text"
                      value={accountSetting && accountSetting.limMaxAmount}
                      onChange={handleChangeSetting}
                      className="w-full form-input"
                      placeholder={ConfigProvider.getTranslation("MaxAmount")}
                      required
                      maxLength={15}
                      disabled={!accountSetting | loading}
                    />
                  ) : (
                    <label className="block text-sm w-full">
                      {accountSetting && accountSetting.limMaxAmount}
                    </label>
                  )}
                </div>
                <div className="relative">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="limMaxWon"
                  >
                    {ConfigProvider.getTranslation("MaxWon")}
                  </label>
                  {editAccountSetting ? (
                    <input
                      id="limMaxWon"
                      name="limMaxWon"
                      type="text"
                      value={accountSetting && accountSetting.limMaxWon}
                      onChange={handleChangeSetting}
                      className="w-full form-input"
                      placeholder={ConfigProvider.getTranslation("MaxWon")}
                      required
                      maxLength={15}
                      disabled={!accountSetting | loading}
                    />
                  ) : (
                    <label className="block text-sm w-full">
                      {accountSetting && accountSetting.limMaxWon}
                    </label>
                  )}
                </div>
                <div className="relative">
                  <label
                    className="block text-sm font-medium mb-1"
                    htmlFor="maxOpenBetLimitSport"
                  >
                    {`${ConfigProvider.getTranslation(
                      "Sports"
                    )} - ${ConfigProvider.getTranslation("MaxOpenBetLimit")}`}
                  </label>
                  {editAccountSetting ? (
                    <input
                      id="maxOpenBetLimitSport"
                      name="maxOpenBetLimitSport"
                      type="text"
                      value={
                        accountSetting && accountSetting.maxOpenBetLimitSport
                      }
                      onChange={handleChangeSetting}
                      className="w-full form-input"
                      placeholder={ConfigProvider.getTranslation(
                        "MaxOpenBetLimit"
                      )}
                      maxLength={15}
                      disabled={!accountSetting | loading}
                    />
                  ) : (
                    <label className="block text-sm w-full">
                      {accountSetting && accountSetting.maxOpenBetLimitSport
                        ? accountSetting.maxOpenBetLimitSport
                        : ConfigProvider.getTranslation("Undefined")}
                    </label>
                  )}
                </div>
                <div className="relative">
                  <div className="m-3 w-full">
                    {accountSetting && (
                      <div className="flex items-center gap-3">
                        <div className="form-switch">
                          <input
                            id="isPlaceBet"
                            name="isPlaceBet"
                            type="checkbox"
                            className="sr-only"
                            checked={
                              accountSetting && accountSetting.isPlaceBet
                            }
                            onChange={() =>
                              editAccountSetting &&
                              setAccountSetting({
                                ...accountSetting,
                                isPlaceBet: accountSetting.isPlaceBet
                                  ? false
                                  : true,
                              })
                            }
                            disabled={!accountSetting | loading}
                          />
                          <label
                            className="bg-slate-400 dark:bg-slate-700"
                            htmlFor="isPlaceBet"
                          >
                            <span
                              className="bg-white shadow-sm"
                              aria-hidden="true"
                            ></span>
                          </label>
                        </div>
                        <label
                          className="block text-sm font-medium"
                          htmlFor="isPlaceBet"
                        >
                          {ConfigProvider.getTranslation("IsPlaceBet")}
                        </label>
                      </div>
                    )}
                  </div>
                </div>
                <div className="relative">
                  <div className="m-3 w-full">
                    {accountSetting && (
                      <div className="flex items-center gap-3">
                        <div className="form-switch">
                          <input
                            id="casinoActive"
                            name="casinoActive"
                            type="checkbox"
                            className="sr-only"
                            checked={
                              accountSetting && accountSetting.casinoActive
                            }
                            onChange={() =>
                              editAccountSetting &&
                              setAccountSetting({
                                ...accountSetting,
                                casinoActive: accountSetting.casinoActive
                                  ? false
                                  : true,
                              })
                            }
                            disabled={!accountSetting | loading}
                          />
                          <label
                            className="bg-slate-400 dark:bg-slate-700"
                            htmlFor="casinoActive"
                          >
                            <span
                              className="bg-white shadow-sm"
                              aria-hidden="true"
                            ></span>
                          </label>
                        </div>
                        <label
                          className="block text-sm font-medium"
                          htmlFor="casinoActive"
                        >
                          {ConfigProvider.getTranslation("Casino")}
                        </label>
                      </div>
                    )}
                  </div>
                </div>
                <div className="relative">
                  <div className="m-3 w-full">
                    {accountSetting && (
                      <div className="flex items-center gap-3">
                        <div className="form-switch">
                          <input
                            id="liveCasino"
                            name="liveCasino"
                            type="checkbox"
                            className="sr-only"
                            checked={
                              accountSetting && accountSetting.liveCasino
                            }
                            onChange={() =>
                              editAccountSetting &&
                              setAccountSetting({
                                ...accountSetting,
                                liveCasino: accountSetting.liveCasino
                                  ? false
                                  : true,
                              })
                            }
                            disabled={!accountSetting | loading}
                          />
                          <label
                            className="bg-slate-400 dark:bg-slate-700"
                            htmlFor="liveCasino"
                          >
                            <span
                              className="bg-white shadow-sm"
                              aria-hidden="true"
                            ></span>
                          </label>
                        </div>
                        <label
                          className="block text-sm font-medium"
                          htmlFor="liveCasino"
                        >
                          {ConfigProvider.getTranslation("LiveCasino")}
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
          {/* Panel footer */}
          {editAccountSetting && (
            <footer>
              <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
                <div className="flex self-end">
                  <button
                    type="button"
                    disabled={!accountSetting | loading}
                    className="btn bg-rose-500 hover:bg-rose-600 text-white ml-3 flex items-center"
                    onClick={() => {
                      setEditAccountSetting(false);
                      setAccountSetting(oldAccountSetting);
                    }}
                  >
                    <span>
                      <XMarkIcon className="w-4 h-4 mr-2" />
                    </span>
                    <span className="mr-2 uppercase">
                      {ConfigProvider.getTranslation("Cancel")}
                    </span>
                  </button>
                  <button
                    type="submit"
                    disabled={!accountSetting | loading}
                    className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
                  >
                    <span className="mr-2 uppercase">
                      {ConfigProvider.getTranslation("Button.Send")}
                    </span>
                    {loading ? (
                      <span>
                        <LoadingSpinner className="w-4 h-4 mb-1" />
                      </span>
                    ) : (
                      <span>
                        <ArrowRightCircleIcon className="w-4 h-4" />
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </footer>
          )}
        </form>
      )}
    </div>
  );
}

export default EditAccount;
