import React, { useEffect, useState } from "react";

function ColorInput({ title, name, val, handleChange, loading }) {
  const [rgb, setRgb] = useState(
    val && val.length > 0 && val.split(" ").length === 3
      ? val.split(" ").map(Number)
      : [null, null, null]
  );
  useEffect(() => {
    const newVal =
      val && val.length > 0 && val.split(" ").length === 3
        ? val.split(" ").map(Number)
        : [null, null, null];
    if (newVal !== rgb) {
      setRgb(newVal);
    }
    // eslint-disable-next-line
  }, [val]);

  const updateText = (index, value) => {
    const newRgb = [...rgb];
    newRgb[index] = Number(value);
    setRgb(newRgb);
    handleChange(newRgb.join(" "));
  };

  return (
    <div className="relative col-span-2">
      <label className="block text-sm font-medium mb-1" htmlFor={name}>
        {title}
      </label>
      <div className="flex space-x-2">
        {rgb.map((color, index) => (
          <>
            <div className="leading-8">{["R", "G", "B"][index]}</div>
            <input
              key={index}
              id={`${name}-${index}`}
              name={`${name}-${index}`}
              type="number"
              value={color}
              onChange={(e) => updateText(index, e.target.value)}
              className="w-full form-input"
              disabled={loading}
              min="0"
              max="255"
            />
          </>
        ))}
      </div>
    </div>
  );
}

export default ColorInput;
