import React from "react";
import ReactDOM from "react-dom/client";
import axios from "axios";
import App from "./App";
import "./assets/css/app.css";
import "./assets/css/additional-styles/utility-patterns.css";
import "toastr/build/toastr.min.css";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "./context/ThemeContext";
import { ConfigProvider } from "./context/ConfigContext";
import { PrefencesProvider } from "./context/PrefencesContext";
import { DeviceSizeProvider } from "./context/DeviceSizeContext";
import { ToastrProvider } from "./context/ToastrContext";
import { TreeDataProvider } from "./context/TreeDataContext";
import { SocketProvider } from "./context/SocketContext";

axios.defaults.withCredentials = true;
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <DeviceSizeProvider>
    <ConfigProvider>
      <ThemeProvider>
        <PrefencesProvider>
          <ToastrProvider>
            <TreeDataProvider>
              <SocketProvider>
                <BrowserRouter>
                  <App />
                </BrowserRouter>
              </SocketProvider>
            </TreeDataProvider>
          </ToastrProvider>
        </PrefencesProvider>
      </ThemeProvider>
    </ConfigProvider>
  </DeviceSizeProvider>
);
