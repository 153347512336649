import React, { useEffect, useState } from "react";
import { useConfig } from "../../context/ConfigContext";
import { Link, useNavigate } from "react-router-dom";
import { checkRole, diagnosticResponse } from "../../helpers/Helpers";
import Requests from "../../helpers/Requests";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import { ArrowRightCircleIcon } from "@heroicons/react/24/outline";
import Alert from "../../components/Alert";
import Confirm from "../../components/Confirm";
import { useToastr } from "../../context/ToastrContext";
import Dropdown from "../../components/Dropdown";
import readXlsxFile from "read-excel-file";
import NoData from "../../components/NoData";
import {
  CheckCircleIcon,
  PlusIcon,
  XCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";

function BulkFreespin() {
  const ConfigProvider = useConfig();
  const ToastrContext = useToastr();

  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "CasinoBulkFreespin")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [formData, setFormData] = useState({
    template: "",
    count: "",
    betlevel: "1",
    expireDays: "",
  });
  const [freespins, setFreespins] = useState(null);
  const [freespinsSelect, setFreespinsSelect] = useState(null);
  const getItems = () => {
    if (
      !loading &&
      checkRole(ConfigProvider.config.user, "CasinoListFreespins")
    ) {
      setLoading(true);
      Requests.postData("/casino/getFreespinsFilterSelect", {})
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            ToastrContext.showToast({
              type: "error",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.ErrorMessage"),
            });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setFreespins(res.data);
            let a = {};
            Object.keys(res.data).forEach((item) => {
              a[item] = res.data[item].name;
            });
            setFreespinsSelect(a);
          }
          setLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (freespins && freespins.hasOwnProperty(formData.template)) {
      setFormData({
        ...formData,
        count: freespins[formData.template].count,
        betlevel: freespins[formData.template].betlevel,
        expireDays: freespins[formData.template].expireDays,
      });
    }
    // eslint-disable-next-line
  }, [formData.template]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => {
      return { ...prev, [name]: value };
    });
  };
  const submitForm = () => {
    let requiredFieldErros = [];
    if (!formData.template) {
      requiredFieldErros.push(ConfigProvider.getTranslation("Freespin"));
    }
    if (requiredFieldErros.length > 0) {
      setMessages([
        {
          type: "error",
          text:
            "[" +
            requiredFieldErros.join(",") +
            "] " +
            ConfigProvider.getTranslation("Forms.EmptyValueError"),
        },
      ]);
      document.getElementById("mainBody").scrollTo(0, 0);
    } else if (usernames.length === 0) {
      setMessages([
        {
          type: "error",
          text: ConfigProvider.getTranslation("YouMustAddAtLeastOneUser"),
        },
      ]);
    } else if (usernames.find((x) => x.stat === null)) {
      setMessages([
        {
          type: "error",
          text: ConfigProvider.getTranslation("YouShouldCheckFirst"),
        },
      ]);
    } else if (!loading) {
      setLoading(true);
      setMessages([]);
      let postData = formData;
      postData.unames = usernames
        .filter((x) => x.stat === true)
        .map((x) => x.uname);
      Requests.postData("/casino/bulkFreespin", postData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            setMessages([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setMessages([
              {
                type: "success",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.SuccessMessage"),
              },
            ]);
            setAddedUsernames(res.data);
          }
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };
  const [showConfirm, setShowConfirm] = useState(false);
  const [usernames, setUsernames] = useState([]);
  const [form2, setForm2] = useState({ uname: "" });

  const checkUsers = () => {
    if (!loading) {
      setLoading(true);
      Requests.postData("/casino/bulkCardsSelect", {
        data: usernames.map((x) => x.uname),
      })
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            ToastrContext.showToast({
              type: "error",
              text: res.message.length
                ? res.message
                : ConfigProvider.getTranslation("Forms.ErrorMessage"),
            });
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            setUsernames(res.data);
          }
          setLoading(false);
        })
        .catch(() => {
          ToastrContext.showToast({
            type: "error",
            text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
          });
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  const [addedUsernames, setAddedUsernames] = useState([]);
  const reset = () => {
    setMessages([]);
    setFormData({
      ...formData,
      count: "",
      betlevel: "1",
      expireDays: "",
      template: "",
    });
    setShowConfirm(false);
    setForm2({ ...form2, uname: "" });
    setUsernames([]);
    setAddedUsernames([]);
  };
  return (
    <div className="flex space-x-4 items-center justify-center">
      <div className="relative grow min-w-0 max-w-4xl">
        <WelcomeBanner />

        {addedUsernames.length > 0 ? (
          <>
            <div className="w-full">
              <div className="p-6 space-y-6">
                <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
                  {ConfigProvider.getTranslation("Cards")}
                </h2>
                <section>
                  {/* Table */}
                  <div className="overflow-x-auto">
                    <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
                      {/* Table header */}
                      <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
                        <tr>
                          <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                            <div className="grow font-semibold text-left">
                              {ConfigProvider.getTranslation("Username")}
                            </div>
                          </th>
                          <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                            <div className="grow font-semibold text-center">
                              {ConfigProvider.getTranslation("Status")}
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
                        {addedUsernames.length === 0 && (
                          <tr>
                            <td colSpan="3">
                              <NoData />
                            </td>
                          </tr>
                        )}
                        {usernames.map((item, i) => (
                          <tr key={i}>
                            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                              <div className="font-medium text-slate-800 dark:text-slate-100 text-left">
                                {item.uname}
                              </div>
                            </td>
                            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                              <div className="grow flex items-center justify-center">
                                <div
                                  className={`${
                                    addedUsernames.includes(item.uname)
                                      ? "bg-emerald-500"
                                      : "bg-rose-500"
                                  } w-9 h-9 rounded-full shrink-0 my-2 mr-3`}
                                >
                                  {addedUsernames.includes(item.uname) ? (
                                    <CheckCircleIcon className="w-9 h-9 text-indigo-50 p-2" />
                                  ) : (
                                    <XCircleIcon className="w-9 h-9 text-indigo-50 p-2" />
                                  )}
                                </div>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </section>
              </div>
              {/* Panel footer */}

              <footer>
                <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
                  <div className="flex justify-between">
                    <button
                      type="button"
                      disabled={loading}
                      onClick={() => {
                        reset();
                      }}
                      className="btn bg-emerald-500 hover:bg-emerald-600 text-white ml-3 flex items-center h-10"
                    >
                      <span className="mr-2 uppercase">
                        {ConfigProvider.getTranslation("Re-add")}
                      </span>
                    </button>
                    <div className="flex space-x-4">
                      <div className="flex items-center flex-col space-y-2 justify-center">
                        <div
                          className={`bg-emerald-500 w-9 h-9 rounded-full shrink-0`}
                        >
                          <CheckCircleIcon className="w-9 h-9 text-indigo-50 p-2" />
                        </div>
                        <div className="text-center">
                          {
                            usernames.filter((x) =>
                              addedUsernames.includes(x.uname)
                            ).length
                          }
                        </div>
                      </div>
                      <div className="flex items-center flex-col space-y-2 justify-center">
                        <div
                          className={`bg-rose-500 w-9 h-9 rounded-full shrink-0`}
                        >
                          <XCircleIcon className="w-9 h-9 text-indigo-50 p-2" />
                        </div>
                        <div className="text-center">
                          {
                            usernames.filter(
                              (x) => !addedUsernames.includes(x.uname)
                            ).length
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </footer>
            </div>
          </>
        ) : (
          <>
            <form
              className="w-full border-b border-slate-200 dark:border-slate-700"
              onSubmit={(e) => {
                e.preventDefault();
                if (!usernames.find((x) => x.uname === form2.uname)) {
                  const arr = [{ uname: form2.uname, stat: null }];
                  setUsernames((prev) => {
                    return [...prev, ...arr];
                  });
                }
                setForm2({ ...form2, uname: "" });
              }}
              autoComplete="off"
            >
              {/* Panel body */}
              <div className="p-6 space-y-6">
                <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
                  {ConfigProvider.getTranslation("Cards")}
                </h2>
                <section>
                  <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
                    <div className="relative col-span-2">
                      <label className="block text-sm font-medium mb-1">
                        {ConfigProvider.getTranslation("Upload")} (txt,xls,xlsx)
                      </label>
                      <FileUpload setUsernames={setUsernames} />
                    </div>
                  </div>
                </section>

                <section>
                  {/* Table */}
                  <div className="overflow-x-auto">
                    <table className="table-auto w-full dark:text-slate-300 divide-y divide-slate-200 dark:divide-slate-700">
                      {/* Table header */}
                      <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
                        <tr>
                          <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap">
                            <div className="grow font-semibold text-left">
                              {ConfigProvider.getTranslation("Username")}
                            </div>
                          </th>
                          <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                            <div className="grow font-semibold text-center">
                              {ConfigProvider.getTranslation("Status")}
                            </div>
                          </th>
                          <th className="px-2 first:pl-3 last:pr-2 2xl:first:pl-5 2xl:last:pr-5 py-3 whitespace-nowrap w-px">
                            &nbsp;
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
                        {
                          <tr>
                            <td
                              className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap"
                              colSpan={2}
                            >
                              <input
                                id="username"
                                name="username"
                                type="text"
                                value={form2.uname}
                                onChange={(e) => {
                                  setForm2({ ...form2, uname: e.target.value });
                                }}
                                className="w-full form-input"
                                placeholder={ConfigProvider.getTranslation(
                                  "Username"
                                )}
                                required
                                disabled={loading}
                              />
                            </td>
                            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                              <button className="btn bg-emerald-500 hover:bg-emerald-600 text-white">
                                <PlusIcon className="w-4 fill-current" />
                              </button>
                            </td>
                          </tr>
                        }
                        {usernames.length === 0 && (
                          <tr>
                            <td colSpan="3">
                              <NoData />
                            </td>
                          </tr>
                        )}
                        {usernames.map((item, i) => (
                          <tr key={i}>
                            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                              <div className="font-medium text-slate-800 dark:text-slate-100 text-left">
                                {item.uname}
                              </div>
                            </td>
                            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                              <div className="grow flex items-center justify-center">
                                {item.stat !== null && (
                                  <div
                                    className={`${
                                      item.stat
                                        ? "bg-emerald-500"
                                        : "bg-rose-500"
                                    } w-9 h-9 rounded-full shrink-0 my-2 mr-3`}
                                  >
                                    {item.stat ? (
                                      <CheckCircleIcon className="w-9 h-9 text-indigo-50 p-2" />
                                    ) : (
                                      <XCircleIcon className="w-9 h-9 text-indigo-50 p-2" />
                                    )}
                                  </div>
                                )}
                              </div>
                            </td>
                            <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                              <div className="grow flex items-center justify-center">
                                <Link
                                  onClick={() => {
                                    setUsernames((prev) => {
                                      return prev.filter((x) => x !== item);
                                    });
                                  }}
                                  className="text-rose-500"
                                >
                                  <XMarkIcon className="w-4 fill-current" />
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </section>
              </div>
              {/* Panel footer */}
              {usernames.length > 0 && (
                <footer>
                  <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
                    <div className="flex justify-between">
                      <button
                        type="button"
                        disabled={loading}
                        onClick={() => {
                          setUsernames([]);
                        }}
                        className="btn bg-amber-500 hover:bg-amber-600 text-white ml-3 flex items-center h-10"
                      >
                        <span className="mr-2 uppercase">
                          {ConfigProvider.getTranslation("Reset")}
                        </span>
                      </button>
                      <div className="flex space-x-4">
                        <div className="flex items-center flex-col space-y-2 justify-center">
                          <div
                            className={`bg-emerald-500 w-9 h-9 rounded-full shrink-0`}
                          >
                            <CheckCircleIcon className="w-9 h-9 text-indigo-50 p-2" />
                          </div>
                          <div className="text-center">
                            {usernames.filter((x) => x.stat).length}
                          </div>
                        </div>
                        <div className="flex items-center flex-col space-y-2 justify-center">
                          <div
                            className={`bg-rose-500 w-9 h-9 rounded-full shrink-0`}
                          >
                            <XCircleIcon className="w-9 h-9 text-indigo-50 p-2" />
                          </div>
                          <div className="text-center">
                            {usernames.filter((x) => x.stat === false).length}
                          </div>
                        </div>
                      </div>
                      <button
                        type="button"
                        disabled={loading}
                        onClick={() => {
                          checkUsers();
                        }}
                        className="btn bg-emerald-500 hover:bg-emerald-600 text-white ml-3 flex items-center h-10"
                      >
                        <span className="mr-2 uppercase">
                          {ConfigProvider.getTranslation("Check")}
                        </span>
                      </button>
                    </div>
                  </div>
                </footer>
              )}
            </form>
            <form
              className="w-full border-b border-slate-200 dark:border-slate-700"
              onSubmit={(e) => {
                e.preventDefault();
                setShowConfirm(true);
              }}
              autoComplete="off"
            >
              {/* Panel body */}
              <div className="p-6 space-y-6">
                <div className="w-full mt-2 mb-4">
                  <Alert messages={messages} setMessages={setMessages} />
                </div>
                <h2 className="text-2xl text-slate-800 dark:text-slate-100 font-bold mb-5 flex justify-between">
                  {ConfigProvider.getTranslation("AddFreespin")}
                </h2>
                <section>
                  <div className="sm:grid sm:grid-cols-2 sm:items-center space-y-4 sm:space-y-0 gap-4 mt-5">
                    <div className="relative">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="freespin"
                      >
                        {ConfigProvider.getTranslation("Freespin")}
                      </label>

                      {freespinsSelect &&
                        Object.keys(freespinsSelect).length > 0 && (
                          <Dropdown
                            name="Freespin"
                            items={freespinsSelect}
                            selected={formData.template}
                            setSelected={(val) => {
                              setFormData({ ...formData, template: val });
                            }}
                          />
                        )}
                    </div>
                    <div className="relative">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="count"
                      >
                        {ConfigProvider.getTranslation("Count")}
                      </label>
                      <input
                        id="count"
                        name="count"
                        type="number"
                        value={formData.count}
                        onChange={handleChange}
                        className="w-full form-input"
                        placeholder={ConfigProvider.getTranslation("Count")}
                        required
                        maxLength={10}
                        disabled={loading}
                        step={1}
                        pattern="\d*"
                      />
                    </div>
                    <div className="relative">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="betlevel"
                      >
                        {ConfigProvider.getTranslation("BetLevel")}
                      </label>
                      <input
                        id="betlevel"
                        name="betlevel"
                        type="number"
                        value={formData.betlevel}
                        onChange={handleChange}
                        className="w-full form-input"
                        placeholder={ConfigProvider.getTranslation("BetLevel")}
                        required
                        min={1}
                        step={1}
                        pattern="\d*"
                        disabled={loading}
                        maxLength={10}
                      />
                    </div>
                    <div className="relative">
                      <label
                        className="block text-sm font-medium mb-1"
                        htmlFor="expireDays"
                      >
                        {ConfigProvider.getTranslation("ExpireDays")}
                      </label>
                      <input
                        id="expireDays"
                        name="expireDays"
                        type="number"
                        value={formData.expireDays}
                        onChange={handleChange}
                        className="w-full form-input"
                        placeholder={ConfigProvider.getTranslation(
                          "ExpireDays"
                        )}
                        maxLength={10}
                        disabled={loading}
                        step={1}
                        pattern="\d*"
                        required
                        min={1}
                        max={100}
                      />
                    </div>
                  </div>
                </section>
              </div>
              {/* Panel footer */}
              <footer>
                <div className="flex flex-col px-6 py-5 border-t border-slate-200 dark:border-slate-700">
                  <div className="flex justify-between">
                    <button
                      type="submit"
                      disabled={loading}
                      className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3 flex items-center"
                    >
                      <span className="mr-2 uppercase">
                        {ConfigProvider.getTranslation("Button.Send")}
                      </span>
                      {loading ? (
                        <span>
                          <LoadingSpinner className="w-4 h-4 mb-1" />
                        </span>
                      ) : (
                        <span>
                          <ArrowRightCircleIcon className="w-4 h-4" />
                        </span>
                      )}
                    </button>
                  </div>
                </div>
              </footer>
            </form>
          </>
        )}
      </div>
      {showConfirm && (
        <Confirm
          title={ConfigProvider.getTranslation("AreYouSure")}
          text={
            <>
              <p>{`${ConfigProvider.getTranslation("Freespin")}: ${
                freespinsSelect.hasOwnProperty(formData.template)
                  ? freespinsSelect[formData.template]
                  : "-"
              }`}</p>
              <p>{`${ConfigProvider.getTranslation("Count")}: ${
                formData.count
              }`}</p>
              <p>{`${ConfigProvider.getTranslation("BetLevel")}: ${
                formData.betlevel
              }`}</p>
              <p>{`${ConfigProvider.getTranslation("ExpireDays")}: ${
                formData.expireDays
              }`}</p>
            </>
          }
          confirm={() => {
            submitForm();
          }}
          callback={() => {
            setShowConfirm(false);
          }}
        />
      )}
    </div>
  );
}
function WelcomeBanner() {
  const ConfigProvider = useConfig();
  return (
    <div className="relative bg-indigo-200 dark:bg-indigo-500 p-4 sm:p-6 rounded-sm overflow-hidden mb-8">
      {/* Background illustration */}
      <div
        className="absolute right-0 top-0 -mt-4 mr-16 pointer-events-none hidden xl:block"
        aria-hidden="true"
      >
        <svg width="319" height="198" xmlnsXlink="http://www.w3.org/1999/xlink">
          <defs>
            <path id="welcome-a" d="M64 0l64 128-64-20-64 20z" />
            <path id="welcome-e" d="M40 0l40 80-40-12.5L0 80z" />
            <path id="welcome-g" d="M40 0l40 80-40-12.5L0 80z" />
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="welcome-b">
              <stop stopColor="#A5B4FC" offset="0%" />
              <stop stopColor="#818CF8" offset="100%" />
            </linearGradient>
            <linearGradient
              x1="50%"
              y1="24.537%"
              x2="50%"
              y2="100%"
              id="welcome-c"
            >
              <stop stopColor="#4338CA" offset="0%" />
              <stop stopColor="#6366F1" stopOpacity="0" offset="100%" />
            </linearGradient>
          </defs>
          <g fill="none" fillRule="evenodd">
            <g transform="rotate(64 36.592 105.604)">
              <mask id="welcome-d" fill="#fff">
                <use xlinkHref="#welcome-a" />
              </mask>
              <use fill="url(#welcome-b)" xlinkHref="#welcome-a" />
              <path
                fill="url(#welcome-c)"
                mask="url(#welcome-d)"
                d="M64-24h80v152H64z"
              />
            </g>
            <g transform="rotate(-51 91.324 -105.372)">
              <mask id="welcome-f" fill="#fff">
                <use xlinkHref="#welcome-e" />
              </mask>
              <use fill="url(#welcome-b)" xlinkHref="#welcome-e" />
              <path
                fill="url(#welcome-c)"
                mask="url(#welcome-f)"
                d="M40.333-15.147h50v95h-50z"
              />
            </g>
            <g transform="rotate(44 61.546 392.623)">
              <mask id="welcome-h" fill="#fff">
                <use xlinkHref="#welcome-g" />
              </mask>
              <use fill="url(#welcome-b)" xlinkHref="#welcome-g" />
              <path
                fill="url(#welcome-c)"
                mask="url(#welcome-h)"
                d="M40.333-15.147h50v95h-50z"
              />
            </g>
          </g>
        </svg>
      </div>

      {/* Content */}
      <div className="relative">
        <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold mb-1">
          {ConfigProvider.getTranslation("BulkFreespin")}💫
        </h1>
      </div>
    </div>
  );
}
const FileUpload = ({ setUsernames }) => {
  const ConfigProvider = useConfig();
  const ToastrContext = useToastr();

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const fileType = file.name.split(".").pop();

    if (fileType === "txt") {
      readTxtFile(file);
    } else if (fileType === "xlsx" || fileType === "xls") {
      readExcelFile(file);
    } else {
      ToastrContext.showToast({
        type: "error",
        text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
      });
    }
    event.target.value = null;
  };

  const readTxtFile = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      const lines = text
        .split("\n")
        .map((line) => line.trim())
        .filter((x) => x && x.length > 0);
      const uniqueArr = [...new Set(lines)];
      setUsernames((prev) => {
        return [
          ...prev,
          ...uniqueArr
            .filter((x) => !prev.find((a) => a.uname === x.trim()))
            .map((line) => {
              return { uname: line.trim(), stat: null };
            }),
        ];
      });
    };
    reader.readAsText(file);
  };

  const readExcelFile = (file) => {
    readXlsxFile(file).then((rows) => {
      const allData = rows
        .map((x) => x[0] && x[0].length > 0 && x[0].trim())
        .filter((x) => x && x.length > 0);
      const uniqueArr = [...new Set(allData)];
      setUsernames((prev) => {
        const newUsernames = uniqueArr
          .filter((x) => !prev.find((a) => a.uname === x))
          .map((x) => {
            return { uname: x, stat: null }; //
          });

        return [...prev, ...newUsernames];
      });
    });
  };

  return (
    <input
      type="file"
      onChange={handleFileUpload}
      accept=".txt,.xlsx,.xls"
      className="w-full form-input"
    />
  );
};

export default BulkFreespin;
