import React, { useState, useEffect, useRef } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

import SidebarLinkGroup from "./SidebarLinkGroup";
import { useConfig } from "../../context/ConfigContext";
import { checkRole } from "../../helpers/Helpers";
import { SparklesIcon, GiftIcon } from "@heroicons/react/24/solid";

function Sidebar({ sidebarOpen, setSidebarOpen }) {
  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
  );

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector("body").classList.add("sidebar-expanded");
    } else {
      document.querySelector("body").classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  const ConfigProvider = useConfig();
  return ConfigProvider.config.user !== null ? (
    <div className="min-w-fit">
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 h-[100dvh] overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64 2xl:!w-64 shrink-0 bg-slate-800 p-4 transition-all duration-200 ease-in-out ${
          sidebarOpen ? "translate-x-0" : "-translate-x-64"
        }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-start space-x-2 mb-10 pr-3 sm:px-2">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-slate-500 hover:text-slate-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">
              {ConfigProvider.getTranslation("Close")}
            </span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}
          <NavLink end to="/" className="flex items-center space-x-4">
            <svg width="32" height="32" viewBox="0 0 32 32">
              <defs>
                <linearGradient
                  x1="28.538%"
                  y1="20.229%"
                  x2="100%"
                  y2="108.156%"
                  id="logo-a"
                >
                  <stop stopColor="#A5B4FC" stopOpacity="0" offset="0%" />
                  <stop stopColor="#A5B4FC" offset="100%" />
                </linearGradient>
                <linearGradient
                  x1="88.638%"
                  y1="29.267%"
                  x2="22.42%"
                  y2="100%"
                  id="logo-b"
                >
                  <stop stopColor="#38BDF8" stopOpacity="0" offset="0%" />
                  <stop stopColor="#38BDF8" offset="100%" />
                </linearGradient>
              </defs>
              <rect fill="#6366F1" width="32" height="32" rx="16" />
              <path
                d="M18.277.16C26.035 1.267 32 7.938 32 16c0 8.837-7.163 16-16 16a15.937 15.937 0 01-10.426-3.863L18.277.161z"
                fill="#4F46E5"
              />
              <path
                d="M7.404 2.503l18.339 26.19A15.93 15.93 0 0116 32C7.163 32 0 24.837 0 16 0 10.327 2.952 5.344 7.404 2.503z"
                fill="url(#logo-a)"
              />
              <path
                d="M2.223 24.14L29.777 7.86A15.926 15.926 0 0132 16c0 8.837-7.163 16-16 16-5.864 0-10.991-3.154-13.777-7.86z"
                fill="url(#logo-b)"
              />
            </svg>
            <span className="text-white">Backoffice</span>
          </NavLink>
        </div>

        {/* Links */}
        <div className="space-y-8">
          {/* Pages group */}
          <div>
            <h3 className="text-xs uppercase text-slate-500 font-semibold pl-3">
              <span
                className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6"
                aria-hidden="true"
              >
                •••
              </span>
              <span className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                {ConfigProvider.getTranslation("General")}
              </span>
            </h3>
            <ul className="mt-3">
              {/* Dashboard */}
              <li
                className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                  (pathname === "/" || pathname.startsWith("/dashboard")) &&
                  "bg-slate-900"
                }`}
              >
                <NavLink
                  end
                  to="/"
                  className={`block text-slate-200 truncate transition duration-150 ${
                    pathname === "/" || pathname.startsWith("/dashboard")
                      ? "hover:text-slate-200"
                      : "hover:text-white"
                  }`}
                >
                  <div className="flex items-center">
                    <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                      <path
                        className={`fill-current ${
                          pathname === "/" || pathname.startsWith("dashboard")
                            ? "text-indigo-500"
                            : "text-slate-400"
                        }`}
                        d="M12 0C5.383 0 0 5.383 0 12s5.383 12 12 12 12-5.383 12-12S18.617 0 12 0z"
                      />
                      <path
                        className={`fill-current ${
                          pathname === "/" || pathname.startsWith("/dashboard")
                            ? "text-indigo-600"
                            : "text-slate-600"
                        }`}
                        d="M12 3c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9z"
                      />
                      <path
                        className={`fill-current ${
                          pathname === "/" || pathname.startsWith("/dashboard")
                            ? "text-indigo-200"
                            : "text-slate-400"
                        }`}
                        d="M12 15c-1.654 0-3-1.346-3-3 0-.462.113-.894.3-1.285L6 6l4.714 3.301A2.973 2.973 0 0112 9c1.654 0 3 1.346 3 3s-1.346 3-3 3z"
                      />
                    </svg>
                    <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                      {ConfigProvider.getTranslation("Dashboard")}
                    </span>
                  </div>
                </NavLink>
              </li>

              {/* Cards */}
              {["CardsList", "CardsAdd"].find((x) =>
                checkRole(ConfigProvider.config.user, x)
              ) && (
                <SidebarLinkGroup
                  activecondition={pathname.startsWith("/cards")}
                >
                  {(handleClick, open) => {
                    return (
                      <React.Fragment>
                        <Link
                          className={`block cursor-pointer text-slate-200 truncate transition duration-150 ${
                            pathname.startsWith("/cards")
                              ? "hover:text-slate-200"
                              : "hover:text-white"
                          }`}
                          onClick={(e) => {
                            e.preventDefault();
                            handleClick();
                            setSidebarExpanded(true);
                          }}
                        >
                          <div className="flex items-center justify-between">
                            <div className="flex items-center">
                              <svg
                                className="shrink-0 h-6 w-6"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  className={`fill-current ${
                                    pathname.startsWith("/cards")
                                      ? "text-indigo-300"
                                      : "text-slate-400"
                                  }`}
                                  d="M13 15l11-7L11.504.136a1 1 0 00-1.019.007L0 7l13 8z"
                                />
                                <path
                                  className={`fill-current ${
                                    pathname.startsWith("/cards")
                                      ? "text-indigo-600"
                                      : "text-slate-700"
                                  }`}
                                  d="M13 15L0 7v9c0 .355.189.685.496.864L13 24v-9z"
                                />
                                <path
                                  className={`fill-current ${
                                    pathname.startsWith("/cards")
                                      ? "text-indigo-500"
                                      : "text-slate-600"
                                  }`}
                                  d="M13 15.047V24l10.573-7.181A.999.999 0 0024 16V8l-11 7.047z"
                                />
                              </svg>
                              <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                {ConfigProvider.getTranslation("Cards")}
                              </span>
                            </div>
                            {/* Icon */}
                            <div className="flex shrink-0 ml-2">
                              <svg
                                className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                  open && "rotate-180"
                                }`}
                                viewBox="0 0 12 12"
                              >
                                <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                              </svg>
                            </div>
                          </div>
                        </Link>
                        <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                          <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                            {[
                              {
                                link: "/cards/list",
                                name: ConfigProvider.getTranslation(
                                  "Filter.All"
                                ),
                                role: "CardsList",
                              },
                              {
                                link: "/cards/add",
                                name: ConfigProvider.getTranslation("Add"),
                                role: "CardsAdd",
                              },
                              {
                                link: "/cards/categories/list",
                                name: ConfigProvider.getTranslation(
                                  "ListCategories"
                                ),
                                role: "CategoriesList",
                              },
                              {
                                link: "/cards/categories/add",
                                name: ConfigProvider.getTranslation(
                                  "AddCategory"
                                ),
                                role: "CategoryAdd",
                              },
                              {
                                link: "/cards/bulkMessage",
                                name: ConfigProvider.getTranslation(
                                  "BulkMessage"
                                ),
                                role: "CardsBulkMessage",
                              },
                            ]
                              .filter((x) =>
                                checkRole(ConfigProvider.config.user, x.role)
                              )
                              .map((item) => (
                                <li key={item.link} className="mb-1 last:mb-0">
                                  <NavLink
                                    end
                                    to={item.link}
                                    className={({ isActive }) =>
                                      "block transition duration-150 truncate " +
                                      (isActive
                                        ? "text-indigo-500"
                                        : "text-slate-400 hover:text-slate-200")
                                    }
                                  >
                                    <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      {item.name}
                                    </span>
                                  </NavLink>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </React.Fragment>
                    );
                  }}
                </SidebarLinkGroup>
              )}
              {/* Accounts */}
              {["AccountsList", "AccountsAdd"].find((x) =>
                checkRole(ConfigProvider.config.user, x)
              ) && (
                <SidebarLinkGroup
                  activecondition={pathname.startsWith("/accounts")}
                >
                  {(handleClick, open) => {
                    return (
                      <React.Fragment>
                        <Link
                          className={`block text-slate-200 truncate transition duration-150 ${
                            pathname.startsWith("/accounts")
                              ? "hover:text-slate-200"
                              : "hover:text-white"
                          }`}
                          onClick={(e) => {
                            e.preventDefault();
                            handleClick();
                            setSidebarExpanded(true);
                          }}
                        >
                          <div className="flex items-center justify-between">
                            <div className="flex items-center">
                              <svg
                                className="shrink-0 h-6 w-6"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  className={`fill-current ${
                                    pathname.startsWith("/accounts")
                                      ? "text-indigo-500"
                                      : "text-slate-600"
                                  }`}
                                  d="M18.974 8H22a2 2 0 012 2v6h-2v5a1 1 0 01-1 1h-2a1 1 0 01-1-1v-5h-2v-6a2 2 0 012-2h.974zM20 7a2 2 0 11-.001-3.999A2 2 0 0120 7zM2.974 8H6a2 2 0 012 2v6H6v5a1 1 0 01-1 1H3a1 1 0 01-1-1v-5H0v-6a2 2 0 012-2h.974zM4 7a2 2 0 11-.001-3.999A2 2 0 014 7z"
                                />
                                <path
                                  className={`fill-current ${
                                    pathname.startsWith("/accounts")
                                      ? "text-indigo-300"
                                      : "text-slate-400"
                                  }`}
                                  d="M12 6a3 3 0 110-6 3 3 0 010 6zm2 18h-4a1 1 0 01-1-1v-6H6v-6a3 3 0 013-3h6a3 3 0 013 3v6h-3v6a1 1 0 01-1 1z"
                                />
                              </svg>
                              <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                {ConfigProvider.getTranslation("Accounts")}
                              </span>
                            </div>
                            {/* Icon */}
                            <div className="flex shrink-0 ml-2">
                              <svg
                                className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                  open && "rotate-180"
                                }`}
                                viewBox="0 0 12 12"
                              >
                                <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                              </svg>
                            </div>
                          </div>
                        </Link>
                        <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                          <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                            {[
                              {
                                link: "/accounts/list",
                                name: ConfigProvider.getTranslation(
                                  "Filter.All"
                                ),
                                role: "AccountsList",
                              },
                              {
                                link: "/accounts/add",
                                name: ConfigProvider.getTranslation("Add"),
                                role: "AccountsAdd",
                              },
                            ]
                              .filter((x) =>
                                checkRole(ConfigProvider.config.user, x.role)
                              )
                              .map((item) => (
                                <li key={item.link} className="mb-1 last:mb-0">
                                  <NavLink
                                    end
                                    to={item.link}
                                    className={({ isActive }) =>
                                      "block transition duration-150 truncate " +
                                      (isActive
                                        ? "text-indigo-500"
                                        : "text-slate-400 hover:text-slate-200")
                                    }
                                  >
                                    <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      {item.name}
                                    </span>
                                  </NavLink>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </React.Fragment>
                    );
                  }}
                </SidebarLinkGroup>
              )}

              {/* Tickets */}
              {checkRole(ConfigProvider.config.user, "SportBetList") && (
                <li
                  className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                    pathname === "/tickets" && "bg-slate-900"
                  }`}
                >
                  <NavLink
                    end
                    to="/tickets"
                    className={`block text-slate-200 truncate transition duration-150 ${
                      pathname === "/tickets"
                        ? "hover:text-slate-200"
                        : "hover:text-white"
                    }`}
                  >
                    <div className="flex items-center">
                      <svg className="shrink-0 w-6 h-6" viewBox="0 0 24 24">
                        <defs></defs>
                        <path
                          className={`fill-current ${
                            pathname.startsWith("/tickets")
                              ? "text-indigo-300"
                              : "text-slate-400"
                          }`}
                          d="M 2.25 12 C 2.25 10.343 3.593 9 5.25 9 L 18.75 9 C 20.407 9 21.75 10.343 21.75 12 L 21.75 18 C 21.75 19.657 20.407 21 18.75 21 L 5.25 21 C 3.593 21 2.25 19.657 2.25 18 L 2.25 12 Z"
                        ></path>
                        <path
                          className={`fill-current ${
                            pathname.startsWith("/tickets")
                              ? "text-indigo-400"
                              : "text-slate-500"
                          }`}
                          d="M 5.046 7.5 C 4.636 7.5 4.24 7.555 3.862 7.657 C 4.37 6.641 5.409 5.999 6.546 6 L 17.046 6 C 18.182 6 19.22 6.641 19.729 7.657 C 19.343 7.552 18.945 7.5 18.546 7.5 L 5.046 7.5 Z"
                        ></path>
                        <path
                          className={`fill-current ${
                            pathname.startsWith("/tickets")
                              ? "text-indigo-500"
                              : "text-slate-600"
                          }`}
                          d="M 5.374 4.657 C 5.76 4.552 6.158 4.5 6.558 4.5 L 17.058 4.5 C 17.468 4.5 17.864 4.555 18.241 4.657 C 17.732 3.641 16.694 3 15.558 3 L 8.058 3 C 6.922 2.999 5.883 3.641 5.374 4.657 Z"
                        ></path>
                      </svg>

                      <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        {ConfigProvider.getTranslation("Tickets")}
                      </span>
                    </div>
                  </NavLink>
                </li>
              )}
              {/* Casino */}
              {/* Logs */}
              {checkRole(ConfigProvider.config.user, "CasinoListBonus") && (
                <li
                  className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                    pathname === "/helperCasino/bonus/list" && "bg-slate-900"
                  }`}
                >
                  <NavLink
                    end
                    to="/helperCasino/bonus/list"
                    className={`block text-slate-200 truncate transition duration-150 ${
                      pathname === "/helperCasino/bonus/list"
                        ? "hover:text-slate-200"
                        : "hover:text-white"
                    }`}
                  >
                    <div className="flex items-center">
                      <span>
                        <GiftIcon
                          className={`shrink-0 h-6 w-6 ${
                            pathname.startsWith("/helperCasino/bonus")
                              ? "text-indigo-400"
                              : "text-slate-400"
                          }`}
                        />
                      </span>
                      <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        {ConfigProvider.getTranslation("Bonus")}
                      </span>
                    </div>
                  </NavLink>
                </li>
              )}
              {[
                "CasinoListFreespins",
                "CasinoManageCasinoFreespinTemplates",
              ].find((x) => checkRole(ConfigProvider.config.user, x)) && (
                <SidebarLinkGroup
                  activecondition={pathname.startsWith(
                    "/helperCasino/freespin"
                  )}
                >
                  {(handleClick, open) => {
                    return (
                      <React.Fragment>
                        <Link
                          className={`block text-slate-200 truncate transition duration-150 ${
                            pathname.startsWith("/helperCasino/freespin")
                              ? "hover:text-slate-200"
                              : "hover:text-white"
                          }`}
                          onClick={(e) => {
                            e.preventDefault();
                            handleClick();
                            setSidebarExpanded(true);
                          }}
                        >
                          <div className="flex items-center justify-between">
                            <div className="flex items-center">
                              <span>
                                <SparklesIcon
                                  className={`shrink-0 h-6 w-6 ${
                                    pathname.startsWith(
                                      "/helperCasino/freespin"
                                    )
                                      ? "text-indigo-400"
                                      : "text-slate-400"
                                  }`}
                                />
                              </span>
                              <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                {ConfigProvider.getTranslation("Freespin")}
                              </span>
                            </div>
                            {/* Icon */}
                            <div className="flex shrink-0 ml-2">
                              <svg
                                className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                  open && "rotate-180"
                                }`}
                                viewBox="0 0 12 12"
                              >
                                <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                              </svg>
                            </div>
                          </div>
                        </Link>
                        <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                          <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                            {[
                              {
                                link: "/helperCasino/freespins/list",
                                name: ConfigProvider.getTranslation(
                                  "Freespins"
                                ),
                                role: "CasinoListFreespins",
                              },
                              {
                                link: "/helperCasino/freespinTemplates/list",
                                name: ConfigProvider.getTranslation(
                                  "FreespinTemplates"
                                ),
                                role: "CasinoManageCasinoFreespinTemplates",
                              },
                              {
                                link: "/helperCasino/freespinTemplates/bulk",
                                name: ConfigProvider.getTranslation(
                                  "BulkFreespin"
                                ),
                                role: "CasinoBulkFreespin",
                              },
                            ]
                              .filter((x) =>
                                checkRole(ConfigProvider.config.user, x.role)
                              )
                              .map((item) => (
                                <li key={item.link} className="mb-1 last:mb-0">
                                  <NavLink
                                    end
                                    to={item.link}
                                    className={({ isActive }) =>
                                      "block transition duration-150 truncate " +
                                      (isActive
                                        ? "text-indigo-500"
                                        : "text-slate-400 hover:text-slate-200")
                                    }
                                  >
                                    <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      {item.name}
                                    </span>
                                  </NavLink>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </React.Fragment>
                    );
                  }}
                </SidebarLinkGroup>
              )}
              {/* Finance */}
              {[
                "FinanceAccountingList",
                "FinanceBalanceTransactionsList",
                "FinanceCreditTransactionsList",
                "FinanceBillingTransactionsList",
                "FinanceBonusesList",
              ].find((x) => checkRole(ConfigProvider.config.user, x)) && (
                <SidebarLinkGroup
                  activecondition={pathname.startsWith("/finance")}
                >
                  {(handleClick, open) => {
                    return (
                      <React.Fragment>
                        <Link
                          className={`block text-slate-200 truncate transition duration-150 ${
                            pathname.startsWith("/finance")
                              ? "hover:text-slate-200"
                              : "hover:text-white"
                          }`}
                          onClick={(e) => {
                            e.preventDefault();
                            handleClick();
                            setSidebarExpanded(true);
                          }}
                        >
                          <div className="flex items-center justify-between">
                            <div className="flex items-center">
                              <svg
                                className="shrink-0 h-6 w-6"
                                viewBox="0 0 24 24"
                              >
                                <path
                                  className={`fill-current ${
                                    pathname.startsWith("/finance")
                                      ? "text-indigo-300"
                                      : "text-slate-400"
                                  }`}
                                  d="M13 6.068a6.035 6.035 0 0 1 4.932 4.933H24c-.486-5.846-5.154-10.515-11-11v6.067Z"
                                />
                                <path
                                  className={`fill-current ${
                                    pathname.startsWith("/finance")
                                      ? "text-indigo-500"
                                      : "text-slate-700"
                                  }`}
                                  d="M18.007 13c-.474 2.833-2.919 5-5.864 5a5.888 5.888 0 0 1-3.694-1.304L4 20.731C6.131 22.752 8.992 24 12.143 24c6.232 0 11.35-4.851 11.857-11h-5.993Z"
                                />
                                <path
                                  className={`fill-current ${
                                    pathname.startsWith("/finance")
                                      ? "text-indigo-600"
                                      : "text-slate-600"
                                  }`}
                                  d="M6.939 15.007A5.861 5.861 0 0 1 6 11.829c0-2.937 2.167-5.376 5-5.85V0C4.85.507 0 5.614 0 11.83c0 2.695.922 5.174 2.456 7.17l4.483-3.993Z"
                                />
                              </svg>
                              <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                {ConfigProvider.getTranslation("Finance")}
                              </span>
                            </div>
                            {/* Icon */}
                            <div className="flex shrink-0 ml-2">
                              <svg
                                className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                  open && "rotate-180"
                                }`}
                                viewBox="0 0 12 12"
                              >
                                <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                              </svg>
                            </div>
                          </div>
                        </Link>
                        <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                          <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                            {[
                              {
                                link: "/finance/accounting",
                                name: ConfigProvider.getTranslation(
                                  "Accounting"
                                ),
                                role: "FinanceAccountingList",
                              },

                              {
                                link: "/finance/balanceTransactions",
                                name: ConfigProvider.getTranslation(
                                  "BalanceTransactions"
                                ),
                                role: "FinanceBalanceTransactionsList",
                              },
                              {
                                link: "/finance/creditTransactions",
                                name: ConfigProvider.getTranslation(
                                  "CreditTransactions"
                                ),
                                role: "FinanceCreditTransactionsList",
                              },
                              {
                                link: "/finance/billingTransactions",
                                name: ConfigProvider.getTranslation(
                                  "BillingTransactions"
                                ),
                                role: "FinanceBillingTransactionsList",
                              },
                            ]
                              .filter(
                                (x) =>
                                  checkRole(
                                    ConfigProvider.config.user,
                                    x.role
                                  ) &&
                                  (ConfigProvider.config.isShopSystem ||
                                    (!ConfigProvider.config.isShopSystem &&
                                      x.link !== "/finance/accounting" &&
                                      x.link !==
                                        "/finance/creditTransactions" &&
                                      x.link !==
                                        "/finance/billingTransactions"))
                              )
                              .map((item) => (
                                <li key={item.link} className="mb-1 last:mb-0">
                                  <NavLink
                                    end
                                    to={item.link}
                                    className={({ isActive }) =>
                                      "block transition duration-150 truncate " +
                                      (isActive
                                        ? "text-indigo-500"
                                        : "text-slate-400 hover:text-slate-200")
                                    }
                                  >
                                    <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      {item.name}
                                    </span>
                                  </NavLink>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </React.Fragment>
                    );
                  }}
                </SidebarLinkGroup>
              )}
              {/* Report */}
              {[
                "ReportSportBetsReportsList",
                "ReportSportBetsReportsListByTransactionDate",
                "ReportCasinoBetsReportsList",
                "ReportCasinoBetsShopReportsList",
                "ReportMatchReportsList",
                "ReportCardReportsList",
                "ReportCardCasinoReportsList",
              ].find((x) => checkRole(ConfigProvider.config.user, x)) && (
                <SidebarLinkGroup
                  activecondition={pathname.startsWith("/report")}
                >
                  {(handleClick, open) => {
                    return (
                      <React.Fragment>
                        <Link
                          className={`block text-slate-200 truncate transition duration-150 ${
                            pathname.startsWith("/report")
                              ? "hover:text-slate-200"
                              : "hover:text-white"
                          }`}
                          onClick={(e) => {
                            e.preventDefault();
                            handleClick();
                            setSidebarExpanded(true);
                          }}
                        >
                          <div className="flex items-center justify-between">
                            <div className="flex items-center">
                              <span>
                                <svg
                                  className="shrink-0 h-6 w-6"
                                  viewBox="0 0 24 24"
                                >
                                  <path
                                    className={`fill-current ${
                                      pathname.startsWith("/report")
                                        ? "text-indigo-500"
                                        : "text-slate-400"
                                    }`}
                                    d="M3.293 9.293C3 9.586 3 10.057 3 11v6c0 .943 0 1.414.293 1.707C3.586 19 4.057 19 5 19c.943 0 1.414 0 1.707-.293C7 18.414 7 17.943 7 17v-6c0-.943 0-1.414-.293-1.707C6.414 9 5.943 9 5 9c-.943 0-1.414 0-1.707.293z"
                                  ></path>
                                  <path
                                    className={`fill-current ${
                                      pathname.startsWith("/report")
                                        ? "text-indigo-500"
                                        : "text-slate-400"
                                    }`}
                                    d="M17.293 2.293C17 2.586 17 3.057 17 4v13c0 .943 0 1.414.293 1.707.293.293.764.293 1.707.293.943 0 1.414 0 1.707-.293C21 18.414 21 17.943 21 17V4c0-.943 0-1.414-.293-1.707C20.414 2 19.943 2 19 2c-.943 0-1.414 0-1.707.293z"
                                    opacity="0.4"
                                  ></path>
                                  <path
                                    className={`fill-current ${
                                      pathname.startsWith("/report")
                                        ? "text-indigo-500"
                                        : "text-slate-400"
                                    }`}
                                    d="M10 7c0-.943 0-1.414.293-1.707C10.586 5 11.057 5 12 5c.943 0 1.414 0 1.707.293C14 5.586 14 6.057 14 7v10c0 .943 0 1.414-.293 1.707C13.414 19 12.943 19 12 19c-.943 0-1.414 0-1.707-.293C10 18.414 10 17.943 10 17V7z"
                                    opacity="0.7"
                                  ></path>
                                  <path
                                    className={`fill-current ${
                                      pathname.startsWith("/report")
                                        ? "text-indigo-300"
                                        : "text-slate-400"
                                    }`}
                                    d="M3 21.25a.75.75 0 000 1.5h18a.75.75 0 000-1.5H3z"
                                  ></path>
                                </svg>
                              </span>
                              <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                {ConfigProvider.getTranslation("Reports")}
                              </span>
                            </div>
                            {/* Icon */}
                            <div className="flex shrink-0 ml-2">
                              <svg
                                className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                  open && "rotate-180"
                                }`}
                                viewBox="0 0 12 12"
                              >
                                <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                              </svg>
                            </div>
                          </div>
                        </Link>
                        <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                          <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                            {[
                              {
                                link: "/report/betReports",
                                name: `${ConfigProvider.getTranslation(
                                  "BetReportsByBetDate"
                                )}`,
                                role: "ReportSportBetsReportsList",
                              },
                              {
                                link: "/report/betReportsByTransactionDate",
                                name: `${ConfigProvider.getTranslation(
                                  "BetReportsByTransactionDate"
                                )}`,
                                role: "ReportSportBetsReportsListByTransactionDate",
                              },
                              {
                                link: "/report/generalAccountingReport",
                                name: ConfigProvider.getTranslation("Reports"),
                                role: "ReportCasinoBetsReportsList",
                              },
                              {
                                link: "/report/shopAccountingReport",
                                name:
                                  ConfigProvider.getTranslation("Reports") +
                                  " (" +
                                  ConfigProvider.getTranslation("Shop") +
                                  ")",
                                role: "ReportCasinoBetsShopReportsList",
                              },

                              {
                                link: "/report/reportEvent",
                                name: ConfigProvider.getTranslation(
                                  "MatchReports"
                                ),
                                role: "ReportMatchReportsList",
                              },
                              {
                                link: "/report/reportCard",
                                name: `${ConfigProvider.getTranslation(
                                  "CardReports"
                                )} (${ConfigProvider.getTranslation(
                                  "Sports"
                                )})`,
                                role: "ReportCardReportsList",
                              },
                              {
                                link: "/report/reportCasinoCard",
                                name: `${ConfigProvider.getTranslation(
                                  "CardReports"
                                )} (${ConfigProvider.getTranslation(
                                  "Casino"
                                )})`,
                                role: "ReportCardCasinoReportsList",
                              },
                              {
                                link: "/report/betPaymentReport",
                                name: ConfigProvider.getTranslation(
                                  "BetPaymentReport"
                                ),
                                role: "ReportSportBetPaymentReportList",
                              },
                              {
                                link: "/report/casinoGameReport",
                                name: ConfigProvider.getTranslation(
                                  "CasinoGameReport"
                                ),
                                role: "ReportCasinoGameReportsList",
                              },
                            ]
                              .filter(
                                (x) =>
                                  checkRole(
                                    ConfigProvider.config.user,
                                    x.role
                                  ) &&
                                  (ConfigProvider.config.isShopSystem ||
                                    (!ConfigProvider.config.isShopSystem &&
                                      x.link !==
                                        "/report/shopAccountingReport"))
                              )
                              .map((item) => (
                                <li key={item.link} className="mb-1 last:mb-0">
                                  <NavLink
                                    end
                                    to={item.link}
                                    className={({ isActive }) =>
                                      "block transition duration-150 truncate " +
                                      (isActive
                                        ? "text-indigo-500"
                                        : "text-slate-400 hover:text-slate-200")
                                    }
                                  >
                                    <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      {item.name}
                                    </span>
                                  </NavLink>
                                </li>
                              ))}
                          </ul>
                        </div>
                      </React.Fragment>
                    );
                  }}
                </SidebarLinkGroup>
              )}
              {/* Support */}
              {checkRole(ConfigProvider.config.user, "Support") &&
                ConfigProvider.config.user.auth !== "0" && (
                  <li
                    className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                      pathname.startsWith("/support") && "bg-slate-900"
                    }`}
                  >
                    <NavLink
                      end
                      to="/support/tickets"
                      className={`block text-slate-200 truncate transition duration-150 ${
                        pathname.startsWith("/support")
                          ? "hover:text-slate-200"
                          : "hover:text-white"
                      }`}
                    >
                      <div className="flex items-center">
                        <span>
                          <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                            <path
                              className={`fill-current ${
                                pathname.startsWith("/support")
                                  ? "text-indigo-500"
                                  : "text-slate-600"
                              }`}
                              d="M14.5 7c4.695 0 8.5 3.184 8.5 7.111 0 1.597-.638 3.067-1.7 4.253V23l-4.108-2.148a10 10 0 01-2.692.37c-4.695 0-8.5-3.184-8.5-7.11C6 10.183 9.805 7 14.5 7z"
                            />
                            <path
                              className={`fill-current ${
                                pathname.startsWith("/support")
                                  ? "text-indigo-300"
                                  : "text-slate-400"
                              }`}
                              d="M11 1C5.477 1 1 4.582 1 9c0 1.797.75 3.45 2 4.785V19l4.833-2.416C8.829 16.85 9.892 17 11 17c5.523 0 10-3.582 10-8s-4.477-8-10-8z"
                            />
                          </svg>
                        </span>
                        <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                          {ConfigProvider.getTranslation("SupportTickets")}
                        </span>
                      </div>
                    </NavLink>
                  </li>
                )}
              {/* Logs */}
              {checkRole(ConfigProvider.config.user, "FinanceLogsList") && (
                <li
                  className={`px-3 py-2 rounded-sm mb-0.5 last:mb-0 ${
                    pathname.startsWith("/logs") && "bg-slate-900"
                  }`}
                >
                  <NavLink
                    end
                    to="/logs"
                    className={`block text-slate-200 truncate transition duration-150 ${
                      pathname.startsWith("/logs")
                        ? "hover:text-slate-200"
                        : "hover:text-white"
                    }`}
                  >
                    <div className="flex items-center">
                      <svg className="shrink-0 h-6 w-6" viewBox="0 0 24 24">
                        <path
                          className={`fill-current ${
                            pathname.startsWith("/logs")
                              ? "text-indigo-500"
                              : "text-slate-600"
                          }`}
                          d="M8.07 16H10V8H8.07a8 8 0 110 8z"
                        />
                        <path
                          className={`fill-current ${
                            pathname.startsWith("/logs")
                              ? "text-indigo-300"
                              : "text-slate-400"
                          }`}
                          d="M15 12L8 6v5H0v2h8v5z"
                        />
                      </svg>
                      <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                        {ConfigProvider.getTranslation("Logs")}
                      </span>
                    </div>
                  </NavLink>
                </li>
              )}
            </ul>
          </div>
          {/* More group */}
          {["0"].includes(ConfigProvider.config.user.auth) && (
            <div>
              <h3 className="text-xs uppercase text-slate-500 font-semibold pl-3">
                <span
                  className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6"
                  aria-hidden="true"
                >
                  •••
                </span>
                <span className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                  {ConfigProvider.getTranslation("Administration")}
                </span>
              </h3>
              <ul className="mt-3">
                {/* settlement */}
                {[
                  "SettlementEventSettlement",
                  "SettlementBetSettlement",
                  "SettlementViewBetComplaints",
                ].find((x) => checkRole(ConfigProvider.config.user, x)) && (
                  <SidebarLinkGroup
                    activecondition={pathname.startsWith("/settlement")}
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <Link
                            className={`block text-slate-200 truncate transition duration-150 ${
                              pathname.startsWith("/settlement")
                                ? "hover:text-slate-200"
                                : "hover:text-white"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              handleClick();
                              setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <span>
                                  <svg
                                    className="shrink-0 h-6 w-6"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      className={`fill-current ${
                                        pathname.startsWith("/settlement")
                                          ? "text-indigo-500"
                                          : "text-slate-600"
                                      }`}
                                      d="M8 1v2H3v19h18V3h-5V1h7v23H1V1z"
                                    />
                                    <path
                                      className={`fill-current ${
                                        pathname.startsWith("/settlement")
                                          ? "text-indigo-500"
                                          : "text-slate-600"
                                      }`}
                                      d="M1 1h22v23H1z"
                                    />
                                    <path
                                      className={`fill-current ${
                                        pathname.startsWith("/settlement")
                                          ? "text-indigo-300"
                                          : "text-slate-400"
                                      }`}
                                      d="M15 10.586L16.414 12 11 17.414 7.586 14 9 12.586l2 2zM5 0h14v4H5z"
                                    />
                                  </svg>
                                </span>
                                <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  {ConfigProvider.getTranslation("Settlement")}
                                </span>
                              </div>
                              {/* Icon */}
                              <div className="flex shrink-0 ml-2">
                                <svg
                                  className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                    open && "rotate-180"
                                  }`}
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                              </div>
                            </div>
                          </Link>
                          <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              {[
                                {
                                  link: "/settlement/events",
                                  name: ConfigProvider.getTranslation(
                                    "EventSettlement"
                                  ),
                                  role: "SettlementEventSettlement",
                                },
                                {
                                  link: "/settlement/bet",
                                  name: ConfigProvider.getTranslation(
                                    "BetSettlement"
                                  ),
                                  role: "SettlementBetSettlement",
                                },
                                {
                                  link: "/settlement/complaints",
                                  name: ConfigProvider.getTranslation(
                                    "TicketComplaints"
                                  ),
                                  role: "SettlementViewBetComplaints",
                                },
                              ]
                                .filter((x) =>
                                  checkRole(ConfigProvider.config.user, x.role)
                                )
                                .map((item) => (
                                  <li
                                    key={item.link}
                                    className="mb-1 last:mb-0"
                                  >
                                    <NavLink
                                      end
                                      to={item.link}
                                      className={({ isActive }) =>
                                        "block transition duration-150 truncate " +
                                        (isActive
                                          ? "text-indigo-500"
                                          : "text-slate-400 hover:text-slate-200")
                                      }
                                    >
                                      <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                        {item.name}
                                      </span>
                                    </NavLink>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>
                )}

                {/* Sports */}
                {[
                  "SportsManageSportEvents",
                  "SportsManageSportLeagues",
                  "SportsManageSportMarkets",
                  "SportsManageSports",
                  "SportsManageAccumulatorBonuses",
                  "SportsManageInPlayDelay",
                  "SportsManageFactorSetting",
                  "SportsManageLimitClasses",
                  "SportsManageShopLimits",
                ].find((x) => checkRole(ConfigProvider.config.user, x)) && (
                  <SidebarLinkGroup
                    activecondition={pathname.startsWith("/sports")}
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <Link
                            className={`block text-slate-200 truncate transition duration-150 ${
                              pathname.startsWith("/sports")
                                ? "hover:text-slate-200"
                                : "hover:text-white"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              handleClick();
                              setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <span>
                                  <svg
                                    className="shrink-0 h-6 w-6"
                                    viewBox="0 0 1024 1024"
                                  >
                                    <path
                                      className={`fill-current ${
                                        pathname.startsWith("/sports")
                                          ? "text-indigo-300"
                                          : "text-slate-400"
                                      }`}
                                      d="M940.992 508.74c1.151 97.505-30.722 194.499-93.35 273.143h-.031l-158.247-30.915-63.139-69.916 70.972-218.284 123.016-18.414 120.78 64.385z"
                                    ></path>
                                    <path
                                      className={`fill-current ${
                                        pathname.startsWith("/sports")
                                          ? "text-indigo-500"
                                          : "text-slate-600"
                                      }`}
                                      d="M940.992 508.74l-120.779-64.386 67.167-138.426c35.23 63.522 52.845 133.279 53.612 202.811z"
                                    ></path>
                                    <path
                                      className={`fill-current ${
                                        pathname.startsWith("/sports")
                                          ? "text-indigo-300"
                                          : "text-slate-400"
                                      }`}
                                      d="M887.38 305.928l-67.167 138.426-123.016 18.414L509.25 327.922V234.83l.927-.736 150.35-122.345.352-.96c72.857 27.046 139.289 74.137 190.311 140.153a429.934 429.934 0 0136.19 54.987z"
                                    ></path>
                                    <path
                                      className={`fill-current ${
                                        pathname.startsWith("/sports")
                                          ? "text-indigo-500"
                                          : "text-slate-600"
                                      }`}
                                      d="M847.61 781.883h.033c-21.1 26.534-45.716 50.958-73.72 72.601-32.833 25.384-68.19 45.268-104.955 59.782l20.396-163.298 158.247 30.915zM697.198 462.768l-70.972 218.284h-229.41l-70.97-218.284L509.25 327.922z"
                                    ></path>
                                    <path
                                      className={`fill-current ${
                                        pathname.startsWith("/sports")
                                          ? "text-indigo-300"
                                          : "text-slate-400"
                                      }`}
                                      d="M626.225 681.052l63.14 69.916-20.397 163.298c-98.273 38.939-206.616 39.578-303.866 4.668L344.1 750.968l52.717-69.916h229.41z"
                                    ></path>
                                    <path
                                      className={`fill-current ${
                                        pathname.startsWith("/sports")
                                          ? "text-indigo-500"
                                          : "text-slate-600"
                                      }`}
                                      d="M660.88 110.789l-.352.959-150.35 122.345-.927.736-151.246-123.081c98.337-37.372 206.264-36.797 302.875-.96z"
                                    ></path>
                                    <path
                                      className={`fill-current ${
                                        pathname.startsWith("/sports")
                                          ? "text-indigo-300"
                                          : "text-slate-400"
                                      }`}
                                      d="M509.25 234.829v93.093L325.845 462.768 213.25 444.354l-68.03-140.249-8.088-3.548c27.302-47.794 64.29-91.112 110.55-126.885 34.43-26.63 71.642-47.219 110.324-61.924l151.246 123.08zM325.844 462.768l70.972 218.284-52.717 69.916-168.413 32.897a377.137 377.137 0 01-5.307-6.714c-60.39-78.1-89.673-170.427-89.833-262.05h.032l132.671-70.747 112.595 18.414z"
                                    ></path>
                                    <path
                                      className={`fill-current ${
                                        pathname.startsWith("/sports")
                                          ? "text-indigo-500"
                                          : "text-slate-600"
                                      }`}
                                      d="M344.099 750.968l21.003 167.966c-72.09-25.863-138.106-71.26-189.416-135.07l168.413-32.896zM80.546 515.101c-.128-74.616 19.085-148.816 56.585-214.544l8.088 3.548 68.03 140.249L80.578 515.1h-.032z"
                                    ></path>
                                    <path
                                      className={`fill-current ${
                                        pathname.startsWith("/sports")
                                          ? "text-indigo-700"
                                          : "text-slate-800"
                                      }`}
                                      d="M511.095 966.705a453.244 453.244 0 01-153.553-26.71c-78.174-28.043-147.09-77.186-199.295-142.106a420.388 420.388 0 01-5.56-7.033c-61.662-79.75-94.344-175.088-94.516-275.717-.134-79.142 20.447-157.179 59.526-225.669 29.578-51.787 68.702-96.697 116.294-133.503 35.457-27.423 74.507-49.34 116.063-65.137 51.32-19.504 105.3-29.392 160.44-29.392 54.204 0 107.423 9.546 158.173 28.373 79.15 29.38 148.388 80.367 200.235 147.447a453.541 453.541 0 0138.063 57.842c36.077 65.044 55.587 138.841 56.412 213.396 1.228 104.203-33.654 206.252-98.225 287.328a453.01 453.01 0 01-77.544 76.371 451.633 451.633 0 01-110.42 62.89c-52.958 20.978-108.846 31.62-166.093 31.62zm-.604-860.513c-49.686 0-98.314 8.906-144.535 26.473-37.43 14.23-72.618 33.98-104.584 58.708-42.908 33.18-78.173 73.653-104.81 120.283-35.21 61.717-53.76 132.055-53.638 203.407.157 90.657 29.604 176.555 85.16 248.399a373.052 373.052 0 004.987 6.314c47.09 58.56 109.174 102.835 179.589 128.093a408.622 408.622 0 00138.435 24.08c51.58 0 101.92-9.585 149.631-28.488a407.087 407.087 0 0099.507-56.681 408.19 408.19 0 0069.897-68.826c58.171-73.05 89.593-165.009 88.49-258.95-.741-67.166-18.315-133.63-50.808-192.22a409.645 409.645 0 00-34.319-52.148c-46.729-60.46-109.107-106.402-180.398-132.866a408.689 408.689 0 00-142.604-25.578z"
                                    ></path>
                                    <path
                                      className={`fill-current ${
                                        pathname.startsWith("/sports")
                                          ? "text-indigo-700"
                                          : "text-slate-800"
                                      }`}
                                      d="M626.225 703.43h-229.41a22.378 22.378 0 01-21.281-15.46L304.563 469.69a22.372 22.372 0 018.024-24.949l183.406-134.845a22.378 22.378 0 0126.301-.153L710.24 444.587a22.378 22.378 0 018.235 25.102L647.507 687.97a22.372 22.372 0 01-21.282 15.46zm-213.153-44.756h196.897l60.885-187.262-161.443-115.83-157.288 115.642 60.949 187.45zm96.166-401.467a22.282 22.282 0 01-14.111-5.023L344.243 129.398a22.33 22.33 0 01-8.629-17.65c0-12.36 10-22.379 22.362-22.379a22.479 22.479 0 0114.16 5.023l151.245 123.08a22.378 22.378 0 01-14.143 39.735zM80.598 537.483a22.378 22.378 0 01-10.55-42.13l113.668-60.613-58.63-120.868a22.382 22.382 0 0140.27-19.536l68.03 140.248a22.378 22.378 0 01-9.603 29.514L91.112 534.845a22.295 22.295 0 01-10.515 2.638zm284.476 403.832a22.382 22.382 0 01-22.177-19.606l-18.03-144.184-144.894 28.302c-12.12 2.382-23.881-5.54-26.253-17.672a22.378 22.378 0 0117.672-26.253l168.413-32.896a22.378 22.378 0 0126.493 19.188l21.004 167.965a22.378 22.378 0 01-22.228 25.156zm575.902-410.194a22.324 22.324 0 01-10.508-2.635L809.69 464.101a22.378 22.378 0 01-9.606-29.517l67.023-138.129c4.699-10.096 16.3-15.377 27.164-11.873 11.761 3.795 18.235 16.358 14.443 28.12l-.032.096a22.96 22.96 0 01-1.163 2.9L849.756 434.74l101.77 54.252a22.378 22.378 0 01-10.55 42.129zM668.968 936.66a22.366 22.366 0 01-22.206-25.17l20.4-163.297a22.385 22.385 0 0126.496-19.188l158.246 30.914a22.375 22.375 0 0117.673 26.253 22.366 22.366 0 01-26.253 17.672L708.6 777.528l-17.388 139.22a22.394 22.394 0 01-22.244 19.913z"
                                    ></path>
                                    <path
                                      className={`fill-current ${
                                        pathname.startsWith("/sports")
                                          ? "text-indigo-700"
                                          : "text-slate-800"
                                      }`}
                                      d="M509.25 350.3a22.378 22.378 0 01-22.378-22.378V234.83a22.378 22.378 0 1144.757 0v93.093a22.378 22.378 0 01-22.378 22.379zm187.918 134.85a22.382 22.382 0 01-3.283-44.514L816.9 422.22a22.382 22.382 0 016.624 44.265L700.51 484.9a22.36 22.36 0 01-3.341.25zm-7.797 288.197a22.314 22.314 0 01-16.615-7.382l-63.139-69.916a22.378 22.378 0 0133.22-29.997l63.138 69.917a22.378 22.378 0 01-16.604 37.378zm-345.288 0a22.378 22.378 0 01-17.852-35.85l52.717-69.917a22.372 22.372 0 0131.343-4.395 22.378 22.378 0 014.395 31.339L361.97 764.44a22.35 22.35 0 01-17.886 8.907zM325.87 485.149c-1.199 0-2.414-.096-3.638-.297l-112.595-18.414a22.378 22.378 0 017.225-44.169l112.595 18.414a22.378 22.378 0 01-3.587 44.466z"
                                    ></path>
                                    <path
                                      className={`fill-current ${
                                        pathname.startsWith("/sports")
                                          ? "text-indigo-700"
                                          : "text-slate-800"
                                      }`}
                                      d="M510.194 256.472a22.378 22.378 0 01-14.137-39.738l150.35-122.345a22.378 22.378 0 1128.248 34.718L524.308 251.452a22.314 22.314 0 01-14.114 5.02z"
                                    ></path>
                                  </svg>
                                </span>
                                <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  {ConfigProvider.getTranslation("Sports")}
                                </span>
                              </div>
                              {/* Icon */}
                              <div className="flex shrink-0 ml-2">
                                <svg
                                  className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                    open && "rotate-180"
                                  }`}
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                              </div>
                            </div>
                          </Link>
                          <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              {[
                                {
                                  link: "/sports/competitions",
                                  name: ConfigProvider.getTranslation(
                                    "Competitions"
                                  ),
                                  role: "SportsManageSportEvents",
                                },
                                {
                                  link: "/sports/leagues",
                                  name: ConfigProvider.getTranslation(
                                    "Leagues"
                                  ),
                                  role: "SportsManageSportLeagues",
                                },
                                {
                                  link: "/sports/sports",
                                  name: ConfigProvider.getTranslation("Sports"),
                                  role: "SportsManageSports",
                                },
                                {
                                  link: "/sports/markets",
                                  name: ConfigProvider.getTranslation(
                                    "Markets"
                                  ),
                                  role: "SportsManageSportMarkets",
                                },

                                {
                                  link: "/sports/accBonuses",
                                  name: ConfigProvider.getTranslation(
                                    "AccumulatorBonuses"
                                  ),
                                  role: "SportsManageAccumulatorBonuses",
                                },
                                {
                                  link: "/sports/inPlayDelay",
                                  name: ConfigProvider.getTranslation(
                                    "inPlayDelay"
                                  ),
                                  role: "SportsManageInPlayDelay",
                                },
                                {
                                  link: "/sports/factorSettings",
                                  name: ConfigProvider.getTranslation(
                                    "FactorSettings"
                                  ),
                                  role: "SportsManageFactorSetting",
                                },
                                {
                                  link: "/sports/limitClasses",
                                  name: ConfigProvider.getTranslation(
                                    "LimitClasses"
                                  ),
                                  role: "SportsManageLimitClasses",
                                },
                                {
                                  link: "/sports/limitShops",
                                  name:
                                    ConfigProvider.getTranslation("Shop") +
                                    " " +
                                    ConfigProvider.getTranslation("Limits"),
                                  role: "SportsManageShopLimits",
                                },
                              ]
                                .filter((x) =>
                                  checkRole(ConfigProvider.config.user, x.role)
                                )
                                .map((item) => (
                                  <li
                                    key={item.link}
                                    className="mb-1 last:mb-0"
                                  >
                                    <NavLink
                                      end
                                      to={item.link}
                                      className={({ isActive }) =>
                                        "block transition duration-150 truncate " +
                                        (isActive
                                          ? "text-indigo-500"
                                          : "text-slate-400 hover:text-slate-200")
                                      }
                                    >
                                      <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                        {item.name}
                                      </span>
                                    </NavLink>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>
                )}
                {/* Casino */}
                {[
                  "CasinoManageCasinoGames",
                  "CasinoManageCasinoGamePrefers",
                ].find((x) => checkRole(ConfigProvider.config.user, x)) && (
                  <SidebarLinkGroup
                    activecondition={pathname.startsWith("/casino")}
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <Link
                            className={`block text-slate-200 truncate transition duration-150 ${
                              pathname.startsWith("/casino")
                                ? "hover:text-slate-200"
                                : "hover:text-white"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              handleClick();
                              setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <span>
                                  <svg
                                    className="shrink-0 h-6 w-6"
                                    viewBox="-4 0 20 16"
                                  >
                                    <path
                                      className={`fill-current ${
                                        pathname.startsWith("/casino")
                                          ? "text-indigo-500"
                                          : "text-slate-400"
                                      }`}
                                      d="M8.757 14H3.485a.41.41 0 01-.41-.41c0-4.122 2.27-5.955 4.514-8.198l1.108-1.108c-.258.034-.53.05-.818.05-1.154 0-2.151-.438-3.076-.438-.985 0-1.786.801-1.786 1.786a.41.41 0 01-.41.41H.41a.41.41 0 01-.41-.41V.41A.41.41 0 01.41 0h2.197a.41.41 0 01.41.41v.462C3.874.312 4.963 0 6.12 0c1.84 0 2.728.879 4.394.879a.47.47 0 00.468-.469.41.41 0 01.41-.41h2.197a.41.41 0 01.41.41c0 2.034-.978 3.46-2.013 4.97-1.322 1.926-2.82 4.11-2.82 8.21a.41.41 0 01-.41.41z"
                                    ></path>
                                  </svg>
                                </span>
                                <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  {ConfigProvider.getTranslation("Casino")}
                                </span>
                              </div>
                              {/* Icon */}
                              <div className="flex shrink-0 ml-2">
                                <svg
                                  className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                    open && "rotate-180"
                                  }`}
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                              </div>
                            </div>
                          </Link>
                          <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              {[
                                {
                                  link: "/casino/games",
                                  name: ConfigProvider.getTranslation(
                                    "CasinoGames"
                                  ),
                                  role: "CasinoManageCasinoGames",
                                },
                                {
                                  link: "/casino/gamePrefers",
                                  name: ConfigProvider.getTranslation(
                                    "CasinoGamePrefers"
                                  ),
                                  role: "CasinoManageCasinoGamePrefers",
                                },
                              ]
                                .filter((x) =>
                                  checkRole(ConfigProvider.config.user, x.role)
                                )
                                .map((item) => (
                                  <li
                                    key={item.link}
                                    className="mb-1 last:mb-0"
                                  >
                                    <NavLink
                                      end
                                      to={item.link}
                                      className={({ isActive }) =>
                                        "block transition duration-150 truncate " +
                                        (isActive
                                          ? "text-indigo-500"
                                          : "text-slate-400 hover:text-slate-200")
                                      }
                                    >
                                      <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                        {item.name}
                                      </span>
                                    </NavLink>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>
                )}
                {/* Payments */}
                {[
                  "PaymentMethodsListTransactions",
                  "PaymentMethodsManage",
                  "PaymentMethodsManageBlackList",
                  "PaymentMethodsReportTransactions",
                ].find((x) => checkRole(ConfigProvider.config.user, x)) && (
                  <SidebarLinkGroup
                    activecondition={pathname.startsWith("/payments")}
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <Link
                            className={`block text-slate-200 truncate transition duration-150 ${
                              pathname.startsWith("/payments")
                                ? "hover:text-slate-200"
                                : "hover:text-white"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              handleClick();
                              setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <span>
                                  <svg
                                    className="shrink-0 w-6 h-6"
                                    fillRule="evenodd"
                                    strokeLinejoin="round"
                                    strokeMiterlimit="2"
                                    clipRule="evenodd"
                                    viewBox="0 0 32 32"
                                  >
                                    <path
                                      className={`fill-current ${
                                        pathname.startsWith("/payments")
                                          ? "text-indigo-300"
                                          : "text-slate-400"
                                      }`}
                                      d="M29 56.9c0-.504-.211-.987-.586-1.343A2.052 2.052 0 0027 55H5c-.53 0-1.039.2-1.414.557A1.852 1.852 0 003 56.9v15.2c0 .504.211.987.586 1.343C3.961 73.8 4.47 74 5 74h22c.53 0 1.039-.2 1.414-.557.375-.356.586-.839.586-1.343V56.9z"
                                      transform="translate(0 -48) matrix(1 0 0 1.05263 0 -3.895)"
                                    ></path>
                                    <path
                                      className={`fill-current ${
                                        pathname.startsWith("/payments")
                                          ? "text-indigo-500"
                                          : "text-slate-600"
                                      }`}
                                      d="M2 56v16c0 .796.316 1.559.879 2.121A2.996 2.996 0 005 75h22c.796 0 1.559-.316 2.121-.879A2.996 2.996 0 0030 72V56c0-.796-.316-1.559-.879-2.121A2.996 2.996 0 0027 53H5c-.796 0-1.559.316-2.121.879A2.996 2.996 0 002 56zm26 5v11a.997.997 0 01-1 1H5a.997.997 0 01-1-1V61h24zM7 71h1a1 1 0 000-2H7a1 1 0 000 2zm4 0h1a1 1 0 000-2h-1a1 1 0 000 2zm17-12H4v-3a.997.997 0 011-1h22a.997.997 0 011 1v3z"
                                      transform="translate(0 -48)"
                                    ></path>
                                  </svg>
                                </span>
                                <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  {ConfigProvider.getTranslation(
                                    "PaymentMethods"
                                  )}
                                </span>
                              </div>
                              {/* Icon */}
                              <div className="flex shrink-0 ml-2">
                                <svg
                                  className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                    open && "rotate-180"
                                  }`}
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                              </div>
                            </div>
                          </Link>
                          <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              {[
                                {
                                  link: "/payments/transactions",
                                  name: ConfigProvider.getTranslation(
                                    "Transactions"
                                  ),
                                  role: "PaymentMethodsListTransactions",
                                },
                                {
                                  link: "/payments/list",
                                  name: ConfigProvider.getTranslation("List"),
                                  role: "PaymentMethodsManage",
                                },
                                {
                                  link: "/payments/blacklist",
                                  name: ConfigProvider.getTranslation(
                                    "Blacklist"
                                  ),
                                  role: "PaymentMethodsManageBlackList",
                                },
                                {
                                  link: "/payments/report",
                                  name: ConfigProvider.getTranslation("Report"),
                                  role: "PaymentMethodsReportTransactions",
                                },
                              ]
                                .filter((x) =>
                                  checkRole(ConfigProvider.config.user, x.role)
                                )
                                .map((item) => (
                                  <li
                                    key={item.link}
                                    className="mb-1 last:mb-0"
                                  >
                                    <NavLink
                                      end
                                      to={item.link}
                                      className={({ isActive }) =>
                                        "block transition duration-150 truncate " +
                                        (isActive
                                          ? "text-indigo-500"
                                          : "text-slate-400 hover:text-slate-200")
                                      }
                                    >
                                      <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                        {item.name}
                                      </span>
                                    </NavLink>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>
                )}
                {/* Jackpot */}
                {["JackpotsList", "JackpotsDefaultList"].find((x) =>
                  checkRole(ConfigProvider.config.user, x)
                ) && (
                  <SidebarLinkGroup
                    activecondition={pathname.startsWith("/jackpot")}
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <Link
                            className={`block text-slate-200 truncate transition duration-150 ${
                              pathname.startsWith("/jackpot")
                                ? "hover:text-slate-200"
                                : "hover:text-white"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              handleClick();
                              setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <span>
                                  <svg
                                    className="shrink-0 w-6 h-6"
                                    viewBox="0 0 32 32"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      d="M16.0002 3L20.9992 10H11.0004L16.0002 3Z"
                                      className={`fill-current ${
                                        pathname.startsWith("/jackpot")
                                          ? "text-indigo-600"
                                          : "text-slate-600"
                                      }`}
                                    />
                                    <path
                                      d="M10.9992 10L6.00017 3L1.00037 10H10.9992Z"
                                      className={`fill-current ${
                                        pathname.startsWith("/jackpot")
                                          ? "text-indigo-400"
                                          : "text-slate-500"
                                      }`}
                                    />
                                    <path
                                      d="M30.9992 10L26.0002 3L21.0004 10H30.9992Z"
                                      className={`fill-current ${
                                        pathname.startsWith("/jackpot")
                                          ? "text-indigo-400"
                                          : "text-slate-500"
                                      }`}
                                    />
                                    <path
                                      d="M10.9999 10.0002L15.9999 31.0002L20.9999 10.0002H10.9999Z"
                                      className={`fill-current ${
                                        pathname.startsWith("/jackpot")
                                          ? "stroke-indigo-500"
                                          : "stroke-slate-700"
                                      }`}
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                    <path
                                      d="M16 31L1 10H11L16 31Z"
                                      className={`fill-current ${
                                        pathname.startsWith("/jackpot")
                                          ? "text-indigo-400"
                                          : "text-slate-500"
                                      }`}
                                    />
                                    <path
                                      d="M16 31L20.9998 10H30.9998L16 31Z"
                                      className={`fill-current ${
                                        pathname.startsWith("/jackpot")
                                          ? "text-indigo-400"
                                          : "text-slate-500"
                                      }`}
                                    />
                                    <path
                                      d="M10.9994 10L6.00037 3H15.9992L10.9994 10Z"
                                      className={`fill-current ${
                                        pathname.startsWith("/jackpot")
                                          ? "text-indigo-300"
                                          : "text-slate-400"
                                      }`}
                                    />
                                    <path
                                      d="M20.9993 9.99988L16.0004 3H25.9992L20.9993 9.99988L16 30.9999L10.9994 10L20.9993 9.99988Z"
                                      className={`fill-current ${
                                        pathname.startsWith("/jackpot")
                                          ? "text-indigo-300"
                                          : "text-slate-400"
                                      }`}
                                    />
                                    <path
                                      d="M6 3L1 10M6 3H26M6 3L11 10M1 10L16 31M1 10H31M16 31L31 10M16 31L21 10M16 31L11 10M31 10L26 3M26 3L21 10M21 10L16 3L11 10"
                                      className={`fill-current ${
                                        pathname.startsWith("/jackpot")
                                          ? "stroke-indigo-500"
                                          : "stroke-slate-700"
                                      }`}
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    />
                                  </svg>
                                </span>
                                <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  {ConfigProvider.getTranslation("Jackpot")}
                                </span>
                              </div>
                              {/* Icon */}
                              <div className="flex shrink-0 ml-2">
                                <svg
                                  className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                    open && "rotate-180"
                                  }`}
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                              </div>
                            </div>
                          </Link>
                          <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              {[
                                {
                                  link: "/jackpot/list",
                                  name: ConfigProvider.getTranslation(
                                    "Jackpot"
                                  ),
                                  role: "JackpotsList",
                                },
                                {
                                  link: "/jackpot/defaults",
                                  name: ConfigProvider.getTranslation(
                                    "DefaultJackpots"
                                  ),
                                  role: "JackpotsDefaultList",
                                },
                              ]
                                .filter((x) =>
                                  checkRole(ConfigProvider.config.user, x.role)
                                )
                                .map((item) => (
                                  <li
                                    key={item.link}
                                    className="mb-1 last:mb-0"
                                  >
                                    <NavLink
                                      end
                                      to={item.link}
                                      className={({ isActive }) =>
                                        "block transition duration-150 truncate " +
                                        (isActive
                                          ? "text-indigo-500"
                                          : "text-slate-400 hover:text-slate-200")
                                      }
                                    >
                                      <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                        {item.name}
                                      </span>
                                    </NavLink>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>
                )}
                {/* CMS */}
                {[
                  "CMSManagePages",
                  "CMSManagePopup",
                  "CMSManageSlider",
                  "CMSManageTranslation",
                  "CMSManageEmailTemplates",
                  "CMSManageAnnouncements",
                  "CMSManageMenus",
                ].find((x) => checkRole(ConfigProvider.config.user, x)) && (
                  <SidebarLinkGroup
                    activecondition={pathname.startsWith("/cms")}
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <Link
                            className={`block text-slate-200 truncate transition duration-150 ${
                              pathname.startsWith("/cms")
                                ? "hover:text-slate-200"
                                : "hover:text-white"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              handleClick();
                              setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <span>
                                  <svg
                                    className="shrink-0 h-6 w-6"
                                    viewBox="0 0 24 24"
                                  >
                                    <circle
                                      className={`fill-current ${
                                        pathname.startsWith("/cms")
                                          ? "text-indigo-300"
                                          : "text-slate-400"
                                      }`}
                                      cx="18.5"
                                      cy="5.5"
                                      r="4.5"
                                    />
                                    <circle
                                      className={`fill-current ${
                                        pathname.startsWith("/cms")
                                          ? "text-indigo-500"
                                          : "text-slate-600"
                                      }`}
                                      cx="5.5"
                                      cy="5.5"
                                      r="4.5"
                                    />
                                    <circle
                                      className={`fill-current ${
                                        pathname.startsWith("/cms")
                                          ? "text-indigo-500"
                                          : "text-slate-600"
                                      }`}
                                      cx="18.5"
                                      cy="18.5"
                                      r="4.5"
                                    />
                                    <circle
                                      className={`fill-current ${
                                        pathname.startsWith("/cms")
                                          ? "text-indigo-300"
                                          : "text-slate-400"
                                      }`}
                                      cx="5.5"
                                      cy="18.5"
                                      r="4.5"
                                    />
                                  </svg>
                                </span>
                                <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  {ConfigProvider.getTranslation("CMS")}
                                </span>
                              </div>
                              {/* Icon */}
                              <div className="flex shrink-0 ml-2">
                                <svg
                                  className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                    open && "rotate-180"
                                  }`}
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                              </div>
                            </div>
                          </Link>
                          <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              {[
                                {
                                  link: "/cms/pages",
                                  name: ConfigProvider.getTranslation("Pages"),
                                  role: "CMSManagePages",
                                },
                                {
                                  link: "/cms/popup",
                                  name: ConfigProvider.getTranslation("Popup"),
                                  role: "CMSManagePopup",
                                },
                                {
                                  link: "/cms/slider",
                                  name: ConfigProvider.getTranslation("Slider"),
                                  role: "CMSManageSlider",
                                },
                                {
                                  link: "/cms/promotions",
                                  name: ConfigProvider.getTranslation(
                                    "Promotions"
                                  ),
                                  role: "CMSManagePromotions",
                                },
                                {
                                  link: "/cms/translate",
                                  name: ConfigProvider.getTranslation(
                                    "Translate"
                                  ),
                                  role: "CMSManageTranslation",
                                },
                                {
                                  link: "/cms/emailTemplates",
                                  name: ConfigProvider.getTranslation(
                                    "EmailTemplates"
                                  ),
                                  role: "CMSManageEmailTemplates",
                                },
                                {
                                  link: "/cms/announcements",
                                  name: ConfigProvider.getTranslation(
                                    "Announcements"
                                  ),
                                  role: "CMSManageAnnouncements",
                                },
                                {
                                  link: "/cms/menus",
                                  name: ConfigProvider.getTranslation("Menu"),
                                  role: "CMSManageMenus",
                                },
                              ]
                                .filter((x) =>
                                  checkRole(ConfigProvider.config.user, x.role)
                                )
                                .map((item) => (
                                  <li
                                    key={item.link}
                                    className="mb-1 last:mb-0"
                                  >
                                    <NavLink
                                      end
                                      to={item.link}
                                      className={({ isActive }) =>
                                        "block transition duration-150 truncate " +
                                        (isActive
                                          ? "text-indigo-500"
                                          : "text-slate-400 hover:text-slate-200")
                                      }
                                    >
                                      <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                        {item.name}
                                      </span>
                                    </NavLink>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>
                )}
                {/* System */}
                {[
                  "SiteManageDomain",
                  "SiteManageUsers",
                  "SiteManageRulesets",
                ].find((x) => checkRole(ConfigProvider.config.user, x)) && (
                  <SidebarLinkGroup
                    activecondition={pathname.startsWith("/system")}
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <Link
                            className={`block text-slate-200 truncate transition duration-150 ${
                              pathname.startsWith("/system")
                                ? "hover:text-slate-200"
                                : "hover:text-white"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              handleClick();
                              setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <span>
                                  <svg
                                    className="shrink-0 h-6 w-6"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      className={`fill-current ${
                                        pathname.startsWith("/system")
                                          ? "text-indigo-500"
                                          : "text-slate-600"
                                      }`}
                                      d="M19.714 14.7l-7.007 7.007-1.414-1.414 7.007-7.007c-.195-.4-.298-.84-.3-1.286a3 3 0 113 3 2.969 2.969 0 01-1.286-.3z"
                                    />
                                    <path
                                      className={`fill-current ${
                                        pathname.startsWith("/system")
                                          ? "text-indigo-300"
                                          : "text-slate-400"
                                      }`}
                                      d="M10.714 18.3c.4-.195.84-.298 1.286-.3a3 3 0 11-3 3c.002-.446.105-.885.3-1.286l-6.007-6.007 1.414-1.414 6.007 6.007z"
                                    />
                                    <path
                                      className={`fill-current ${
                                        pathname.startsWith("/system")
                                          ? "text-indigo-500"
                                          : "text-slate-600"
                                      }`}
                                      d="M5.7 10.714c.195.4.298.84.3 1.286a3 3 0 11-3-3c.446.002.885.105 1.286.3l7.007-7.007 1.414 1.414L5.7 10.714z"
                                    />
                                    <path
                                      className={`fill-current ${
                                        pathname.startsWith("/system")
                                          ? "text-indigo-300"
                                          : "text-slate-400"
                                      }`}
                                      d="M19.707 9.292a3.012 3.012 0 00-1.415 1.415L13.286 5.7c-.4.195-.84.298-1.286.3a3 3 0 113-3 2.969 2.969 0 01-.3 1.286l5.007 5.006z"
                                    />
                                  </svg>
                                </span>
                                <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  {ConfigProvider.getTranslation("System")}
                                </span>
                              </div>
                              {/* Icon */}
                              <div className="flex shrink-0 ml-2">
                                <svg
                                  className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                    open && "rotate-180"
                                  }`}
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                              </div>
                            </div>
                          </Link>
                          <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              {[
                                {
                                  link: "/system/accounts",
                                  name: ConfigProvider.getTranslation(
                                    "Administration"
                                  ),
                                  role: "SiteManageUsers",
                                },
                                {
                                  link: "/system/rulesets",
                                  name: ConfigProvider.getTranslation(
                                    "Rulesets"
                                  ),
                                  role: "SiteManageRulesets",
                                },
                                {
                                  link: "/system/confDomain",
                                  name: ConfigProvider.getTranslation(
                                    "DomainSettings"
                                  ),
                                  role: "SiteManageDomain",
                                },
                                {
                                  link: "/system/reserveDomain",
                                  name: ConfigProvider.getTranslation(
                                    "DomainRezerv"
                                  ),
                                  role: "SiteManageDomain",
                                },
                              ]
                                .filter((x) =>
                                  checkRole(ConfigProvider.config.user, x.role)
                                )
                                .map((item) => (
                                  <li
                                    key={item.link}
                                    className="mb-1 last:mb-0"
                                  >
                                    <NavLink
                                      end
                                      to={item.link}
                                      className={({ isActive }) =>
                                        "block transition duration-150 truncate " +
                                        (isActive
                                          ? "text-indigo-500"
                                          : "text-slate-400 hover:text-slate-200")
                                      }
                                    >
                                      <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                        {item.name}
                                      </span>
                                    </NavLink>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>
                )}
                {/* Support */}
                {["Support", "SiteSupportManageCategories"].find((x) =>
                  checkRole(ConfigProvider.config.user, x)
                ) && (
                  <SidebarLinkGroup
                    activecondition={pathname.startsWith("/support")}
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <Link
                            className={`block text-slate-200 truncate transition duration-150 ${
                              pathname.startsWith("/support")
                                ? "hover:text-slate-200"
                                : "hover:text-white"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              handleClick();
                              setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <span>
                                  <svg
                                    className="shrink-0 h-6 w-6"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      className={`fill-current ${
                                        pathname.startsWith("/support")
                                          ? "text-indigo-500"
                                          : "text-slate-600"
                                      }`}
                                      d="M14.5 7c4.695 0 8.5 3.184 8.5 7.111 0 1.597-.638 3.067-1.7 4.253V23l-4.108-2.148a10 10 0 01-2.692.37c-4.695 0-8.5-3.184-8.5-7.11C6 10.183 9.805 7 14.5 7z"
                                    />
                                    <path
                                      className={`fill-current ${
                                        pathname.startsWith("/support")
                                          ? "text-indigo-300"
                                          : "text-slate-400"
                                      }`}
                                      d="M11 1C5.477 1 1 4.582 1 9c0 1.797.75 3.45 2 4.785V19l4.833-2.416C8.829 16.85 9.892 17 11 17c5.523 0 10-3.582 10-8s-4.477-8-10-8z"
                                    />
                                  </svg>
                                </span>
                                <span className="text-sm font-medium ml-3 lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  {ConfigProvider.getTranslation("Support")}
                                </span>
                              </div>
                              {/* Icon */}
                              <div className="flex shrink-0 ml-2">
                                <svg
                                  className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                    open && "rotate-180"
                                  }`}
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                              </div>
                            </div>
                          </Link>
                          <div className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              {[
                                {
                                  link: "/support/tickets",
                                  name: ConfigProvider.getTranslation(
                                    "SupportTickets"
                                  ),
                                  role: "Support",
                                },
                                {
                                  link: "/support/categories",
                                  name: ConfigProvider.getTranslation(
                                    "SupportCategories"
                                  ),
                                  role: "SiteSupportManageCategories",
                                },
                              ]
                                .filter((x) =>
                                  checkRole(ConfigProvider.config.user, x.role)
                                )
                                .map((item) => (
                                  <li
                                    key={item.link}
                                    className="mb-1 last:mb-0"
                                  >
                                    <NavLink
                                      end
                                      to={item.link}
                                      className={({ isActive }) =>
                                        "block transition duration-150 truncate " +
                                        (isActive
                                          ? "text-indigo-500"
                                          : "text-slate-400 hover:text-slate-200")
                                      }
                                    >
                                      <span className="text-sm font-medium lg:opacity-0 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                        {item.name}
                                      </span>
                                    </NavLink>
                                  </li>
                                ))}
                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>
                )}
              </ul>
            </div>
          )}
        </div>

        {/* Expand / collapse button */}
        <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
          <div className="px-3 py-2">
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <svg
                className="w-6 h-6 fill-current sidebar-expanded:rotate-180"
                viewBox="0 0 24 24"
              >
                <path
                  className="text-slate-400"
                  d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
                />
                <path className="text-slate-600" d="M3 23H1V1h2z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default Sidebar;
