import React, { useEffect, useState } from "react";
import {
  Route,
  Routes,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Home from "./pages/Home";
import Error from "./pages/Error";
import Login from "./pages/Login";
import { useConfig } from "./context/ConfigContext";
import TwoFA from "./pages/TwoFA";
import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";
import ChangeTimezone from "./pages/profile/ChangeTimezone";
import ChangePassword from "./pages/profile/ChangePassword";
import AccountCurrency from "./pages/profile/AccountCurrency";
import AccountLanguage from "./pages/profile/AccountLanguage";
import ListCard from "./pages/cards/ListCard";
import AddCard from "./pages/cards/AddCard";
import ViewCard from "./pages/cards/ViewCard";
import SummaryCard from "./pages/cards/SummaryCard";
import EditCard from "./pages/cards/EditCard";
import FinanceCard from "./pages/cards/FinanceCard";
import TicketCard from "./pages/cards/TicketCard";
import BalanceTransactionsCard from "./pages/cards/BalanceTransactionsCard";
import LogsCard from "./pages/cards/LogsCard";
import ListAccount from "./pages/accounts/ListAccount";
import AddAccount from "./pages/accounts/AddAccount";
import ViewAccount from "./pages/accounts/ViewAccount";
import EditAccount from "./pages/accounts/EditAccount";
import ListAccountUsers from "./pages/accounts/ListAccountUsers";
import Tickets from "./pages/Tickets";
import Accounting from "./pages/finance/Accounting";
import BalanceTransactions from "./pages/finance/BalanceTransactions";
import CreditTransactions from "./pages/finance/CreditTransactions";
import BillingTransactions from "./pages/finance/BillingTransactions";

import BetReports from "./pages/report/BetReports";
import GeneralAccountingReport from "./pages/report/GeneralAccountingReport";
import ReportCard from "./pages/report/ReportCard";
import ReportEvent from "./pages/report/ReportEvent";
import ShopAccountingReport from "./pages/report/ShopAccountingReport";
import Logs from "./pages/Logs";
import FinanceAccount from "./pages/accounts/FinanceAccount";
import Rulesets from "./pages/system/Rulesets";
import LoadingSpinner from "./components/Loading/LoadingSpinner";
import TicketAccount from "./pages/accounts/TicketAccount";
import CreditTransactionsAccount from "./pages/accounts/CreditTransactionsAccount";
import LogsAccount from "./pages/accounts/LogsAccount";
import SettlementEvents from "./pages/settlement/SettlementEvents";
import SettlementEvent from "./pages/settlement/SettlementEvent";
import SettlementBet from "./pages/settlement/SettlementBet";
import SettlementComplaints from "./pages/settlement/SettlementComplaints";
import SportCompetitions from "./pages/sports/SportCompetitions";
import SportLeagues from "./pages/sports/SportLeagues";
import SportMarkets from "./pages/sports/SportMarkets";
import SportList from "./pages/sports/SportList";
import AccBonuses from "./pages/sports/AccBonuses";
import InPlayDelay from "./pages/sports/InPlayDelay";
import LimitClasses from "./pages/sports/LimitClasses";
import LimitShops from "./pages/sports/LimitShops";
import FactorSettings from "./pages/sports/FactorSettings";
import CasinoGames from "./pages/casino/CasinoGames";
import PaymentsTransactions from "./pages/payments/PaymentsTransactions";
import PaymentsList from "./pages/payments/PaymentsList";
import PaymentsBlackList from "./pages/payments/PaymentsBlackList";
import JackpotList from "./pages/jackpot/JackpotList";
import JackpotDefaults from "./pages/jackpot/JackpotDefaults";
import Pages from "./pages/cms/Pages";
import Popup from "./pages/cms/Popup";
import Slider from "./pages/cms/Slider";
import Promotions from "./pages/cms/Promotions";
import Translate from "./pages/cms/Translate";
import SystemAccounts from "./pages/system/SystemAccounts";
import ConfDomain from "./pages/system/ConfDomain";
import ReserveDomain from "./pages/system/ReserveDomain";
import SupportTickets from "./pages/support/SupportTickets";
import SupportCategories from "./pages/support/SupportCategories";
import UpsertAccount from "./pages/system/UpsertAccount";
import ListFreespinTemplates from "./pages/helperCasino/ListFreespinTemplates";
import ListFreespins from "./pages/helperCasino/ListFreespins";
import UpsertFreespinTemplate from "./pages/helperCasino/UpsertFreespinTemplate";

import AddFreespinCard from "./pages/cards/AddFreespinCard";
import ListFreespinsCard from "./pages/cards/ListFreespinsCard";
import UpsertJackpot from "./pages/jackpot/UpsertJackpot";
import UpsertJackpotDefault from "./pages/jackpot/UpsertJackpotDefault";
import UpsertPaymentBlackList from "./pages/payments/UpsertPaymentBlackList";
import UpsertPaymentMethod from "./pages/payments/UpsertPaymentMethod";
import UpsertPage from "./pages/cms/UpsertPage";
import UpsertPopup from "./pages/cms/UpsertPopup";
import UpsertSlider from "./pages/cms/UpsertSlider";
import UpsertPromotions from "./pages/cms/UpsertPromotions";
import UpsertRuleset from "./pages/system/UpsertRuleset";
import UpsertAccountUser from "./pages/accounts/UpsertAccountUser";
import UpsertReserveDomain from "./pages/system/UpsertReserveDomain";
import EmailTemplates from "./pages/cms/EmailTemplates";
import UpsertEmailTemplate from "./pages/cms/UpsertEmailTemplate";
import Announcements from "./pages/cms/Announcements";
import UpsertAnnouncement from "./pages/cms/UpsertAnnouncement";
import UpsertFactorSetting from "./pages/sports/UpsertFactorSetting";
import UpsertLimitShop from "./pages/sports/UpsertLimitShop";
import AddBonusCard from "./pages/cards/AddBonusCard";
import ListBonusCard from "./pages/cards/ListBonusCard";
import ListBonus from "./pages/helperCasino/ListBonus";
import UpsertSupportCategory from "./pages/support/UpsertSupportCategory";
import AddSupportTicket from "./pages/support/AddSupportTicket";
import EditSupportTicket from "./pages/support/EditSupportTicket";
import ReportCasinoCard from "./pages/report/ReportCasinoCard";
import ListNoteCard from "./pages/cards/ListNoteCard";
import AddNoteCard from "./pages/cards/AddNoteCard";
import { useSocket } from "./context/SocketContext";
import PaymentsReport from "./pages/payments/PaymentsReport";
import UpdateLogs from "./pages/UpdateLogs";
import BetPaymentReport from "./pages/report/BetPaymentReport";
import Menus from "./pages/cms/Menus";
import UpsertMenu from "./pages/cms/UpsertMenu";
import BetReportsByTransactionDate from "./pages/report/BetReportsByTransactionDate";
import ReportEventDetail from "./pages/report/ReportEventDetail";
import ListCardCategories from "./pages/cards/ListCardCategories";
import UpsertCardCategory from "./pages/cards/UpsertCardCategory";
import MatchOdds from "./pages/sports/MatchOdds";
import ListLimitsCard from "./pages/cards/ListLimitsCard";
import UpsertLimitCard from "./pages/cards/UpsertLimitCard";
import AddMessageCard from "./pages/cards/AddMessageCard";
import ListMessageCard from "./pages/cards/ListMessageCard";
import ListNoteAccount from "./pages/accounts/ListNoteAccount";
import AddNoteAccount from "./pages/accounts/AddNoteAccount";
import BulkMessage from "./pages/cards/BulkMessage";
import BulkFreespin from "./pages/helperCasino/BulkFreespin";
import CasinoGameReport from "./pages/report/CasinoGameReport";
import CasinoGamePrefers from "./pages/casino/CasinoGamePrefers";

function App() {
  const SocketProvider = useSocket();
  const location = useLocation();
  const ConfigProvider = useConfig();

  const { userQueue, clearUserMessages } = SocketProvider;

  const [userId, setUserId] = useState("");
  useEffect(() => {
    if (ConfigProvider.config.user && ConfigProvider.config.user !== null) {
      if (ConfigProvider.config.user.account !== userId) {
        setUserId(ConfigProvider.config.user.account);
        SocketProvider.subscribe([
          "user-" + ConfigProvider.config.user.account,
        ]);
        SocketProvider.defineUserId(ConfigProvider.config.user.account);
      }
    } else if (userId !== "") {
      SocketProvider.unsubscribe(["user-" + userId]);
      setUserId("");
      SocketProvider.defineUserId("");
    }
    // eslint-disable-next-line
  }, [ConfigProvider.config.user, userId]);
  useEffect(() => {
    if (userQueue.length > 0) {
      const readMessages = userQueue;
      readMessages.forEach((item) => {
        const message = JSON.parse(item.message);
        if (
          ConfigProvider.config.user !== null &&
          ConfigProvider.config.user.account === message._id
        ) {
          if (item.cmd === "update") {
            if (message.hasOwnProperty("balance")) {
              ConfigProvider.updateBalance(message.balance, message.strbalance);
            }
          }
        }
      });
      clearUserMessages(readMessages.length);
    }
    // eslint-disable-next-line
  }, [userQueue, clearUserMessages]);

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]); // triggered on route change
  return ConfigProvider.config === null ? (
    <>
      <div className="flex h-[100dvh] items-center justify-center overflow-hidden">
        <LoadingSpinner className="w-12 h-12" />
      </div>
    </>
  ) : (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="dashboard" element={<Home />} />
        <Route path="/profile/changePassword" element={<ChangePassword />} />
        <Route path="/profile/changeTimezone" element={<ChangeTimezone />} />
        <Route path="/profile/language" element={<AccountLanguage />} />
        <Route path="/profile/currency" element={<AccountCurrency />} />
        <Route path="cards/list" element={<ListCard />} />
        <Route path="cards/add" element={<AddCard />} />
        <Route path="cards/card/view/:_id" element={<ViewCard />} />
        <Route path="cards/card/summary/:_id" element={<SummaryCard />} />
        <Route path="cards/card/edit/:_id" element={<EditCard />} />
        <Route path="cards/card/finance/:_id" element={<FinanceCard />} />
        <Route path="cards/card/tickets/:_id" element={<TicketCard />} />
        <Route path="cards/card/bonus/add/:_id" element={<AddBonusCard />} />
        <Route path="cards/card/bonus/list/:_id" element={<ListBonusCard />} />
        <Route
          path="cards/card/freespins/add/:_id"
          element={<AddFreespinCard />}
        />
        <Route
          path="cards/card/freespins/list/:_id"
          element={<ListFreespinsCard />}
        />
        <Route
          path="cards/card/balanceTransactions/:_id"
          element={<BalanceTransactionsCard />}
        />
        <Route path="cards/card/logs/:_id" element={<LogsCard />} />
        <Route path="cards/card/note/add/:_id" element={<AddNoteCard />} />
        <Route path="cards/card/note/list/:_id" element={<ListNoteCard />} />
        <Route
          path="cards/card/message/add/:_id"
          element={<AddMessageCard />}
        />
        <Route
          path="cards/card/message/list/:_id"
          element={<ListMessageCard />}
        />
        <Route path="cards/categories/list" element={<ListCardCategories />} />
        <Route path="cards/categories/add" element={<UpsertCardCategory />} />
        <Route
          path="cards/categories/edit/:_id"
          element={<UpsertCardCategory />}
        />
        <Route
          path="cards/card/limits/add/:_id"
          element={<UpsertLimitCard />}
        />
        <Route
          path="cards/card/limits/edit/:_id/:limit"
          element={<UpsertLimitCard />}
        />
        <Route
          path="cards/card/limits/list/:_id"
          element={<ListLimitsCard />}
        />
        <Route path="cards/bulkMessage" element={<BulkMessage />} />

        <Route path="accounts/list" element={<ListAccount />} />
        <Route path="accounts/add" element={<AddAccount />} />
        <Route path="accounts/account/view/:_id" element={<ViewAccount />} />
        <Route path="accounts/account/edit/:_id" element={<EditAccount />} />
        <Route
          path="accounts/account/finance/:_id"
          element={<FinanceAccount />}
        />
        <Route
          path="accounts/account/users/:_id"
          element={<ListAccountUsers />}
        />
        <Route
          path="accounts/account/users/:_id/add"
          element={<UpsertAccountUser />}
        />
        <Route
          path="accounts/account/users/:_id/edit/:_uid"
          element={<UpsertAccountUser />}
        />
        <Route
          path="accounts/account/tickets/:_id"
          element={<TicketAccount />}
        />
        <Route
          path="accounts/account/creditTransactions/:_id"
          element={<CreditTransactionsAccount />}
        />
        <Route path="accounts/account/logs/:_id" element={<LogsAccount />} />
        <Route
          path="accounts/account/note/add/:_id"
          element={<AddNoteAccount />}
        />
        <Route
          path="accounts/account/note/list/:_id"
          element={<ListNoteAccount />}
        />
        <Route path="tickets/:cid?" element={<Tickets />} />
        <Route path="helperCasino/bonus/list" element={<ListBonus />} />
        <Route path="helperCasino/freespins/list" element={<ListFreespins />} />
        <Route
          path="helperCasino/freespinTemplates/list"
          element={<ListFreespinTemplates />}
        />
        <Route
          path="helperCasino/freespinTemplates/add"
          element={<UpsertFreespinTemplate />}
        />
        <Route
          path="helperCasino/freespinTemplates/edit/:_id"
          element={<UpsertFreespinTemplate />}
        />
        <Route
          path="helperCasino/freespinTemplates/bulk"
          element={<BulkFreespin />}
        />
        <Route path="finance/accounting" element={<Accounting />} />
        <Route
          path="finance/balanceTransactions"
          element={<BalanceTransactions />}
        />
        <Route
          path="finance/creditTransactions"
          element={<CreditTransactions />}
        />
        <Route
          path="finance/billingTransactions"
          element={<BillingTransactions />}
        />
        <Route path="report/betReports" element={<BetReports />} />
        <Route
          path="report/betReportsByTransactionDate"
          element={<BetReportsByTransactionDate />}
        />
        <Route
          path="report/generalAccountingReport"
          element={<GeneralAccountingReport />}
        />
        <Route
          path="report/shopAccountingReport"
          element={<ShopAccountingReport />}
        />
        <Route path="report/reportEvent" element={<ReportEvent />} />
        <Route path="report/reportEvent/:id" element={<ReportEventDetail />} />
        <Route path="report/reportCard" element={<ReportCard />} />
        <Route path="report/reportCasinoCard" element={<ReportCasinoCard />} />
        <Route path="report/betPaymentReport" element={<BetPaymentReport />} />
        <Route path="report/casinoGameReport" element={<CasinoGameReport />} />
        <Route path="logs/:ip?" element={<Logs />} />
        <Route path="updateLogs/:c/:_id" element={<UpdateLogs />} />
        <Route path="settlement/events" element={<SettlementEvents />} />
        <Route path="settlement/event/:_id" element={<SettlementEvent />} />
        <Route path="settlement/bet" element={<SettlementBet />} />
        <Route
          path="settlement/complaints"
          element={<SettlementComplaints />}
        />
        <Route path="sports/competitions" element={<SportCompetitions />} />
        <Route path="sports/competitions/:eid" element={<MatchOdds />} />
        <Route path="sports/leagues" element={<SportLeagues />} />
        <Route path="sports/sports" element={<SportList />} />
        <Route path="sports/markets" element={<SportMarkets />} />
        <Route path="sports/accBonuses" element={<AccBonuses />} />
        <Route path="sports/inPlayDelay" element={<InPlayDelay />} />
        <Route path="sports/factorSettings" element={<FactorSettings />} />
        <Route
          path="sports/factorSetting/add"
          element={<UpsertFactorSetting />}
        />
        <Route
          path="sports/factorSetting/edit/:_id"
          element={<UpsertFactorSetting />}
        />
        <Route path="sports/limitClasses" element={<LimitClasses />} />
        <Route path="sports/limitShops" element={<LimitShops />} />
        <Route path="sports/limitShop/add" element={<UpsertLimitShop />} />
        <Route
          path="sports/limitShop/edit/:_id"
          element={<UpsertLimitShop />}
        />

        <Route path="casino/games" element={<CasinoGames />} />
        <Route path="casino/gamePrefers" element={<CasinoGamePrefers />} />

        <Route
          path="payments/transactions"
          element={<PaymentsTransactions />}
        />
        <Route path="payments/list" element={<PaymentsList />} />
        <Route path="payments/add" element={<UpsertPaymentMethod />} />
        <Route path="payments/edit/:_id" element={<UpsertPaymentMethod />} />
        <Route path="payments/blacklist" element={<PaymentsBlackList />} />
        <Route
          path="payments/blacklist/add"
          element={<UpsertPaymentBlackList />}
        />
        <Route
          path="payments/blacklist/edit/:_id"
          element={<UpsertPaymentBlackList />}
        />
        <Route path="payments/report" element={<PaymentsReport />} />
        <Route path="jackpot/list" element={<JackpotList />} />
        <Route path="jackpot/add" element={<UpsertJackpot />} />
        <Route path="jackpot/edit/:_id" element={<UpsertJackpot />} />
        <Route path="jackpot/defaults" element={<JackpotDefaults />} />
        <Route path="jackpot/defaults/add" element={<UpsertJackpotDefault />} />
        <Route
          path="jackpot/defaults/edit/:_id"
          element={<UpsertJackpotDefault />}
        />
        <Route path="cms/pages" element={<Pages />} />
        <Route path="cms/pages/add" element={<UpsertPage />} />
        <Route path="cms/pages/edit/:_id" element={<UpsertPage />} />
        <Route path="cms/popup" element={<Popup />} />
        <Route path="cms/popup/add" element={<UpsertPopup />} />
        <Route path="cms/popup/edit/:_id" element={<UpsertPopup />} />
        <Route path="cms/slider" element={<Slider />} />
        <Route path="cms/slider/add" element={<UpsertSlider />} />
        <Route path="cms/slider/edit/:_id" element={<UpsertSlider />} />
        <Route path="cms/promotions" element={<Promotions />} />
        <Route path="cms/promotion/add" element={<UpsertPromotions />} />
        <Route path="cms/promotion/edit/:_id" element={<UpsertPromotions />} />
        <Route path="cms/translate" element={<Translate />} />
        <Route path="cms/emailTemplates" element={<EmailTemplates />} />
        <Route path="cms/emailTemplate/add" element={<UpsertEmailTemplate />} />
        <Route
          path="cms/emailTemplate/edit/:_id"
          element={<UpsertEmailTemplate />}
        />
        <Route path="cms/announcements" element={<Announcements />} />
        <Route path="cms/announcement/add" element={<UpsertAnnouncement />} />
        <Route
          path="cms/announcement/edit/:_id"
          element={<UpsertAnnouncement />}
        />
        <Route path="cms/menus" element={<Menus />} />
        <Route path="cms/menu/add" element={<UpsertMenu />} />
        <Route path="cms/menu/edit/:_id" element={<UpsertMenu />} />
        <Route path="system/rulesets" element={<Rulesets />} />
        <Route path="system/rulesets/add" element={<UpsertRuleset />} />
        <Route path="system/rulesets/edit/:_id" element={<UpsertRuleset />} />
        <Route path="system/accounts" element={<SystemAccounts />} />
        <Route path="system/accounts/add" element={<UpsertAccount />} />
        <Route path="system/accounts/edit/:_id" element={<UpsertAccount />} />
        <Route path="system/confDomain" element={<ConfDomain />} />
        <Route path="system/reserveDomain" element={<ReserveDomain />} />
        <Route
          path="system/reserveDomain/add"
          element={<UpsertReserveDomain />}
        />
        <Route path="support/tickets" element={<SupportTickets />} />
        <Route path="support/ticket/add" element={<AddSupportTicket />} />
        <Route
          path="support/ticket/edit/:_id"
          element={<EditSupportTicket />}
        />
        <Route path="support/categories" element={<SupportCategories />} />
        <Route
          path="support/category/add"
          element={<UpsertSupportCategory />}
        />
        <Route
          path="support/category/edit/:_id"
          element={<UpsertSupportCategory />}
        />
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/2fa" element={<TwoFA />} />
      <Route path="error/:code/:text" element={<Error />} />
      <Route path="*" element={<Error />} />
    </Routes>
  );
}
function Layout() {
  const ConfigProvider = useConfig();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (ConfigProvider.config.user === null) {
      navigate("/login");
    }
    // eslint-disable-next-line
  }, []);

  const [sidebarOpen, setSidebarOpen] = useState(false);
  useEffect(() => {
    setSidebarOpen(false);
  }, [location.pathname]);
  return ConfigProvider.config.user === null ? (
    <></>
  ) : (
    <div className="flex h-[100dvh] overflow-hidden">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
      <div
        id="mainBody"
        className="relative flex flex-col flex-1 h-full overflow-y-auto overflow-x-hidden"
      >
        <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
        <main className="grow min-w-0">
          <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
            <Outlet />
          </div>
        </main>
      </div>
    </div>
  );
}

export default App;
