import React, { useEffect, useState } from "react";
import TreeMain from "../../components/Tree/TreeMain";
import TreeMainNav from "../../components/Tree/TreeMainNav";
import { useConfig } from "../../context/ConfigContext";
import { ArrowRightCircleIcon } from "@heroicons/react/24/solid";
import Requests from "../../helpers/Requests";
import { usePrefences } from "../../context/PrefencesContext";
import {
  checkRole,
  classNames,
  diagnosticResponse,
} from "../../helpers/Helpers";
import { Link, useNavigate } from "react-router-dom";
import LoadingSpinner from "../../components/Loading/LoadingSpinner";
import Alert from "../../components/Alert";
import ShopTooltip from "../../components/ShopTooltip";
import PaginationClassic from "../../components/PaginationClassic";
import SearchFilter from "../../components/SearchFilter";
import SortByItem from "../../components/SortByItems";
import NoData from "../../components/NoData";

function ListAccount() {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();

  const navigate = useNavigate();
  useEffect(() => {
    if (!checkRole(ConfigProvider.config.user, "AccountsList")) {
      navigate("/");
    } // eslint-disable-next-line
  }, []);

  const [loading, setLoading] = useState(false);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState("");
  const [formData, setFormData] = useState({
    page: 1,
    stat: 1,
    sort: 3,
    sortBy: 0,
  });
  const [data, setData] = useState([]);
  const [messages, setMessages] = useState([]);
  const getItems = () => {
    if (!loading) {
      setLoading(true);
      setMessages([]);
      const postData = formData;
      postData.itemsPerPage = PrefencesProvider.prefences.offset;
      postData.search = search;
      Requests.postData("/accounts/getAccounts", postData)
        .then((response) => {
          let res = response.data;
          if (res.type !== "success") {
            diagnosticResponse(res);
            setMessages([
              {
                type: "error",
                text: res.message.length
                  ? res.message
                  : ConfigProvider.getTranslation("Forms.ErrorMessage"),
              },
            ]);
            document.getElementById("mainBody").scrollTo(0, 0);
          } else {
            if (formData.page === 1) {
              setTotalItems(res.c);
            }
            setData(res.data);
          }
          setLoading(false);
        })
        .catch(() => {
          setMessages([
            {
              type: "error",
              text: ConfigProvider.getTranslation("Forms.ErrorMessage"),
            },
          ]);
          document.getElementById("mainBody").scrollTo(0, 0);
          setLoading(false);
        });
    }
  };
  useEffect(() => {
    getItems();
    // eslint-disable-next-line
  }, [formData, PrefencesProvider.prefences.offset]);
  return ConfigProvider.config.user !== null ? (
    <>
      <div className="flex space-x-4">
        {ConfigProvider.config.isShopSystem &&
        (ConfigProvider.config.user.auth === "0") |
          (ConfigProvider.config.user.auth === "1") ? (
          <TreeMain
            callback={() => {
              getItems();
            }}
            enableDnd={
              ConfigProvider.config.user.auth === "0" &&
              checkRole(ConfigProvider.config.user, "AccountsEdit")
            }
          />
        ) : null}
        <div className="relative grow min-w-0">
          {loading && <div className="w-full h-full absolute z-50">&nbsp;</div>}
          <form
            onSubmit={(e) => {
              e.preventDefault();
              if (formData.page !== 1) {
                setFormData((prev) => {
                  return { ...prev, page: 1 };
                });
              } else {
                getItems();
              }
            }}
            className=""
          >
            {messages.length > 0 && (
              <div className="mt-2 mb-4">
                <Alert messages={messages} setMessages={setMessages} />
              </div>
            )}
            {/* Page header */}
            <div className="sm:flex sm:justify-between sm:items-center mb-5">
              {/* Left: Title */}
              <div className="mb-4 sm:mb-0">
                <h1 className="text-2xl md:text-3xl text-slate-800 dark:text-slate-100 font-bold">
                  {ConfigProvider.getTranslation("Accounts")} ✨
                </h1>
              </div>

              {/* Right: Actions */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                {/* Search form */}
                <SearchFilter
                  placeholder={ConfigProvider.getTranslation("Username")}
                  search={search}
                  setSearch={setSearch}
                />
                <button
                  type="submit"
                  className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                >
                  <ArrowRightCircleIcon className="w-4 h-4 fill-current opacity-50 shrink-0" />
                  <span className="ml-2">
                    {ConfigProvider.getTranslation("Button.Send")}
                  </span>
                </button>
              </div>
            </div>
            {/* More actions */}
            <div className="sm:flex sm:justify-between sm:items-center mb-5">
              {/* Left side */}
              <div className="mb-4 sm:mb-0">
                <ul className="flex flex-wrap">
                  <li className="m-1">
                    <button
                      type="button"
                      className={classNames(
                        "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border shadow-sm duration-150 ease-in-out",
                        formData.stat === -1
                          ? "border-transparent bg-indigo-500 text-white"
                          : "border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 "
                      )}
                      onClick={() => {
                        setFormData({ ...formData, stat: -1 });
                      }}
                    >
                      {ConfigProvider.getTranslation("Filter.All")}
                    </button>
                  </li>
                  <li className="m-1">
                    <button
                      type="button"
                      className={classNames(
                        "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border shadow-sm duration-150 ease-in-out",
                        formData.stat === 1
                          ? "border-transparent bg-indigo-500 text-white"
                          : "border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 "
                      )}
                      onClick={() => {
                        setFormData({ ...formData, stat: 1 });
                      }}
                    >
                      {ConfigProvider.getTranslation("Active")}
                    </button>
                  </li>
                  <li className="m-1">
                    <button
                      type="button"
                      className={classNames(
                        "inline-flex items-center justify-center text-sm font-medium leading-5 rounded-full px-3 py-1 border shadow-sm duration-150 ease-in-out",
                        formData.stat === 0
                          ? "border-transparent bg-indigo-500 text-white"
                          : "border-slate-200 dark:border-slate-700 hover:border-slate-300 dark:hover:border-slate-600 shadow-sm bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 "
                      )}
                      onClick={() => {
                        setFormData({ ...formData, stat: 0 });
                      }}
                    >
                      {ConfigProvider.getTranslation("Passive")}
                    </button>
                  </li>
                </ul>
              </div>
              {/* Right side */}
              <div className="grid grid-flow-col sm:auto-cols-max justify-end gap-2">
                {/* Add button */}
                {checkRole(ConfigProvider.config.user, "AccountsAdd") && (
                  <Link
                    to="/accounts/add"
                    className="btn bg-green-500 hover:bg-green-600 text-slate-100"
                  >
                    <svg
                      className="w-4 h-4 fill-slate-100 shrink-0"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                    </svg>
                    <span className="hidden xs:block ml-2">
                      {ConfigProvider.getTranslation("Add")}
                    </span>
                  </Link>
                )}
              </div>
            </div>

            {/* Table */}
            <AccountTable
              data={data}
              loading={loading}
              formData={formData}
              setFormData={setFormData}
            />

            {/* Pagination */}
            <div className="mt-8">
              <PaginationClassic
                page={formData.page}
                setPage={(val) => {
                  setFormData((prev) => {
                    return { ...prev, page: val };
                  });
                }}
                totalItems={totalItems}
              />
            </div>
          </form>
        </div>
      </div>
      {ConfigProvider.config.isShopSystem &&
      (ConfigProvider.config.user.auth === "0") |
        (ConfigProvider.config.user.auth === "1") ? (
        <TreeMainNav
          callback={() => {
            getItems();
          }}
        />
      ) : null}
    </>
  ) : null;
}
function AccountTable({ data, loading, formData, setFormData }) {
  const ConfigProvider = useConfig();
  const PrefencesProvider = usePrefences();
  return (
    <div className="bg-white dark:bg-slate-800 shadow-lg rounded-sm border border-slate-200 dark:border-slate-700 relative">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-slate-800 dark:text-slate-100">
          {ConfigProvider.getTranslation("Accounts")}
        </h2>
      </header>
      <div>
        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full dark:text-slate-300">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-500 dark:text-slate-400 bg-slate-50 dark:bg-slate-900/20 border-t border-b border-slate-200 dark:border-slate-700">
              <tr>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-center">&nbsp;</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={0}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-left">
                      {ConfigProvider.getTranslation("Username")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={1}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Name")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={2}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="grow font-semibold text-center">
                      {ConfigProvider.getTranslation("Credits")}
                    </div>
                  </SortByItem>
                </th>

                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={3}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="font-semibold text-center">
                      {ConfigProvider.getTranslation("RegisterDate.Mini")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={4}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="font-semibold text-center">
                      {ConfigProvider.getTranslation("LastLogin")}
                    </div>
                  </SortByItem>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <SortByItem
                    index={5}
                    formData={formData}
                    setFormData={setFormData}
                  >
                    <div className="font-semibold text-center">
                      {ConfigProvider.getTranslation("Status")}
                    </div>
                  </SortByItem>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-slate-200 dark:divide-slate-700">
              {!loading && data && data.length === 0 && (
                <tr>
                  <td colSpan="7">
                    <NoData />
                  </td>
                </tr>
              )}
              {loading ? (
                <tr>
                  <td colSpan="7" className="text-center py-10">
                    <LoadingSpinner className="w-12 h-12" />
                  </td>
                </tr>
              ) : (
                data.map((item) => {
                  return (
                    <tr key={item.id}>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                        {ConfigProvider.config.isShopSystem && (
                          <ShopTooltip
                            size="lg"
                            position="right"
                            bg="dark"
                            shopName=""
                            shop={item._id}
                            auth={item.auth}
                          />
                        )}
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-full">
                        <div className="font-medium text-slate-800 dark:text-slate-100 text-left">
                          <Link
                            className="text-sky-500 dark:text-sky-400"
                            to={"/accounts/account/view/" + item._id}
                          >
                            {item.uname}
                          </Link>
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                        <div className="text-center">{item.name ?? ""}</div>
                      </td>
                      <td className="px-5 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                        <div
                          className={classNames(
                            "text-center",
                            item.balance > 0
                              ? "text-emerald-500"
                              : item.balance === 0
                              ? "text-slate-500"
                              : "text-rose-500"
                          )}
                        >
                          {`${item.strbalance} ${ConfigProvider.config.user.curSession}`}
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                        <div className="text-center">
                          {new Intl.DateTimeFormat("default", {
                            day: "numeric",
                            month: "numeric",
                            year: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            timeZone: PrefencesProvider.prefences.timezone,
                          }).format(new Date(item.rdate))}
                        </div>
                      </td>
                      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                        <div className="text-center">
                          {new Intl.DateTimeFormat("default", {
                            day: "numeric",
                            month: "numeric",
                            year: "numeric",
                            hour: "numeric",
                            minute: "numeric",
                            timeZone: PrefencesProvider.prefences.timezone,
                          }).format(new Date(item.lastLogin))}
                        </div>
                      </td>
                      <td className="px-5 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
                        <div
                          className={classNames(
                            "inline-flex font-medium rounded-full text-center px-2.5 py-0.5",
                            item.stat === 1
                              ? "bg-emerald-100 dark:bg-emerald-400/30 text-emerald-600 dark:text-emerald-400"
                              : item.stat === 0
                              ? "bg-rose-100 dark:bg-rose-500/30 text-rose-500 dark:text-rose-400"
                              : "bg-slate-100 dark:bg-slate-800 text-slate-500 dark:text-slate-400"
                          )}
                        >
                          {item.stat === 1
                            ? ConfigProvider.getTranslation("Active")
                            : ConfigProvider.getTranslation("Passive")}
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
export default ListAccount;
